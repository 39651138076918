/* eslint-disable react/no-danger */
/* eslint-disable max-lines */
import {times} from 'data/slowModeData';
import {
	MAX_TEXTAREA_LENGTH,
	MAX_TEXTAREA_LENGTH_FOR_MODERATOR,
	MIN_TEXTAREA_LENGTH_VISIBLE_COUNTER,
	MIN_TEXTAREA_LENGTH_VISIBLE_COUNTER_FOR_MODERATOR,
	MAX_TEXTAREA_LENGTH_WARNING_TIMEOUT,
	START_NUMBER_OF_MESSAGES,
} from 'constants/constants';
import {findValueByKeyInObject} from 'utils/findValueByKeyInObject';
import {ActionType} from 'models/enums/ActionType.enum';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import TooltipPositoinType from 'models/enums/TooltipPositoinType.enum';
import ToasNames from 'models/enums/ToasNames.enum';
import appService from 'store/appService';
import roomServices from 'store/roomServices';
import userServices from 'store/userServices';
import chatServices from 'store/chatServices';
import toastService from 'store/toastService';
import controlPanelServices from 'store/controlPanelServices';
import actionMessageServices from 'store/actionMessageServices';
import linkPreviewServices from 'store/linkPreviewServices';
import stickersEmojiServices from 'store/stickersEmojiServices';
import slowModeServices from 'store/slowModeServices';
import suggestServices from 'store/suggestServices';
import modalServices from 'store/modalServices';
import SocketIoServices from 'services/SocketIoServices';
import UserService from 'services/api/UserService';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {isDesktop, isMobile} from 'react-device-detect';
import {ToastIconName} from 'components/toasts/ToastIcons';
import Tooltip from 'components/tooltip/Tooltip';
import {ReactComponent as IcoSendMessage} from 'assets/svg/ico-send-message.svg';
import {ReactComponent as IcoStickers} from 'assets/svg/ico-stickers.svg';
import {ReactComponent as IcoKeyboard} from 'assets/svg/ico-keyboard.svg';
import {Field} from 'components/hoc';
import Button from 'components/hoc/Button';
import debounce from 'lodash/debounce';
import classNames from 'classnames';
import checkRealTextLength from 'utils/checkRealTextLength';
import useL10n from 'l10n/useL10n';
import useAnalytics from 'hooks/useAnalytics';
import useLinkPreview from 'hooks/useLinkPreview';
import useAntimat from 'hooks/useAntimat';
import useChatActions from 'hooks/useChatActions';

const LINE_HEIGHT = 20; // px
const PADDING_VERTICAL = 6; // px
const BORDER_WIDTH = 2; // px
const TIME_FOR_TIMER_SLOWMODE = 1000; // ms
const DELAY_FOR_SEND_MESSAGE = 500; // ms

interface IChatTextareaProps {
	visibleButtons: boolean;
}

const ChatTextarea: React.FC<IChatTextareaProps> = function ChatTextarea(props) {
	const textInputRef = useRef<HTMLTextAreaElement>(null);
	const blockingSendMessageEnterKeyRef = useRef(false);
	const maxLengthWarningTimeoutRef: {current: NodeJS.Timeout | null} = useRef(null);
	const slowModeTimeLeftTimeoutRef: {current: NodeJS.Timeout | null} = useRef(null);
	const sendMessageKeyPressTimeoutRef: {current: NodeJS.Timeout | null} = useRef(null);
	const referenceSlowMode = useRef<HTMLDivElement>(null);
	const [blockedBtnSend, setBlockedBtnSend] = useState(false);
	const [hasLinkPreview, setHasLinkPreview] = useState(false);
	const [maxLengthWarning, setMaxLengthWarning] = useState(false);
	const [maxLengthData, setMaxLengthData] = useState({
		characters: MAX_TEXTAREA_LENGTH,
		visivbeCounter: MIN_TEXTAREA_LENGTH_VISIBLE_COUNTER,
	});
	const [slowModeTooltip, setSlowModeTooltip] = useState(false);
	const [sendAnalyticsSlowModeEnable, setSendAnalyticsSlowModeEnable] = useState(true);
	const {appReadOnly, language, isInternernetActive} = useLocalObservable(() => appService);
	const {
		roomId,
		myTalker,
		clearChatTextarea,
		toggleClearChatTextarea,
		previousMessagesMode,
		togglePreviousMessagesMode,
		removeUnrecordedMessages,
	} = useLocalObservable(() => roomServices);
	const {accessToken, userData, isUserExternalIdCorrect} = useLocalObservable(() => userServices);
	const {
		textareaValue,
		visibleTextareaSendBtn,
		chatBot,
		setTextareaValue,
		setVisibleTextareaSendBtn,
	} = useLocalObservable(() => chatServices);
	const {addToast} = useLocalObservable(() => toastService);
	const {toggleVisibleButtons} = useLocalObservable(() => controlPanelServices);
	const {isVisibleActionMessage, toggleIsVisibleActionMessage, actionData} = useLocalObservable(
		() => actionMessageServices
	);
	const {linkPreviewData, setLinkPreviewData, toggleIsVisibleLinkPreviewData} = useLocalObservable(
		() => linkPreviewServices
	);
	const {visibleStickersEmoji, togglleVisibleStickersEmoji} = useLocalObservable(
		() => stickersEmojiServices
	);
	const {toasts, deleteToastByMessage} = useLocalObservable(() => toastService);
	const {
		slowMode,
		slowModeTimeLeft,
		slowModeTimeLeftOffset,
		slowModeCountdownEnable,
		setSlowModeTimeLeft,
		setslowModeTimeLeftOffset,
		toggleSlowModeCountdownEnable,
	} = useLocalObservable(() => slowModeServices);
	const {visibleSuggest, toggleVisibleSuggest} = useLocalObservable(() => suggestServices);
	const {toggleChatBotRulesModal} = useLocalObservable(() => modalServices);

	const {chatScreen, toasts: toastsTranslate, tooltips} = useL10n();
	const {sendAnalytics} = useAnalytics();
	const {getMetadata, abortGetMetadata} = useLinkPreview();
	const {containsMat} = useAntimat();
	const {getMessagesWithServices} = useChatActions();

	const isMyTalkerModer = !!myTalker?.isModer;
	const textTrim = textareaValue.trim();
	const realTextLength = checkRealTextLength(textareaValue);

	const clearMaxLengthWarningTimeout = () => {
		if (maxLengthWarningTimeoutRef.current) {
			clearTimeout(maxLengthWarningTimeoutRef.current);
		}
	};

	const abortAndClearLinkPreview = () => {
		abortGetMetadata();
		toggleIsVisibleLinkPreviewData(false, true);
	};

	const findLinks = async (incomingText: string) => {
		const arrayFoundLinks = incomingText.match(/\bhttp(s?):\/\/\S+/gi);
		if (arrayFoundLinks?.length && arrayFoundLinks.length > 0) {
			const response = await getMetadata(arrayFoundLinks[0]);
			if (response) {
				if (linkPreviewData?.title !== response.url) {
					setLinkPreviewData({
						title: response.url || response.siteName,
						text: response.description,
					});
					toggleIsVisibleLinkPreviewData(true);
				}
				return;
			}
		}
		abortAndClearLinkPreview();
	};

	const slowModeCountdown = () => {
		let timeLeft = slowModeTimeLeft;
		if (slowModeTimeLeft - slowModeTimeLeftOffset - TIME_FOR_TIMER_SLOWMODE > 0) {
			timeLeft = slowModeTimeLeft - slowModeTimeLeftOffset;
		}

		if (slowModeTimeLeftTimeoutRef.current) {
			clearInterval(slowModeTimeLeftTimeoutRef.current);
		}

		slowModeTimeLeftTimeoutRef.current = setInterval(() => {
			const calcTimeLeft = timeLeft - TIME_FOR_TIMER_SLOWMODE;
			if (calcTimeLeft >= TIME_FOR_TIMER_SLOWMODE) {
				timeLeft = calcTimeLeft;
				setSlowModeTimeLeft(calcTimeLeft);
				return;
			}

			setSlowModeTimeLeft(0);
			setslowModeTimeLeftOffset(0);
			toggleSlowModeCountdownEnable(false);
			setSendAnalyticsSlowModeEnable(true);

			if (slowModeTimeLeftTimeoutRef.current) {
				clearInterval(slowModeTimeLeftTimeoutRef.current);
			}
		}, TIME_FOR_TIMER_SLOWMODE);
	};

	const slowModeConvertTime = (time: number) => {
		const minutes = Math.floor(time / 60000);
		const seconds = ((time % 60000) / 1000).toFixed(0);
		// const minutesCorrect = `00${minutes}`.substring(minutes.toString().length);
		const secondsCorrect = `00${seconds}`.substring(seconds.toString().length);
		return minutes > 0 ? `${minutes}:${secondsCorrect}` : secondsCorrect;
	};

	const suggestManagement = (incomingValue: string) => {
		if (chatBot && chatBot.isActive) {
			if (incomingValue.length === 1 && /^@/.test(incomingValue) && !visibleSuggest) {
				toggleVisibleSuggest(true);
			}
			if (
				!incomingValue.length ||
				(incomingValue.length && !/^@/.test(incomingValue)) ||
				(`@${chatBot.name}` === incomingValue && visibleSuggest) ||
				(/^@/.test(incomingValue) && !`@${chatBot.name}`.includes(incomingValue))
			) {
				toggleVisibleSuggest(false);
			}

			return;
		}

		toggleVisibleSuggest(false);
	};

	const checkContactingChatbot = (textInputValue: string) => {
		const textInputValueTrim = textInputValue.trim();
		const textInputValueSplit = textInputValueTrim.split(' ');
		return (
			(/^@/.test(textInputValueTrim) &&
				textInputValueSplit.length === 1 &&
				chatBot &&
				`@${chatBot.name}` === textInputValueTrim) ||
			(/^@/.test(textInputValueTrim) &&
				textInputValueSplit.length > 1 &&
				chatBot &&
				!chatBot.isActive &&
				textInputValueSplit[0] === `@${chatBot.name}`)
		);
	};

	const userExtraDataCheckChatBotRules = async () => {
		const userExtraData = userData?.extraData;
		const userExtraDataFindRules = userExtraData
			? Object.keys(JSON.parse(userExtraData)).find(item => item === 'rules')
			: false;
		if (
			!userExtraData ||
			(userExtraData && userExtraDataFindRules && !JSON.parse(userExtraData).rules.chatBot)
		) {
			const extraDataForPatchUser = userExtraData
				? {
						...JSON.parse(userExtraData),
						rules: {...JSON.parse(userExtraData).rules, chatBot: true},
				  }
				: {rules: {chatBot: true}};
			toggleChatBotRulesModal(true);
			await UserService.patchUserData(
				{extraData: JSON.stringify(extraDataForPatchUser)},
				accessToken
			);
		}
	};

	const textForSlowModeTooltip = () => {
		let findtTime = times.find(item => item.time === slowMode.local.time);
		if (slowMode.global.enable && !slowMode.local.enable) {
			findtTime = times.find(item => item.time === slowMode.global.time);
		}
		if (findtTime) {
			const findTitle = findValueByKeyInObject<{default: string; prefix?: string}>(
				findtTime.title,
				language,
				{default: findtTime.title.ru.default}
			);
			return tooltips.slowMode.btnSendMessage(findTitle.default);
		}
		return '';
	};

	const onContainsMatHandler = useCallback(
		debounce(
			async (value: string, isVisibleToastWarning) => {
				if (value.trim()) {
					const {status, message, isVailable} = await containsMat(value.trim(), language, () =>
						setBlockedBtnSend(true)
					);
					if (
						status === ResponseStatus.SUCCESS &&
						textInputRef.current &&
						textInputRef.current.value
					) {
						if (!checkContactingChatbot(value)) {
							if (message) {
								setVisibleTextareaSendBtn(true);
								if (myTalker?.isModer) {
									findLinks(message);
								}
							} else {
								setVisibleTextareaSendBtn(false);
								if (linkPreviewData) {
									abortAndClearLinkPreview();
								}
							}

							if (!isVailable) {
								if (!isVisibleToastWarning) {
									setBlockedBtnSend(true);
									addToast({
										name: ToasNames.OBSCENITIES,
										iconName: ToastIconName.brick,
										liveTime: 3000,
										message: toastsTranslate.obscenities,
										messageColor: 'rgba(235, 77, 61, 1)',
										cancellable: true,
									});
								}

								return;
							}

							if (isVailable) {
								setBlockedBtnSend(false);
								deleteToastByMessage(toastsTranslate.obscenities);
							}

							return;
						}
					}
				}

				setVisibleTextareaSendBtn(false);
				setBlockedBtnSend(false);
			},
			100,
			{leading: false}
		),
		[myTalker, linkPreviewData, language, chatBot]
	);

	const onChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const {value} = e.target;
		setTextareaValue(value);
		suggestManagement(value);
		onContainsMatHandler(value, !!toasts.find(t => t.name && t.name === ToasNames.OBSCENITIES));
	};

	const onFocusHandler = () => {
		let checkScrollEnd = false;
		const messagesScroll = document.querySelector('.chat__axis-y');

		if (messagesScroll) {
			checkScrollEnd =
				messagesScroll.scrollTop + messagesScroll.clientHeight === messagesScroll.scrollHeight;
		}

		toggleVisibleButtons(false);

		if (!isVisibleActionMessage) {
			setTimeout(() => {
				if (messagesScroll && checkScrollEnd) {
					messagesScroll.scroll({top: messagesScroll.scrollHeight});
				}
			}, 200);
		}

		if (isMobile && visibleStickersEmoji) {
			togglleVisibleStickersEmoji(false);
		}
	};

	const onInputHandler = () => {
		if (textInputRef.current) {
			textInputRef.current.style.height = 'auto';
			textInputRef.current.style.height = `${textInputRef.current.scrollHeight}px`;
		}
	};

	const sendMessageToSocket = async () => {
		if (!appReadOnly && isUserExternalIdCorrect) {
			setBlockedBtnSend(true);
			const {isVailable}: any = await containsMat(textTrim, language);
			if (roomId && textTrim && isVailable) {
				if (previousMessagesMode) {
					togglePreviousMessagesMode(false);
					removeUnrecordedMessages();
					getMessagesWithServices(START_NUMBER_OF_MESSAGES);
				}

				if (actionData) {
					const {type, message} = actionData;

					if (type === ActionType.REPLAY) {
						sendAnalytics('message_replied');
						SocketIoServices.emitMessage({
							text: textTrim,
							externalRoomId: roomId,
							mentionMessageId: message.id,
							hasPreview: hasLinkPreview,
						});
					}

					if (type === ActionType.EDIT) {
						sendAnalytics('message_changed');
						SocketIoServices.emitEditMessage({
							messageId: message.id,
							text: textTrim,
							externalRoomId: roomId,
							hasPreview: hasLinkPreview,
						});
					}

					toggleIsVisibleActionMessage(false, true);
				} else {
					if (
						chatBot &&
						chatBot.isActive &&
						/^@/.test(textTrim) &&
						textTrim.split(' ')[0] === `@${chatBot.name}`
					) {
						sendAnalytics('bot_request');
						userExtraDataCheckChatBotRules();
					}

					sendAnalytics('message_sent');
					SocketIoServices.emitMessage({
						text: textTrim,
						externalRoomId: roomId,
						hasPreview: hasLinkPreview,
					});
				}

				setTextareaValue('');
				abortAndClearLinkPreview();
				toggleVisibleSuggest(false);
				setVisibleTextareaSendBtn(false);
				setBlockedBtnSend(false);

				if (!isMyTalkerModer && (slowMode.global.enable || slowMode.local.enable)) {
					setSlowModeTimeLeft(slowMode.local.enable ? slowMode.local.time : slowMode.global.time);
					toggleSlowModeCountdownEnable(true);
				}

				if (textInputRef.current) {
					textInputRef.current.style.height = `${LINE_HEIGHT + PADDING_VERTICAL + BORDER_WIDTH}px`;
				}
			}
		}
	};

	const debounceSendMessageToSocket = debounce(sendMessageToSocket, DELAY_FOR_SEND_MESSAGE, {
		leading: true,
		trailing: false,
	});

	const onCheckKeyUp = () => {
		if (realTextLength >= maxLengthData.characters && !props.visibleButtons && !maxLengthWarning) {
			setMaxLengthWarning(true);
			clearMaxLengthWarningTimeout();
			maxLengthWarningTimeoutRef.current = setTimeout(() => {
				setMaxLengthWarning(false);
			}, MAX_TEXTAREA_LENGTH_WARNING_TIMEOUT);
		}
	};

	const onCheckKeyPress = async (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (e.key === 'Enter' && !e.shiftKey && isDesktop) {
			e.preventDefault();
			if (
				!appReadOnly &&
				isUserExternalIdCorrect &&
				!blockedBtnSend &&
				!blockingSendMessageEnterKeyRef.current &&
				!checkContactingChatbot(e.currentTarget.value) &&
				((!slowMode.global.enable && !slowMode.local.enable) ||
					((slowMode.global.enable || slowMode.local.enable) && !slowModeCountdownEnable))
			) {
				blockingSendMessageEnterKeyRef.current = true;
				await debounceSendMessageToSocket();
				sendMessageKeyPressTimeoutRef.current = setTimeout(() => {
					blockingSendMessageEnterKeyRef.current = false;
				}, DELAY_FOR_SEND_MESSAGE);
			}
		}
	};

	const onTogglleVisibleStickersEmojiHandler = () => {
		if (!appReadOnly && isUserExternalIdCorrect) {
			if (visibleStickersEmoji) {
				textInputRef.current?.focus();
			} else {
				sendAnalytics('sticker_button_pressed');
			}
			togglleVisibleStickersEmoji(!visibleStickersEmoji);
		}
	};

	useEffect(() => {
		return () => {
			clearMaxLengthWarningTimeout();
			if (slowModeTimeLeftTimeoutRef.current) {
				setSlowModeTimeLeft(0);
				setslowModeTimeLeftOffset(0);
				toggleSlowModeCountdownEnable(false);
				clearInterval(slowModeTimeLeftTimeoutRef.current);
			}
			if (sendMessageKeyPressTimeoutRef.current) {
				clearTimeout(sendMessageKeyPressTimeoutRef.current);
			}
			if (maxLengthWarningTimeoutRef.current) {
				clearTimeout(maxLengthWarningTimeoutRef.current);
			}
		};
	}, []);

	useEffect(() => {
		const textInputRefCurrent = textInputRef.current;
		if (textInputRefCurrent && textInputRefCurrent.value.length > 0) {
			if (!props.visibleButtons) {
				textInputRefCurrent.removeAttribute('style');
				textInputRefCurrent.setAttribute('style', `height:${textInputRefCurrent.scrollHeight}px;`);
				return;
			}
			textInputRefCurrent.removeAttribute('style');
		}
	}, [textTrim, props.visibleButtons]);

	useEffect(() => {
		if (actionData) {
			const {type, message} = actionData;

			if (type === ActionType.EDIT) {
				setTextareaValue(message.text);
			}

			toggleVisibleButtons(false);
		}
	}, [actionData]);

	useEffect(() => {
		if (linkPreviewData) {
			setHasLinkPreview(true);
		} else {
			setHasLinkPreview(false);
			if (textTrim && textInputRef.current) {
				textInputRef.current.focus();
			}
		}
	}, [linkPreviewData]);

	useEffect(() => {
		if (clearChatTextarea) {
			if (!myTalker?.isBanned) {
				setTextareaValue('');
			}
			toggleClearChatTextarea(false);
			toggleVisibleSuggest(false);
			abortAndClearLinkPreview();
			setVisibleTextareaSendBtn(false);
			textInputRef.current?.removeAttribute('style');
		}
	}, [clearChatTextarea]);

	useEffect(() => {
		if (textareaValue.length > 0 && !(textareaValue.length === 1 && /^@/.test(textareaValue))) {
			setVisibleTextareaSendBtn(true);
		}
	}, [myTalker?.isBanned]);

	useEffect(() => {
		if (myTalker) {
			if (myTalker.isModer) {
				setMaxLengthData({
					characters: MAX_TEXTAREA_LENGTH_FOR_MODERATOR,
					visivbeCounter: MIN_TEXTAREA_LENGTH_VISIBLE_COUNTER_FOR_MODERATOR,
				});
				return;
			}
			if (textareaValue.length > MAX_TEXTAREA_LENGTH) {
				setTextareaValue(textareaValue.slice(0, MAX_TEXTAREA_LENGTH));
			}
			setMaxLengthData({
				characters: MAX_TEXTAREA_LENGTH,
				visivbeCounter: MIN_TEXTAREA_LENGTH_VISIBLE_COUNTER,
			});
		}
	}, [myTalker]);

	useEffect(() => {
		if (
			!isMyTalkerModer &&
			(slowMode.global.enable || slowMode.local.enable) &&
			slowModeCountdownEnable
		) {
			slowModeCountdown();
		}
	}, [myTalker, slowMode, slowModeCountdownEnable]);

	useEffect(() => {
		if (!slowMode.global.enable && !slowMode.local.enable && slowModeTimeLeftTimeoutRef.current) {
			setSlowModeTimeLeft(0);
			setslowModeTimeLeftOffset(0);
			toggleSlowModeCountdownEnable(false);
			clearInterval(slowModeTimeLeftTimeoutRef.current);
		}
	}, [slowMode, slowModeTimeLeftTimeoutRef.current]);

	const chatTextareaWrap = classNames('chat__textarea-wrap', {
		'chat__textarea-wrap--warning': maxLengthWarning,
	});

	const chatTextareaStickersEmojiBtnClasses = classNames('chat__textarea-stickers-emoji-btn', {
		'chat__textarea-stickers-emoji-btn--upper':
			realTextLength > maxLengthData.visivbeCounter && !props.visibleButtons,
	});

	const textareaClass = classNames('chat__textarea-item', {
		'chat__textarea-item--wtext': realTextLength > maxLengthData.visivbeCounter,
	});

	const renderTextLength = useCallback(() => {
		if (realTextLength > maxLengthData.visivbeCounter && !props.visibleButtons) {
			return (
				<span className='chat__text-length'>
					{realTextLength}/{maxLengthData.characters}
				</span>
			);
		}
		return null;
	}, [textareaValue, props.visibleButtons, maxLengthData]);

	const renderTextarea = () => {
		return (
			<div className={chatTextareaWrap}>
				<div className='chat__textarea-container'>
					<Field
						fieldType='textarea'
						placeholder={chatScreen.inputPlaceholder}
						className={textareaClass}
						innerRef={textInputRef}
						autoComplete='off'
						autoCorrect='off'
						spellCheck={false}
						value={textareaValue}
						rows={1}
						maxLength={maxLengthData.characters}
						onChange={onChangeHandler}
						onFocus={onFocusHandler}
						onKeyDown={onCheckKeyPress}
						onKeyUp={onCheckKeyUp}
						onInput={onInputHandler}
					/>

					{renderTextLength()}

					<Button
						className={chatTextareaStickersEmojiBtnClasses}
						onClick={onTogglleVisibleStickersEmojiHandler}>
						{visibleStickersEmoji ? <IcoKeyboard /> : <IcoStickers />}
					</Button>
				</div>

				{visibleTextareaSendBtn && (
					<div className='chat__textarea-wrap-btn' ref={referenceSlowMode}>
						{!isMyTalkerModer &&
						(slowMode.global.enable || slowMode.local.enable) &&
						slowModeTimeLeft ? (
							<>
								<Button
									className='chat__textarea-timer-btn'
									onClick={() => {
										setSlowModeTooltip(!slowModeTooltip);
										if (sendAnalyticsSlowModeEnable) {
											setSendAnalyticsSlowModeEnable(false);
											sendAnalytics('message_sending_blocked_slowmode');
										}
									}}>
									<span>{slowModeConvertTime(slowModeTimeLeft)}</span>
								</Button>

								{slowModeTooltip && textForSlowModeTooltip() && (
									<Tooltip
										referenceElement={referenceSlowMode.current}
										position={TooltipPositoinType.TOP}
										text={textForSlowModeTooltip()}
										delay={3000}
										callbackOnHide={() => setSlowModeTooltip(false)}
									/>
								)}
							</>
						) : (
							<Button
								className='chat__textarea-send-btn'
								disabled={
									blockedBtnSend || !isInternernetActive || !isUserExternalIdCorrect || appReadOnly
								}
								onClick={debounceSendMessageToSocket}>
								<IcoSendMessage />
							</Button>
						)}
					</div>
				)}
			</div>
		);
	};

	const renderBanText = () => {
		return (
			<span
				className='control-panel__ban-alert'
				dangerouslySetInnerHTML={{__html: chatScreen.cantWrite}}
			/>
		);
	};

	return myTalker && !!myTalker.isBanned ? renderBanText() : renderTextarea();
};

export default observer(ChatTextarea);
