import linkPreviewServices from 'store/linkPreviewServices';
import {FunctionComponent} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {CSSTransition} from 'react-transition-group';
import {ReactComponent as IconLink} from 'assets/svg/ico-link.svg';
import {ReactComponent as IconClose} from 'assets/svg/ico-close.svg';
import Button from 'components/hoc/Button';

import './linkPreviewWriting.scss';

const LinkPreviewWriting: FunctionComponent = function LinkPreviewWriting() {
	const {isVisibleLinkPreviewWriting, linkPreviewData, toggleIsVisibleLinkPreviewData} =
		useLocalObservable(() => linkPreviewServices);

	if (!linkPreviewData || !linkPreviewData.title) {
		return null;
	}

	const {title, text} = linkPreviewData;

	const onCloseHandler = () => {
		toggleIsVisibleLinkPreviewData(false, true);
	};

	return (
		<CSSTransition in={isVisibleLinkPreviewWriting} timeout={500} classNames='fade' unmountOnExit>
			<div className='link-preview-writing'>
				<div className='link-preview-writing__icon'>
					<IconLink />
				</div>
				<div className='link-preview-writing__content'>
					<div className='link-preview-writing__title'>{title}</div>
					{text && <div className='link-preview-writing__text'>{text}</div>}
				</div>
				<Button className='link-preview-writing__close' onClick={onCloseHandler}>
					<IconClose />
				</Button>
			</div>
		</CSSTransition>
	);
};

export default observer(LinkPreviewWriting);
