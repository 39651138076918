import {PollEditActionType} from 'models/enums/Poll.enum';
import {action, computed, makeObservable, observable} from 'mobx';

class ModalServices {
	@observable
	public settingsModalVisible = false;

	@observable
	public profileModalVisible = false;

	@observable
	public blockedModalVisible = false;

	@observable
	public visibleModalUsers = false;

	@observable
	public waitingSpeakersModalVisible = false;

	@observable
	public languageModalVisible = false;

	@observable
	public pollCreateModalVisible = false;

	@observable
	public onboardModalVisible = false;

	@observable
	public pollEditModalVisible: {
		visible: boolean;
		typeAction: PollEditActionType;
	} = {
		visible: false,
		typeAction: PollEditActionType.EDIT,
	};

	@observable
	public pollModalVisible = false;

	@observable
	public rulesModalVisible = false;

	@observable
	public changeNameModal = false;

	@observable
	public feedbackAudioModal = false;

	@observable
	public slowModeModal = false;

	@observable
	public slowModeTimeoutModal = false;

	@observable
	public chatBotRulesModal = false;

	@observable
	public shareBetModal = false;

	@observable
	public recordingModalVisible = false;

	// changeNameModal
	@action
	public toggleChangeNameModal = (value: boolean) => {
		this.changeNameModal = value;
	};

	// settingsModal
	@action
	public showSettingsModal = () => {
		this.settingsModalVisible = true;
	};

	@action
	public hideSettingsModal = () => {
		this.settingsModalVisible = false;
	};

	// profileModal
	@action
	public showProfileModal = () => {
		this.profileModalVisible = true;
	};

	@action
	public hideProfileModal = () => {
		this.profileModalVisible = false;
	};

	// blockedModal
	@action
	public showBlockedModal = () => {
		this.blockedModalVisible = true;
	};

	@action
	public hideBlockedModal = () => {
		this.blockedModalVisible = false;
	};

	// modalUsers
	@action
	public hideVisibleModalUsers = () => {
		this.visibleModalUsers = false;
	};

	@action
	public showVisibleModalUsers = () => {
		this.visibleModalUsers = true;
	};

	// waitingSpeakersModal
	@action
	public toggleWaitingSpeakersModal = (visible: boolean) => {
		this.waitingSpeakersModalVisible = visible;
	};

	// languageModal
	@action
	public toggleLanguageModal = (visible: boolean) => {
		this.languageModalVisible = visible;
	};

	// pollCreateModal
	@action
	public togglePollCreateModalVisible = (visible: boolean) => {
		this.pollCreateModalVisible = visible;
	};

	// pollEditModal
	@action
	public togglePollEditModalVisible = (
		visible: boolean,
		typeAction: PollEditActionType = PollEditActionType.EDIT
	) => {
		this.pollEditModalVisible = {visible, typeAction};
	};

	// pollModal
	@action
	public togglePollModalVisible = (visible: boolean) => {
		this.pollModalVisible = visible;
	};

	@action
	public toggleRulesModal = (visible: boolean) => {
		this.rulesModalVisible = visible;
	};

	@action
	public toggleOnboardModal = (visible: boolean) => {
		this.onboardModalVisible = visible;
	};

	@action
	public toggleFeedbackAudioModal = (visible: boolean) => {
		this.feedbackAudioModal = visible;
	};

	@action
	public toggleSlowModeModal = (visible: boolean) => {
		this.slowModeModal = visible;
	};

	@action
	public toggleSlowModeTimeoutModal = (visible: boolean) => {
		this.slowModeTimeoutModal = visible;
	};

	@action
	public toggleChatBotRulesModal = (visible: boolean) => {
		this.chatBotRulesModal = visible;
	};

	@action
	public toggleShareBetModal = (visible: boolean) => {
		this.shareBetModal = visible;
	};

	@action
	public toggleRecordingModal = (visible: boolean) => {
		this.recordingModalVisible = visible;
	};

	@action
	public hideAllModals = () => {
		this.settingsModalVisible = false;
		this.profileModalVisible = false;
		this.blockedModalVisible = false;
		this.visibleModalUsers = false;
		this.waitingSpeakersModalVisible = false;
		this.languageModalVisible = false;
		this.pollCreateModalVisible = false;
		this.onboardModalVisible = false;
		this.pollEditModalVisible = {
			visible: false,
			typeAction: PollEditActionType.EDIT,
		};
		this.pollModalVisible = false;
		this.rulesModalVisible = false;
		this.changeNameModal = false;
		this.feedbackAudioModal = false;
		this.slowModeModal = false;
		this.slowModeTimeoutModal = false;
		this.chatBotRulesModal = false;
		this.shareBetModal = false;
		this.recordingModalVisible = false;
	};

	@action
	public hideAllPollModals = () => {
		this.pollCreateModalVisible = false;
		this.pollEditModalVisible = {
			visible: false,
			typeAction: PollEditActionType.EDIT,
		};
		this.pollModalVisible = false;
		this.rulesModalVisible = false;
	};

	@computed
	get isModalOpen() {
		return (
			this.settingsModalVisible ||
			this.profileModalVisible ||
			this.blockedModalVisible ||
			this.visibleModalUsers ||
			this.waitingSpeakersModalVisible ||
			this.languageModalVisible ||
			this.pollCreateModalVisible ||
			this.onboardModalVisible ||
			this.pollEditModalVisible.visible ||
			this.pollModalVisible ||
			this.rulesModalVisible ||
			this.changeNameModal ||
			this.feedbackAudioModal ||
			this.slowModeModal ||
			this.slowModeTimeoutModal ||
			this.chatBotRulesModal ||
			this.shareBetModal ||
			this.recordingModalVisible
		);
	}

	constructor() {
		makeObservable(this);
	}
}

export default new ModalServices();
