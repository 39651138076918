/* eslint-disable max-lines */
import {MAXIMUM_NUMBER_OF_MESSAGES, NUMBER_OF_MESSAGES_TO_DELETE} from 'constants/constants';
import UserRole from 'models/enums/UserRole.enum';
import MessageType from 'models/enums/MessageType.enum';
import {
	AgoraCreds,
	Message,
	Messages,
	Talker,
	Talkers,
	Room,
	SubmenuMessage,
	SubmenuButton,
	RestApiDataLoaded,
	Reaction,
} from 'models/room';
import {PollMessageData} from 'models/poll';
import {action, computed, makeObservable, observable} from 'mobx';

class RoomServices {
	@observable
	public roomId: string | null = null;

	@observable
	public roomName: string | undefined = undefined;

	@observable
	public talkers: Talkers = [];

	@observable
	public myTalker: Talker | null = null;

	@observable
	public mentionMessageArray: Message[] = [];

	@observable
	public bannedTalkers: Talkers = [];

	@observable
	private _pinnedMessage: Message | null = null;

	@observable
	private _messages: Messages = [];

	@observable
	private _unrecordedMessages: Messages = [];

	@observable
	private _blockedUsers: number[] = [];

	@observable
	private _agoraCreds: AgoraCreds = {
		appId: null,
		token: null,
		channelName: null,
		talker: null,
	};

	@observable
	public clearChatTextarea = false;

	@observable
	public roomData: Room | null = null;

	@observable
	public submenuMessage: SubmenuMessage | null = null;

	@observable
	public submenuButtons: SubmenuButton[] = [];

	@observable
	public restApiDataLoaded: RestApiDataLoaded = {
		messagesLoaded: false,
		pinnedMessageLoaded: false,
		talkersLoaded: false,
		bannedTalkersLoaded: false,
		pollLoaded: false,
	};

	@observable
	public isMessagesLoaded = false;

	@observable
	public chatScrollPositionBottom = true;

	@observable
	public previousMessagesMode = false;

	@action
	public setRoomData = (value: Room) => {
		this.roomData = value;
	};

	@action
	public updateRoomData = (data: {[k: string]: any}) => {
		if (this.roomData) {
			this.roomData = {...this.roomData, ...data};
		}
	};

	@action
	public setAgoraCreds = (value: AgoraCreds) => {
		this._agoraCreds = value;
	};

	@action
	public setTalkers = (value: Talkers) => {
		this.talkers = value;
	};

	@action
	public setMyTalker = (talker: Talker | null) => {
		this.myTalker = talker;
	};

	@action
	public updateMyTalker = (data: any) => {
		this.myTalker = {...this.myTalker, ...data};
	};

	@action
	public addTalker = (talker: Talker) => {
		if (!this.talkers.find(t => t.user.id === talker.user?.id)) {
			this.talkers = [...this.talkers, talker];
		}
	};

	@action
	public updateTalker = (talker: Talker) => {
		this.talkers = this.talkers.map(t => {
			if (t.id === talker.id) {
				return talker;
			}
			return t;
		});

		if (this._messages.find(m => m.talker.id === talker.id)) {
			this._messages = this._messages.map(m => {
				if (m.talker.id === talker.id) {
					return {...m, talker: {...talker}};
				}
				return m;
			});
		}
	};

	@action
	public updateUserInfo = (incomingUser: User) => {
		this.talkers = this.talkers.map(t => {
			const {user} = t;
			if (user && user.id === incomingUser.id) {
				return {...t, user: {...incomingUser}};
			}
			return t;
		});

		this._messages = this._messages.map(message => {
			const {talker} = message;
			if (talker.user && talker.user?.id === incomingUser.id) {
				return {...message, talker: {...message.talker, user: {...incomingUser}}};
			}
			return message;
		});
	};

	@action
	public updateTalkerMic = (userID: number, micStatus: boolean) => {
		this.talkers = this.talkers.map(t => {
			if (t.user.id === userID) {
				const updatedTalker = {...t};
				updatedTalker.isMuted = micStatus;
				return updatedTalker;
			}
			return t;
		});

		if (this._messages.find(m => m.talker.user.id === userID)) {
			this._messages = this._messages.map(m => {
				if (m.talker.user.id === userID) {
					return {...m, talker: {...m.talker, isMuted: micStatus}};
				}
				return m;
			});
		}
	};

	@action
	public updateActiveVoice = (talkerID: number, voiceActive: boolean) => {
		const findTalker = this.talkers.find(t => t.id === talkerID && t.voiceActive !== voiceActive);
		if (findTalker) {
			this.talkers = this.talkers.map(t => {
				if (t.id === talkerID) {
					return {...t, voiceActive};
				}
				return t;
			});
		}
	};

	@action
	public removeTalker = (talkerId: number) => {
		this.talkers = this.talkers.filter(t => t.id !== talkerId);
	};

	@action
	public setRoomId = (value: string) => {
		this.roomId = value;
	};

	@action
	public setRoomName = (value: string) => {
		this.roomName = value;
	};

	@action
	public addMessages = (data: Messages) => {
		this._messages = data;
		this.isMessagesLoaded = true;
	};

	@action
	public unshiftMessages = (data: Messages) => {
		this._messages = [...data, ...this._messages];
	};

	@action
	public pushMessages = (data: Messages) => {
		this._messages = [...this._messages, ...data];
	};

	@action
	public addMessage = (message: Message) => {
		if (!this._messages.find(m => m.id === message.id)) {
			if (this._messages.length >= MAXIMUM_NUMBER_OF_MESSAGES && this.chatScrollPositionBottom) {
				this._messages = [
					...this._messages.slice(NUMBER_OF_MESSAGES_TO_DELETE - MAXIMUM_NUMBER_OF_MESSAGES),
					{...message},
				];
				return;
			}

			this._messages.push(message);
		}
	};

	@action
	public updateMentionMessageArray = (message: Message | null) => {
		if (message === null) {
			this.mentionMessageArray = [];
		} else {
			this.mentionMessageArray = [...this.mentionMessageArray, message];
		}
	};

	@action
	public removeMentionMessage = (messageId: number) => {
		const findMentionById = this.mentionMessageArray.find(
			mentionMessage => mentionMessage.id === messageId
		);
		if (findMentionById) {
			this.mentionMessageArray = this.mentionMessageArray.filter(
				mentionMessage => mentionMessage.id !== messageId
			);
		}
	};

	@action
	public removeMentionMessageForBlockedUser = (blockedUserId: number) => {
		const findMentionByBlockedUserId = this.mentionMessageArray.find(
			mentionMessage => mentionMessage.talker.user?.id === blockedUserId
		);
		if (findMentionByBlockedUserId) {
			// удаляем упоминания с заблокированным пользователем из массива упоминаний
			this.mentionMessageArray = this.mentionMessageArray.filter(
				mentionMessage => mentionMessage.talker.user?.id !== blockedUserId
			);

			// сообщения заблокированного пользователя с упоминаниями помечаем прочтенными
			this._messages = this._messages.map(m => {
				if (m.talker.user?.id === blockedUserId && m.isNotReaded) {
					return {...m, isNotReaded: false};
				}
				return m;
			});
		}
	};

	@action
	public reduceMentionMessageArray = () => {
		this.mentionMessageArray.shift();
	};

	@action
	public updateVisibleMessagesByUserId = (userId: number, visible: boolean) => {
		this._messages = this._messages.map(m => {
			// additional delete mentioned message
			if (this.mentionMessageArray !== null) {
				this.mentionMessageArray = this.mentionMessageArray.filter(item => item.id !== m.id);
			}

			if (m.mentionMessage && m.mentionMessage.talker.user?.id === userId) {
				let updateMessage = m;
				if (m.talker.user && m.talker.user?.id === userId) {
					updateMessage = {...m, isVisible: visible};
				}
				if (updateMessage.mentionMessage) {
					updateMessage = {
						...updateMessage,
						mentionMessage: {...updateMessage.mentionMessage, isVisible: visible},
					};
				}
				return updateMessage;
			}

			if (m.talker.user && m.talker.user?.id === userId) {
				return {...m, isVisible: visible};
			}
			return m;
		});
	};

	@action
	public updateMessage = (message: Message) => {
		this._messages = this._messages.map(m => {
			if (m.mentionMessage && m.mentionMessage.id === message.id) {
				let updateMessage = m;
				if (m.id === message.id) {
					updateMessage = {...m, ...message};
				}
				if (updateMessage.mentionMessage) {
					updateMessage = {
						...updateMessage,
						mentionMessage: {...updateMessage.mentionMessage, ...message},
					};
				}
				return updateMessage;
			}
			if (m.id === message.id) {
				return {...m, ...message};
			}
			return m;
		});
		// additional update pinned message
		if (this._pinnedMessage !== null && message.id === this._pinnedMessage.id) {
			this._pinnedMessage = message;
		}
		// additional remove mentioned message if such a message exist
		!message.isVisible && this.removeMentionMessage(message.id);
	};

	@action
	public updateUserMessagesByUserId = (
		incomingUserId: number,
		incomingKey: string,
		incomingValue: string | number | boolean
	) => {
		this._messages = this._messages.map(message => {
			const {talker} = message;
			if (
				talker.user &&
				talker.user?.id === incomingUserId &&
				Object.keys(talker.user).includes(incomingKey)
			) {
				const updateUser = {...talker.user, ...{[incomingKey]: incomingValue}};
				return {...message, talker: {...message.talker, user: {...updateUser}}};
			}
			return message;
		});
	};

	@action
	public updateTalkerMessagesByTalkerId = (
		incomingTalkerId: number,
		incomingKey: string,
		incomingValue: string | number | boolean
	) => {
		this._messages = this._messages.map(message => {
			const {talker} = message;
			if (talker && talker.id === incomingTalkerId && Object.keys(talker).includes(incomingKey)) {
				const updateTalker = {...talker, ...{[incomingKey]: incomingValue}};
				return {...message, talker: {...updateTalker}};
			}
			return message;
		});
	};

	@action
	public removeMessages = () => {
		this._messages = [];
	};

	@action
	public removeMessage = (messageId: number, userData: User, callBackToast: () => void) => {
		const showToast = !!this._messages.find(
			m => m.id === messageId && m.talker.user && m.talker.user.id === userData.id
		);

		if (showToast) {
			callBackToast();
		}

		// mark the mention as deleted
		this._messages = this._messages.map(m => {
			if (m.mentionMessage && m.mentionMessage.id === messageId) {
				return {...m, mentionMessage: {...m.mentionMessage, deletedAt: new Date().toISOString()}};
			}
			return m;
		});

		this._messages = this._messages.filter(m => m.id !== messageId);

		// additional delete pinned message
		if (this._pinnedMessage !== null && messageId === this._pinnedMessage.id) {
			this._pinnedMessage = null;
		}

		// additional delete mentioned message
		if (this.mentionMessageArray !== null) {
			this.mentionMessageArray = this.mentionMessageArray.filter(item => item.id !== messageId);
		}
	};

	@action
	public removeMessageByPollId = (pollId: number) => {
		if (this._messages.find(message => message.type === MessageType.VOTE)) {
			this._messages = this._messages.filter(message => {
				const {pollText, type} = message;
				if (type === MessageType.VOTE) {
					const pollMessageData: PollMessageData = JSON.parse(pollText);
					if (pollMessageData?.poll.id === pollId) {
						return null;
					}
					return message;
				}
				return message;
			});
		}
	};

	@action
	public setUnrecordedMessage = (messages: Message[]) => {
		this._unrecordedMessages = messages;
	};

	@action
	public addUnrecordedMessage = (message: Message) => {
		this._unrecordedMessages.push(message);
	};

	@action
	public removeUnrecordedMessage = (messageId: number) => {
		if (this._unrecordedMessages.find(m => m.id === messageId)) {
			this._unrecordedMessages = this._unrecordedMessages.filter(m => m.id !== messageId);
		}
	};

	@action
	public removeUnrecordedMessages = () => {
		this._unrecordedMessages = [];
	};

	@action
	public addBlockedUsersForFilteredMessages = (data: BlockedUsers) => {
		const {initiator, target} = data;
		const blockedUsers: number[] = [];
		const blockedUsersfilling = (users: User[]) => {
			users.forEach((user: User) => {
				if (user.id && !blockedUsers.includes(user.id)) {
					blockedUsers.push(user.id);
				}
			});
		};
		blockedUsersfilling(initiator);
		blockedUsersfilling(target);
		this._blockedUsers = blockedUsers;
	};

	@action
	public toggleBlockedUserForFilteredMessages = (userId: number, blocked: boolean) => {
		if (blocked) {
			if (!this._blockedUsers.includes(userId)) {
				this._blockedUsers.push(userId);
			}
			return;
		}
		this._blockedUsers = this._blockedUsers.filter(
			(blockedUserId: number) => blockedUserId !== userId
		);
	};

	@action
	public toggleClearChatTextarea = (value: boolean) => {
		this.clearChatTextarea = value;
	};

	@action
	public setBannedTalkers = (talkers: Talkers) => {
		this.bannedTalkers = talkers;
	};

	@action
	public addBannedTalker = (talker: Talker) => {
		if (!this.bannedTalkers.find(m => m.user.id === talker.user?.id)) {
			this.bannedTalkers.push(talker);
		}
	};

	@action
	public removeBannedTalker = (talker: Talker) => {
		if (this.bannedTalkers.find(m => m.user.id === talker.user?.id)) {
			this.bannedTalkers = this.bannedTalkers.filter(item => item.user.id !== talker.user?.id);
		}
	};

	@action
	public updatePinnedMessagePinStatus = (data: Message | null) => {
		if (data !== null) {
			if (this._pinnedMessage !== null) {
				this.updateMessage({...this._pinnedMessage, isPinned: false});
				this.updateMessage(data);
			} else {
				this.updateMessage(data);
			}
		} else {
			this._pinnedMessage !== null && this.updateMessage({...this._pinnedMessage, isPinned: false});
		}
		this._pinnedMessage = data;
	};

	@action
	public setSubmenuMessage = (data: SubmenuMessage | null) => {
		this.submenuMessage = data;
		if (!data) {
			this.submenuButtons = [];
		}
	};

	@action
	public setSubmenuButtons = (data: SubmenuButton[] | []) => {
		this.submenuButtons = data;
	};

	@action
	public toggleRestApiDataLoaded = (data: RestApiDataLoaded) => {
		this.restApiDataLoaded = {
			...this.restApiDataLoaded,
			...data,
		};
	};

	@action
	public toggleChatScrollPositionBottom = (value: boolean) => {
		this.chatScrollPositionBottom = value;
	};

	@action
	public togglePreviousMessagesMode = (value: boolean) => {
		this.previousMessagesMode = value;
	};

	@action
	public addReactionToMessage = (incomingReaction: Reaction) => {
		const findMessage = this._messages.find(message => message.id === incomingReaction.messageId);
		if (findMessage) {
			if (
				findMessage.reactions &&
				!findMessage.reactions.find(
					reaction =>
						reaction.emotion === incomingReaction.emotion &&
						reaction.talkerId === incomingReaction.talkerId
				)
			) {
				this._messages = this._messages.map(m => {
					const {id, reactions} = m;
					if (id === findMessage.id && reactions) {
						return {
							...m,
							reactions: [...reactions, incomingReaction],
						};
					}
					return m;
				});
				return;
			}
			this._messages = this._messages.map(m => {
				if (m.id === findMessage.id) {
					return {
						...m,
						reactions: [incomingReaction],
					};
				}
				return m;
			});
		}
	};

	@action
	public removeReactionToMessage = (incomingReaction: Reaction) => {
		const findMessage = this._messages.find(message => message.id === incomingReaction.messageId);
		if (
			findMessage &&
			findMessage.reactions &&
			findMessage.reactions.find(
				reaction =>
					reaction.emotion === incomingReaction.emotion &&
					reaction.talkerId === incomingReaction.talkerId
			)
		) {
			this._messages = this._messages.map(m => {
				const {id, reactions} = m;
				if (id === findMessage.id && reactions) {
					return {
						...m,
						reactions: reactions.filter(
							reaction =>
								reaction.emotion !== incomingReaction.emotion ||
								(reaction.emotion === incomingReaction.emotion &&
									reaction.talkerId !== incomingReaction.talkerId)
						),
					};
				}
				return m;
			});
		}
	};

	@computed
	get messages() {
		const messages = this._messages.slice();
		return this.filterMessages(messages);
	}

	@computed
	get unrecordedMessages() {
		const unrecordedMessages = this._unrecordedMessages.slice();
		return this.filterMessages(unrecordedMessages);
	}

	@computed
	get pinnedMessage() {
		if (this._pinnedMessage !== null) {
			// if (!this._blockedUsers.includes(this._pinnedMessage.user?.id as number)) {
			return this._pinnedMessage;
			// }
		}
		return null;
	}

	@computed
	get talkersWaitingSpeakers() {
		const talkers = this.talkers.slice();
		return talkers.filter(({hand, role}) => hand && role !== UserRole.SPEAKER);
	}

	@computed
	get agoraCreds() {
		return this._agoraCreds;
	}

	@computed
	get blockedUsersForFilteredMessages() {
		return this._blockedUsers.slice();
	}

	private filterMessages(messages: Message[]) {
		return messages.filter(
			message =>
				!this._blockedUsers.includes(message.talker.user?.id as number) &&
				(message.type === MessageType.USER ||
					message.type === MessageType.ADVERTISEMENT ||
					message.type === MessageType.POLLRESULTS ||
					message.type === MessageType.STICKER ||
					message.type === MessageType.BET ||
					(message.type === MessageType.VOTE && message.talker.user?.id === this.myTalker?.user.id))
		);
	}

	constructor() {
		makeObservable(this);
	}
}

export default new RoomServices();
