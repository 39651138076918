import {action, makeObservable, observable} from 'mobx';
import {PollScreens} from 'models/enums/Poll.enum';
import {Poll, PollCreated, PollCreatedOption, PollOption} from 'models/poll';

class PollService {
	@observable
	public screen = PollScreens.SETTINGS;

	@observable
	public poll: Poll | null = null;

	@observable
	public pollCreated: PollCreated | null = null;

	@observable
	public pollAlreadyVoted = false;

	@observable
	public pollOptionsChosen: PollOption[] = [];

	@observable
	public pollOptionsBlocked = false;

	@observable
	public pollWithPics = {value: false, isChanged: false};

	@observable
	public pollVotesAnim: PollOption[] = [];

	@observable
	public pollTooltipVisible = false;

	@observable
	public pollToggleButtonAccent = false;

	@action
	public setPollWithPics = (data: boolean, isChanged = false) => {
		this.pollWithPics = {value: data, isChanged};
	};

	@action
	public setPoll = (poll: Poll | null, roomID: string) => {
		this.poll = poll;
		this.resetPollCreated(roomID);
	};

	@action
	public updatePoll = (data: any) => {
		this.poll = {...this.poll, ...data};
	};

	@action
	public removePoll = () => {
		this.poll = null;
		this.pollCreated = null;
		this.pollAlreadyVoted = false;
		this.pollOptionsBlocked = false;
		this.pollOptionsChosen = [];
	};

	@action
	public setPollOptionVote = (incomingPollOption: PollOption) => {
		if (this.poll && typeof this.poll.options !== 'undefined') {
			const updateOptions = this.poll.options.map(option => {
				if (option.id === incomingPollOption.id) {
					let votesLength = 1;
					if (option.votesLength) {
						votesLength += option.votesLength;
					}
					return {...option, votesLength};
				}
				return option;
			});
			this.poll = {...this.poll, options: updateOptions};
		}
	};

	@action
	public toggllePollAlreadyVoted = (value: boolean) => {
		this.pollAlreadyVoted = value;
	};

	@action
	public toggllePollOptionsBlocked = (value: boolean) => {
		this.pollOptionsBlocked = value;
	};

	@action
	public setPollOptionsChosen = (optionsChosen: PollOption[]) => {
		this.pollOptionsChosen = optionsChosen;
	};

	@action
	public addPollOptionChosen = (optionChosen: PollOption) => {
		if (!this.pollOptionsChosen.find(pollOption => pollOption.id === optionChosen.id)) {
			this.pollOptionsChosen = [...this.pollOptionsChosen, optionChosen];
		}
	};

	@action
	public removePollOptionChosen = (optionChosen: PollOption) => {
		this.pollOptionsChosen = this.pollOptionsChosen.filter(
			pollOption => pollOption.id !== optionChosen.id
		);
	};

	@action
	public setPollCreated = (pollCreatedData: PollCreated | null) => {
		this.pollCreated = pollCreatedData;
	};

	@action
	public updatePollCreated = (pollCreatedData: PollCreated) => {
		this.pollCreated = {...this.pollCreated, ...pollCreatedData};
	};

	@action
	public resetPollCreated = (roomId: string) => {
		if (this.poll) {
			const newOptionsArray = this.poll.options.map((el: PollOption) => {
				const {votesLength, ...elRest} = el;
				return elRest;
			});

			this.pollCreated = {
				text: this.poll.text,
				isMultiple: this.poll.isMultiple,
				type: this.poll.type,
				options: newOptionsArray,
				room: {externalRoomId: roomId},
			};
			return;
		}
		this.pollCreated = null;
	};

	@action
	public resetIsRightPollCreatedOptions = () => {
		if (this.pollCreated?.options) {
			const newOptionsArray = this.pollCreated.options.map((el: PollCreatedOption) => {
				return {...el, isRight: false};
			});
			this.pollCreated = {...this.pollCreated, options: newOptionsArray};
		}
	};

	@action
	public addPollVoteAnim = (incomingPollOption: PollOption) => {
		this.pollVotesAnim = [...this.pollVotesAnim, incomingPollOption];
	};

	@action
	public removePollVoteAnim = (incomingPollOption: PollOption) => {
		this.pollVotesAnim = this.pollVotesAnim.filter(
			item => item.uuid && item.uuid !== incomingPollOption.uuid
		);
	};

	@action
	public toggllePollTooltipVisible = (value: boolean) => {
		this.pollTooltipVisible = value;
	};

	@action
	public toggllePollToggleButtonAccent = (value: boolean) => {
		this.pollToggleButtonAccent = value;
	};

	@action
	public setScreen = (screen: PollScreens) => {
		this.screen = screen;
	};

	constructor() {
		makeObservable(this);
	}
}

export default new PollService();
