import {Message} from 'models/room';
import roomServices from 'store/roomServices';
import useL10n from 'l10n/useL10n';
import useSubmenuActions from 'hooks/useSubmenuActions';
import useAnalytics from 'hooks/useAnalytics';
import {FunctionComponent} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import classNames from 'classnames/bind';
import Button from 'components/hoc/Button';
import {ReactComponent as Show} from 'assets/svg/ico-submenu-show.svg';
import {ReactComponent as Block} from 'assets/svg/ico-submenu-block.svg';

interface IButtonBlockProps {
	message: Message;
}

const ButtonBlock: FunctionComponent<IButtonBlockProps> = function ButtonBlock({message}) {
	const {chatScreen} = useL10n();
	const {talker} = message;
	const {roomId, myTalker, bannedTalkers, setSubmenuMessage, blockedUsersForFilteredMessages} =
		useLocalObservable(() => roomServices);
	const {sendAnalytics} = useAnalytics();
	const {blockUser, unBlockUser, blockUserByUser} = useSubmenuActions();

	const isMyTalkerModer = !!myTalker?.isModer;
	const isTalkerBanned = !!bannedTalkers.find(t => t.id === talker.id);

	const checkForModerator = isTalkerBanned && isMyTalkerModer;
	const checkForUser =
		isTalkerBanned &&
		talker.user &&
		talker.user.id &&
		blockedUsersForFilteredMessages.includes(talker.user.id);
	const checkForAllRoles = checkForModerator || checkForUser;

	const blockUserBtnClasses = classNames('chat__submenu-btn ', {
		'chat__submenu-btn--warn': !checkForAllRoles,
		'chat__submenu-btn--success': checkForAllRoles,
	});

	const onBlockUserHandler = () => {
		if (roomId && talker.user) {
			setSubmenuMessage(null);
			sendAnalytics('ban_pressed');
			if (isMyTalkerModer) {
				isTalkerBanned ? unBlockUser(talker.user, roomId) : blockUser(talker.user);
				return;
			}
			blockUserByUser(talker.user);
		}
	};

	return (
		<Button className={blockUserBtnClasses} onClick={onBlockUserHandler}>
			{checkForAllRoles ? (
				<>
					{chatScreen.chatSubmenu.unblockUser}
					<Show className='chat__submenu-icon' />
				</>
			) : (
				<>
					{chatScreen.chatSubmenu.blockUser}
					<Block className='chat__submenu-icon' />
				</>
			)}
		</Button>
	);
};

export default observer(ButtonBlock);
