import {apiPath} from 'constants/api';
import AudioReasons from 'models/enums/AudioReasons';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import checkResponse from 'utils/checkResponse';
import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';
import Log from 'utils/log';
import axios from 'axios';
// import {} from 'models/responses';

export default class FeedbackService {
	static sendAudioFeedback = async (
		token: UUID,
		externalRoomId: string,
		rating: number,
		issue?: AudioReasons,
		info?: string,
		comment?: string
	) => {
		try {
			const params = {
				externalRoomId,
				rating,
				issue: issue || undefined,
				info: info || undefined,
				comment: comment || undefined,
			};

			const {data, status} = await axios.post(apiPath.feedback, params, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Send audio feedback error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}
