import scrollOnDrag from 'utils/scrollOnDrag';
import {Emotion} from 'models/room';
import roomServices from 'store/roomServices';
import emotionServices from 'store/emotionServices';
import useEmotions from 'hooks/useEmotions';
import {useCallback, useEffect} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import './emotions.scss';

const Emotions: React.FC = function Emotions() {
	const {sendEmotion} = useEmotions();
	const {roomId} = useLocalObservable(() => roomServices);
	const {emotions, idEmotionActive} = useLocalObservable(() => emotionServices);

	useEffect(() => {
		const dragContainer = document.getElementById('dragContainer');
		if (dragContainer) {
			scrollOnDrag(dragContainer);
		}
	}, []);

	const renderEmotion = useCallback(
		(emotion: Emotion) => (
			<button
				type='button'
				key={emotion.id}
				data-id={emotion.id}
				className={`emotions__item ${
					emotion.id === idEmotionActive ? 'emotions__item--active' : null
				}`}
				style={{backgroundImage: `url(${emotion.src})`}}
				onClick={() => sendEmotion(emotion, true)}
				aria-hidden='true'
			/>
		),
		[roomId, idEmotionActive]
	);

	return (
		<div className='emotions'>
			<div className='emotions__items' id='dragContainer'>
				{emotions.map(renderEmotion)}
			</div>
		</div>
	);
};

export default observer(Emotions);
