import {URL_FOR_TECHNICAL_ANALYTICS_DEV, URL_FOR_TECHNICAL_ANALYTICS_PROD} from './constants';

type ApiPath = {
	register: string;
	login: string;
	check: string;
	decrypt: string;
	getMyUser: string;
	patchMyUser: string;
	deleteMyUser: string;
	restoreMyUser: string;
	blockUser: string;
	reportUser: string;
	wordList: string;
	stat: string;
	statEvent: string;
	setPoll: string;
	uploadPollOptionPic: string;
	selfmoder: string;
	checkRealRoomId: string;
	sendPollVote: (externalRoomId: string) => string;
	getUserById: (id: number) => string;
	getRoomData: (externalRoomId: string) => string;
	getMessages: (externalRoomId: string) => string;
	getMessagesAround: (externalRoomId: string) => string;
	getAllDataOnload: (externalRoomId: string) => string;
	getPinnedMessages: (externalRoomId: string) => string;
	getTalkersById: (externalRoomID: string) => string;
	getBannedTalkersById: (externalRoomID: string) => string;
	getPoll: (externalRoomID: string) => string;
	pollVote: (externalRoomID: string) => string;
	endPoll: (externalRoomID: string) => string;
	deletePoll: (externalRoomID: string, pollId: number) => string;
	viewAdvertisement: (advertisementId: number) => string;
	sticker: string;
	antimatCheck: string;
	settings: string;
	getRules: string;
	feedback: string;
	getUserLastMessage: (externalRoomID: string, userId: number) => string;
	getGreetingMessage: (lang: string) => string;
	getChatBot: string;
	getBet: (userId: number, page: number, limit?: number) => string;
};

const apiPath: ApiPath = {
	register: `${process.env.REACT_APP_API_URL}/auth/register`,
	login: `${process.env.REACT_APP_API_URL}/auth/login`,
	check: `${process.env.REACT_APP_API_URL}/auth/check`,
	decrypt: `${process.env.REACT_APP_API_URL}/auth/decrypt`,
	getMyUser: `${process.env.REACT_APP_API_URL}/user/me`,
	patchMyUser: `${process.env.REACT_APP_API_URL}/user/me`,
	deleteMyUser: `${process.env.REACT_APP_API_URL}/user`,
	restoreMyUser: `${process.env.REACT_APP_API_URL}/user/restore`,
	blockUser: `${process.env.REACT_APP_API_URL}/block`,
	reportUser: `${process.env.REACT_APP_API_URL}/report`,
	wordList: `${process.env.REACT_APP_API_URL}/wordlist`,
	stat: `${process.env.REACT_APP_API_URL}/stat`,
	statEvent:
		process.env.REACT_APP_ENVIRONMENT === 'dev'
			? `${URL_FOR_TECHNICAL_ANALYTICS_DEV}/stat/event`
			: `${URL_FOR_TECHNICAL_ANALYTICS_PROD}/stat/event`,
	setPoll: `${process.env.REACT_APP_API_URL}/poll`,
	selfmoder: `${process.env.REACT_APP_API_URL}/admin/talker/moder`,
	checkRealRoomId: `${process.env.REACT_APP_API_URL}/room/real`,
	sendPollVote: externalRoomId => `${process.env.REACT_APP_API_URL}/poll/${externalRoomId}/vote`,
	uploadPollOptionPic: `${process.env.REACT_APP_API_URL}/room/pic`,
	getUserById: id => `${process.env.REACT_APP_API_URL}/user/${id}`,
	getRoomData: externalRoomId => `${process.env.REACT_APP_API_URL}/room/${externalRoomId}`,
	getMessages: externalRoomId => `${process.env.REACT_APP_API_URL}/message/room/${externalRoomId}`,
	getMessagesAround: (externalRoomId: string) =>
		`${process.env.REACT_APP_API_URL}/message/room/${externalRoomId}/around`,
	getAllDataOnload: externalRoomId => `${process.env.REACT_APP_API_URL}/data/${externalRoomId}`,
	getPinnedMessages: externalRoomId =>
		`${process.env.REACT_APP_API_URL}/message/room/${externalRoomId}/pinned`,
	getTalkersById: externalRoomID =>
		`${process.env.REACT_APP_API_URL}/talker/room/${externalRoomID}`,
	getBannedTalkersById: externalRoomID =>
		`${process.env.REACT_APP_API_URL}/talker/room/${externalRoomID}/ban`,
	getPoll: externalRoomID => `${process.env.REACT_APP_API_URL}/poll/${externalRoomID}`,
	pollVote: externalRoomID => `${process.env.REACT_APP_API_URL}/poll/${externalRoomID}/vote`,
	endPoll: externalRoomID => `${process.env.REACT_APP_API_URL}/poll/${externalRoomID}/end`,
	deletePoll: (externalRoomID, pollId) =>
		`${process.env.REACT_APP_API_URL}/poll/${externalRoomID}/${pollId}`,
	viewAdvertisement: advertisementId =>
		`${process.env.REACT_APP_API_URL}/message/advertisement/view/${advertisementId}`,
	sticker: `${process.env.REACT_APP_API_URL}/sticker`,
	antimatCheck: `${process.env.REACT_APP_ANTIMAT_API_URL}/check/`,
	settings: `${process.env.REACT_APP_API_URL}/settings`,
	getRules: `${process.env.REACT_APP_API_URL}/settings/rules`,
	feedback: `${process.env.REACT_APP_API_URL}/feedback`,
	getUserLastMessage: (externalRoomID: string, userId: number) =>
		`${process.env.REACT_APP_API_URL}/message/last/room/${externalRoomID}/user/${userId}`,
	getGreetingMessage: (lang: string) => `${process.env.REACT_APP_API_URL}/greeting/${lang}`,
	getChatBot: `${process.env.REACT_APP_API_URL}/chatbot`,
	getBet: (userId: number, page: number, limit?: number) =>
		`${process.env.REACT_APP_API_URL}/bet/${userId}?limit=${limit}&page=${page}`,
};

export {apiPath};
