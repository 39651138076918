import appService from 'store/appService';
import userServices from 'store/userServices';
import slowModeServices from 'store/slowModeServices';
import SettingsService from 'services/api/SettingsService';
import {useLocalObservable} from 'mobx-react-lite';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import chatServices from 'store/chatServices';

const useSettings = () => {
	const {accessToken} = useLocalObservable(() => userServices);
	const {language, setRules} = useLocalObservable(() => appService);
	const {togglleSlowMode} = useLocalObservable(() => slowModeServices);
	const {setGreetingMessage, setChatBot} = useLocalObservable(() => chatServices);

	const getSettingsWithServices = async () => {
		if (accessToken) {
			const response = await SettingsService.settings(accessToken);
			if (response.status === ResponseStatus.SUCCESS) {
				const {isSlowmode, slowmodeDelayMS} = response.data;
				if (typeof isSlowmode !== 'undefined' && typeof slowmodeDelayMS !== 'undefined') {
					togglleSlowMode({
						global: {
							enable: isSlowmode,
							time: slowmodeDelayMS || 1000,
						},
					});
				}
			}
		}
	};

	const getRulesWithServices = async () => {
		if (accessToken) {
			const response = await SettingsService.getRules(accessToken, language);
			if (response.status === ResponseStatus.SUCCESS) {
				setRules(response.data.rulesHTML);
			}
		}
	};

	const getGreetingMessageWithServices = async () => {
		if (accessToken) {
			const response = await SettingsService.getGreetingMessage(accessToken, language);
			if (response.status === ResponseStatus.SUCCESS) {
				setGreetingMessage(response.data);
			}
		}
	};

	const getChatBotWithServices = async () => {
		if (accessToken) {
			const response = await SettingsService.getChatBot(accessToken);
			if (response.status === ResponseStatus.SUCCESS) {
				setChatBot(response.data);
			}
		}
	};

	return {
		getSettingsWithServices,
		getRulesWithServices,
		getGreetingMessageWithServices,
		getChatBotWithServices,
	};
};

export default useSettings;
