import {GreetingMessage} from 'models/room';
import {action, makeObservable, observable} from 'mobx';

class ChatServices {
	@observable
	public textareaValue = '';

	@observable
	public visibleTextareaSendBtn = false;

	@observable
	public greetingMessage: GreetingMessage | null = null;

	@observable
	public chatBot: ChatBot | null = null;

	@action
	public setTextareaValue = (value: string) => {
		this.textareaValue = value;
	};

	@action
	public setVisibleTextareaSendBtn = (value: boolean) => {
		this.visibleTextareaSendBtn = value;
	};

	@action
	public setGreetingMessage = (data: GreetingMessage) => {
		this.greetingMessage = data;
	};

	@action
	public setChatBot = (data: ChatBot) => {
		this.chatBot = data;
	};

	constructor() {
		makeObservable(this);
	}
}

export default new ChatServices();
