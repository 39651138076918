/* eslint-disable max-lines */
import {START_NUMBER_OF_MESSAGES} from 'constants/constants';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {AgoraStatus} from 'models/enums/AgoraStatus.enum';
import UserRole from 'models/enums/UserRole.enum';
import {BanReason} from 'models/enums/BanModerReasons.enum';
import useBanUsers from 'hooks/useBanUsers';
import useChatActions from 'hooks/useChatActions';
import useStickers from 'hooks/useStickers';
import appService from 'store/appService';
import userServices from 'store/userServices';
import roomServices from 'store/roomServices';
import pollServices from 'store/pollServices';
import toastService from 'store/toastService';
import modalServices from 'store/modalServices';
import slowModeServices from 'store/slowModeServices';
import SocketIoServices from 'services/SocketIoServices';
import RoomService from 'services/api/RoomService';
import alertService from 'store/alertService';
import UserService from 'services/api/UserService';
import {FunctionComponent, useCallback, useEffect} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import classNames from 'classnames';
import {AlertBtnType} from 'models/enums/Alert.enum';
import {
	ProfileModal,
	SettingsModal,
	ReportModal,
	BlockedModal,
	LanguageModal,
	WaitingSpeakersModal,
	PollCreatedModal,
	PollEditModal,
	PollModal,
	RulesModal,
	OnboardModal,
	ChangeNameModal,
	UsersModal,
	AudioModal,
	SlowModeModal,
	SlowModeTimeoutModal,
	ChatBotRulesModal,
	ShareBetModal,
	RecordingModal,
} from 'components/modals';
import AgoraServices from 'services/api/AgoraService';
import useL10n from 'l10n/useL10n';
import RoomStatus from 'models/enums/RoomStatus.enum';
import useSocketsHandlers from 'hooks/useSocketsHandlers';
import {AppScreens} from 'models/enums/AppScreens.enum';
import {PollToggle} from 'components/poll/pollToggle';
import {PollVotesAnim} from 'components/poll/pollVotesAnim';
import {OnboardStage} from 'models/enums/OnboardStage.enum';
import {ToastIconName} from 'components/toasts/ToastIcons';
import {NetworkQuality} from 'models/enums/NetworkQuality';
import useSettings from 'hooks/useSettings';
import useAgora from 'hooks/useAgora';

import {Preloader} from 'components/preloader';

import {Chat} from './components/chat';
import {MainBottom} from './components/mainBottom';

import {EmotionsAnim} from './components/emotionsAnim';
import {Event} from './components/event';

const Main: FunctionComponent = function Main() {
	const {accessToken, userData, isUserExternalIdCorrect, isUserModer} = useLocalObservable(
		() => userServices
	);
	const {
		roomId,
		roomName,
		agoraCreds,
		myTalker,
		roomData,
		removeMessages,
		setTalkers,
		updateActiveVoice,
		setBannedTalkers,
		toggleRestApiDataLoaded,
	} = useLocalObservable(() => roomServices);
	const {
		appReadOnly,
		language,
		appVoice,
		deletedState,
		changeCurrentScreen,
		changeInternetConnectionStatus,
		setNetworkQuality,
		networkQuality,
		agoraStatus,
		changeAgoraStatus,
		setAgoraMicrophoneActive,
		setAgoraMicrophones,
	} = useLocalObservable(() => appService);
	const {
		hideAllModals,
		toggleOnboardModal,
		toggleRulesModal,
		toggleChangeNameModal,
		recordingModalVisible,
	} = useLocalObservable(() => modalServices);
	const {poll} = useLocalObservable(() => pollServices);
	const {addToast} = useLocalObservable(() => toastService);
	const {
		onUserJoinedHandler,
		onSuccessfullyJoined,
		onUserLeftHandler,
		onGetMessageHandler,
		onMessageNotSentHandler,
		onMessageEditedHandler,
		onMessagesDeletedHandler,
		onMessagesVisibleHandler,
		onMessageVisibleHandler,
		talkerBanSetHandler,
		onUserBanSetHandler,
		reciveChangeRole,
		onChangeModerHandler,
		onHandToggledHandler,
		reciveAgoraCredsHandler,
		onEmotionHandler,
		onRoomSpeakSetHandler,
		onRoomStatusSet,
		onMuteSetHandler,
		onUserUpdatedHandler,
		onMessagePin,
		onPollCreatedHandler,
		onPollUpdatedHandler,
		onPollDeletedHandler,
		onPollEndedHandler,
		onVoteCreatedHandler,
		onBlockSetHandler,
		onRemoveMessageHandler,
		onReactionCreatedHandler,
		onReactionDeletedHandler,
		onRoomSlowmodeSetHandler,
		onRoomSlowmodeDelaySetHandler,
		onSettingsUpdatedHandler,
	} = useSocketsHandlers();
	const {showAlert, hideAlert} = useLocalObservable(() => alertService);
	const {togglleSlowMode} = useLocalObservable(() => slowModeServices);
	const {getBanUsersWithServices, showAlertForBanReason} = useBanUsers();
	const {getMessagesWithServices, getPollWithServices} = useChatActions();
	const {getStickersWithServices} = useStickers();
	const {
		getSettingsWithServices,
		getRulesWithServices,
		getGreetingMessageWithServices,
		getChatBotWithServices,
	} = useSettings();
	const {getMicrophones, getPlaybackDevices} = useAgora();
	const translations = useL10n();

	const locale = ['ru-Ru', 'en-GB'].find(item => item.includes(language)) || 'en-GB';
	const myTalkerIsModer = !!myTalker?.isModer;

	const APP_OPTIONS = process.env.REACT_APP_AUTH;
	const APP_OPTIONS_PARSE = APP_OPTIONS && JSON.parse(String(APP_OPTIONS).replace(/\\/g, ''));

	const isVisiblePollToggle = myTalkerIsModer || (!myTalkerIsModer && poll);

	const banReasonTranslations = [
		translations.modals.ban.btns.insults,
		translations.modals.ban.btns.personal,
		translations.modals.ban.btns.flood,
		translations.modals.ban.btns.links,
		translations.modals.ban.btns.violence,
		translations.modals.ban.btns.fraud,
		translations.modals.ban.btns.nickname,
		translations.modals.ban.btns.politicial,
		translations.modals.ban.btns.repeated,
		translations.modals.ban.btns.auto,
		translations.modals.ban.btns.spam,
	];

	const mainPageClasses = classNames('main-page', {
		'main-page--show-record': recordingModalVisible,
	});

	const emitLeaveBySocket = () => {
		if (roomId) {
			SocketIoServices.emitLeave(roomId);
		}
	};

	const onVOD = (id: number, level: number) => {
		updateActiveVoice(id, level > 20);
	};

	const subscribeOnSocketMessages = async () => {
		getBanUsersWithServices();
		if (roomId) {
			SocketIoServices.emitJoin(roomId, roomName);
		}

		SocketIoServices.reciveAgoraCreds(reciveAgoraCredsHandler);
		SocketIoServices.onUserJoined(onUserJoinedHandler);
		SocketIoServices.onSuccessfullyJoined(onSuccessfullyJoined);
		SocketIoServices.onUserLeft(onUserLeftHandler);
		SocketIoServices.onGetMessage(onGetMessageHandler);
		SocketIoServices.onMessageNotSent(onMessageNotSentHandler);
		SocketIoServices.onMessageEdited(onMessageEditedHandler);
		SocketIoServices.onMessagesDeleted(onMessagesDeletedHandler);
		SocketIoServices.onMessagesVisibleSet(onMessagesVisibleHandler);
		SocketIoServices.onMessageVisibleSet(onMessageVisibleHandler);
		SocketIoServices.onTalkerSetBan(talkerBanSetHandler);
		SocketIoServices.onUserBanSet(onUserBanSetHandler);
		SocketIoServices.onChangeRole(reciveChangeRole);
		SocketIoServices.onChangeModer(onChangeModerHandler);
		SocketIoServices.onHandToggled(onHandToggledHandler);
		SocketIoServices.onEmotion(onEmotionHandler);
		SocketIoServices.onRoomSpeakSet(onRoomSpeakSetHandler);
		SocketIoServices.onRoomStatusSet(onRoomStatusSet);
		SocketIoServices.onMuteSet(onMuteSetHandler);
		SocketIoServices.onUserUpdated(onUserUpdatedHandler);
		SocketIoServices.onMessagePinned(onMessagePin);
		SocketIoServices.onPollCreated(onPollCreatedHandler);
		SocketIoServices.onPollUpdated(onPollUpdatedHandler);
		SocketIoServices.onPollDeleted(onPollDeletedHandler);
		SocketIoServices.onPollEnded(onPollEndedHandler);
		SocketIoServices.onVoteCreated(onVoteCreatedHandler);
		SocketIoServices.onBlockSet(onBlockSetHandler);
		SocketIoServices.onMessageDeleted(onRemoveMessageHandler);
		SocketIoServices.onReactionCreated(onReactionCreatedHandler);
		SocketIoServices.onReactionDeleted(onReactionDeletedHandler);
		SocketIoServices.onRoomSlowmodeSet(onRoomSlowmodeSetHandler);
		SocketIoServices.onRoomSlowmodeDelaySet(onRoomSlowmodeDelaySetHandler);
		SocketIoServices.onSettingsUpdated(onSettingsUpdatedHandler);

		if (roomId && accessToken) {
			const response = await RoomService.getTalkers(roomId, accessToken);
			if (response.status === ResponseStatus.SUCCESS) {
				setTalkers(response.data);
				toggleRestApiDataLoaded({talkersLoaded: true});
			} else {
				toggleRestApiDataLoaded({talkersLoaded: true});
			}

			const responseBannedTalkers = await RoomService.getBannedTalkers(roomId, accessToken);
			if (responseBannedTalkers.status === ResponseStatus.SUCCESS) {
				setBannedTalkers(responseBannedTalkers.data);
				toggleRestApiDataLoaded({bannedTalkersLoaded: true});
			} else {
				toggleRestApiDataLoaded({bannedTalkersLoaded: true});
			}
		}
	};

	const commonPackUnSubscribeSocketMessages = () => {
		SocketIoServices.offUserJoined(onUserJoinedHandler);
		SocketIoServices.offSuccessfullyJoined(onSuccessfullyJoined);
		SocketIoServices.offUserLeft(onUserLeftHandler);
		SocketIoServices.offGetMessage(onGetMessageHandler);
		SocketIoServices.offMessageNotSent(onMessageNotSentHandler);
		SocketIoServices.offMessageEdited(onMessageEditedHandler);
		SocketIoServices.offMessagesDeleted(onMessagesDeletedHandler);
		SocketIoServices.offMessagesVisibleSet(onMessagesVisibleHandler);
		SocketIoServices.offMessageVisibleSet(onMessageVisibleHandler);
		SocketIoServices.offTalkerSetBan(talkerBanSetHandler);
		SocketIoServices.offUserBanSet(onUserBanSetHandler);
		SocketIoServices.offHandToggled(onHandToggledHandler);
		SocketIoServices.offReciveAgoraCreds(reciveAgoraCredsHandler);
		SocketIoServices.offChangeRole(reciveChangeRole);
		SocketIoServices.offChangeModer(onChangeModerHandler);
		SocketIoServices.offEmotion(onEmotionHandler);
		SocketIoServices.offRoomSpeakSet(onRoomSpeakSetHandler);
		SocketIoServices.offRoomStatusSet(onRoomStatusSet);
		SocketIoServices.offMuteSet(onMuteSetHandler);
		SocketIoServices.offUserUpdated(onUserUpdatedHandler);
		SocketIoServices.offMessagePinned(onMessagePin);
		SocketIoServices.offPollCreated(onPollCreatedHandler);
		SocketIoServices.offPollUpdated(onPollUpdatedHandler);
		SocketIoServices.offPollDeleted(onPollDeletedHandler);
		SocketIoServices.offPollEnded(onPollEndedHandler);
		SocketIoServices.offVoteCreated(onVoteCreatedHandler);
		SocketIoServices.offBlockSet(onBlockSetHandler);
		SocketIoServices.offMessageDeleted(onRemoveMessageHandler);
		SocketIoServices.offReactionCreated(onReactionCreatedHandler);
		SocketIoServices.offReactionDeleted(onReactionDeletedHandler);
		SocketIoServices.offRoomSlowmodeSet(onRoomSlowmodeSetHandler);
		SocketIoServices.offRoomSlowmodeDelaySet(onRoomSlowmodeDelaySetHandler);
		SocketIoServices.offSettingsUpdated(onSettingsUpdatedHandler);
	};

	const unSubscribeOnSocketMessages = () => {
		emitLeaveBySocket();
		commonPackUnSubscribeSocketMessages();
		SocketIoServices.soketDisconnect();
		removeMessages();
	};

	const unSubscribeWhenDisconnectOnSocketMessages = () => {
		commonPackUnSubscribeSocketMessages();
	};

	const socketIoServicesCallInit = () => {
		if (userData?.token && roomId && SocketIoServices.socket === null && accessToken) {
			SocketIoServices.init(
				userData.token,
				subscribeOnSocketMessages,
				unSubscribeWhenDisconnectOnSocketMessages
			);
		}
	};

	const showInternetConnectionToast = () => {
		hideAllModals();
		emitLeaveBySocket();
		commonPackUnSubscribeSocketMessages();
		SocketIoServices.soketDisconnect();
		addToast({
			iconName: ToastIconName.noInet,
			liveTime: 3000,
			message: translations.toasts.noInet,
			cancellable: false,
			messageColor: 'rgba(255, 45, 85, 1)',
			deathCallback: () => {
				changeInternetConnectionStatus(false);
			},
		});
	};

	const hideInternetConnectionToast = () => {
		changeInternetConnectionStatus(true);
		socketIoServicesCallInit();
	};

	const networkQualityTest = useCallback(
		(quality: {uplinkNetworkQuality: number; downlinkNetworkQuality: number}, rtcStat: any) => {
			if (
				quality.uplinkNetworkQuality <= 3 &&
				quality.downlinkNetworkQuality <= 3 &&
				rtcStat.RTT <= 900
			) {
				setNetworkQuality(NetworkQuality.NORMAL);

				return;
			}

			quality.uplinkNetworkQuality > 3 ||
				quality.downlinkNetworkQuality > 3 ||
				(rtcStat.RTT > 920 && setNetworkQuality(NetworkQuality.POOR));
		},
		[networkQuality]
	);

	useEffect(() => {
		changeCurrentScreen(AppScreens.CHAT);
		window.addEventListener('online', hideInternetConnectionToast);
		window.addEventListener('offline', showInternetConnectionToast);
		return () => {
			unSubscribeOnSocketMessages();
			window.removeEventListener('online', hideInternetConnectionToast);
			window.removeEventListener('offline', showInternetConnectionToast);
		};
	}, []);

	useEffect(() => {
		if (appVoice && agoraCreds.appId && agoraStatus === AgoraStatus.DESTROYED) {
			if (myTalker) {
				AgoraServices.init(
					agoraCreds,
					myTalker.role === UserRole.SPEAKER || myTalkerIsModer,
					myTalker.isMuted,
					onVOD,
					networkQualityTest,
					setAgoraMicrophoneActive,
					setAgoraMicrophones,
					changeAgoraStatus
				);
			}
		}

		if (agoraStatus === AgoraStatus.INITED) {
			AgoraServices.destroy(changeAgoraStatus);
		}
	}, [agoraCreds, appVoice]);

	useEffect(() => {
		if (
			(myTalker?.isModer || myTalker?.role === UserRole.SPEAKER) &&
			appVoice &&
			agoraStatus === AgoraStatus.INITED
		) {
			getMicrophones();
		}
	}, [myTalker?.isModer, myTalker?.role, appVoice, agoraStatus]);

	useEffect(() => {
		if (appVoice && agoraStatus === AgoraStatus.INITED) {
			getPlaybackDevices();
		}
	}, [appVoice, agoraStatus]);

	useEffect(() => {
		if (roomData) {
			const {isSlowmode, slowmodeDelayMS} = roomData;
			togglleSlowMode({
				local: {
					enable: isSlowmode,
					time: slowmodeDelayMS || 1000,
				},
			});
		}
	}, [roomData]);

	useEffect(() => {
		if (roomId && accessToken) {
			getMessagesWithServices(START_NUMBER_OF_MESSAGES);
			getStickersWithServices();
			getPollWithServices();
			getSettingsWithServices();
			getRulesWithServices();
		}
	}, [roomId, accessToken]);

	useEffect(() => {
		if (accessToken) {
			getRulesWithServices();
			getGreetingMessageWithServices();
			getChatBotWithServices();
		}
	}, [accessToken, language]);

	useEffect(() => {
		socketIoServicesCallInit();
	}, [userData, roomId, accessToken, deletedState]);

	useEffect(() => {
		if (userData && !userData.isOnboard && userData.onboardStage !== OnboardStage.CHAT) {
			toggleOnboardModal(true);
		}

		if (userData && userData.isBanned && userData.ban) {
			if (userData.ban.reason === BanReason.NICKNAME) {
				if (APP_OPTIONS_PARSE.nickname) {
					showAlertForBanReason(BanReason.NICKNAME);
					return;
				}
				toggleChangeNameModal(true);
				return;
			}

			const bannedUntill =
				userData.ban.expires &&
				new Date(userData.ban.expires).toLocaleDateString(locale, {
					year: 'numeric',
					month: 'long',
					day: 'numeric',
					hour: 'numeric',
					minute: 'numeric',
				});

			changeCurrentScreen(AppScreens.APPBAN);

			showAlert({
				zIndex: 500,
				title: `${translations.alerts.banInApp.title} ${
					userData.ban &&
					userData.ban.reason !== null &&
					typeof banReasonTranslations[userData.ban.reason] !== 'undefined'
						? `${translations.alerts.banInApp.for} ${banReasonTranslations[
								userData.ban.reason
						  ].toLowerCase()}`
						: ''
				}`,
				subtitle:
					bannedUntill === null
						? translations.alerts.banInApp.permanent
						: `${translations.alerts.banInApp.subtitle} ${bannedUntill}`,
				buttons: [
					{
						text: translations.alerts.btns.rules,
						type: AlertBtnType.PRIMARY,
						onPress: () => {
							toggleRulesModal(true);
						},
					},
					{
						text: translations.alerts.btns.contactus,
						type: AlertBtnType.NORMAL,
						onPress: () => {
							window.open('mailto:wink@social-solutions.ru', '_blank')?.focus();
						},
					},
				],
				closeOnBackdropPress: false,
			});
			return;
		}

		changeCurrentScreen(AppScreens.CHAT);
		hideAlert();
		toggleChangeNameModal(false);
	}, [userData]);

	useEffect(() => {
		myTalkerIsModer
			? document.querySelector('body')?.classList.add('moderator')
			: document.querySelector('body')?.classList.remove('moderator');

		return () => {
			document.querySelector('body')?.classList.remove('moderator');
		};
	}, [myTalkerIsModer]);

	useEffect(() => {
		if (APP_OPTIONS_PARSE.moderator) {
			(async () => {
				if (roomId && myTalker?.id && isUserModer !== null && myTalker.isModer !== isUserModer) {
					await UserService.setSelfModer(roomId, myTalker.id, isUserModer);
				}
			})();
		}
	}, [myTalker?.id]);

	return (
		<div className={mainPageClasses}>
			<Preloader visible={roomData === null} />

			{(roomData?.status === RoomStatus.SOON || roomData?.status === RoomStatus.DISABLED) && (
				<Event />
			)}

			<Chat />

			{!appReadOnly && isUserExternalIdCorrect && <MainBottom />}

			<EmotionsAnim />
			<PollVotesAnim />

			{isVisiblePollToggle && <PollToggle />}

			<RecordingModal />
			<SettingsModal />
			<ProfileModal startScreen='main' />
			<BlockedModal />
			<LanguageModal />
			<SlowModeModal />
			<SlowModeTimeoutModal />
			<WaitingSpeakersModal />
			<RulesModal />
			<ReportModal />
			<PollCreatedModal />
			<PollEditModal />
			<PollModal />
			<OnboardModal />
			<ChangeNameModal />
			<UsersModal />
			<AudioModal />
			<ChatBotRulesModal />
			<ShareBetModal />
		</div>
	);
};

export default observer(Main);
