/* eslint-disable max-lines */
import RoomStatus from 'models/enums/RoomStatus.enum';
import MessageType from 'models/enums/MessageType.enum';
import {Message, MessageAdvertisement, Reaction, Sticker} from 'models/room';
import {PollMessageData} from 'models/poll';
import {Bet} from 'models/bet';
import appService from 'store/appService';
import userServices from 'store/userServices';
import roomServices from 'store/roomServices';
import chatServices from 'store/chatServices';
import stickersEmojiServices from 'store/stickersEmojiServices';
import useLongPress from 'hooks/useLongPress';
import useMessage from 'hooks/useMessage';
import {getLocaleTimeMessage, getLocaleDateMessage} from 'utils/message';
import useL10n from 'l10n/useL10n';
import {FunctionComponent, useCallback, useEffect, useRef, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {isMobile} from 'react-device-detect';
import Linkify from 'linkify-react';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import classNames from 'classnames/bind';
import {v4 as uuidv4} from 'uuid';
import isEmptyObj from 'lodash/isEmpty';

import {Avatar} from 'components/avatar';
import {PollResults} from 'components/poll/pollResults';
import BetItem from 'components/bet/BetItem';

import AnonimAva from 'assets/images/anonim-ava.jpg';

import {ChatMessageSubmenu} from './chatMessageSubmenu';
import {LinkPreview} from '../linkPreview';
import {Highlight} from '../highlight';
import {Reactions} from '../reactions';

interface IChatMessageProps {
	message: Message;
	mentionMessage: Message | null | undefined;
	visibleAvatar: boolean;
	visibleName: boolean;
	visibleDate: boolean;
	visibleMessageSubmenu: boolean;
	isLastMessage: boolean;
	highlightMessage?: MessageAdvertisement;
	pollResultsMessage?: PollMessageData;
	stickerMessage?: Sticker;
	betMessage: Bet;
	reactions?: Reaction[];
}

const MESSAGE_TEXT_BASE_LINE_HEIGHT = 21; // px

const LONG_PRESS_DEFAULT_OPTIONS = {
	shouldPreventDefault: false,
	delay: 500,
};

const LINKIFY_OPTIONS = {
	target: '_blank',
};

const groupingByKey = (arrray: any, key = 'emotion') => {
	const resultObject = arrray.reduce((acc: any, item: any) => {
		// eslint-disable-next-line no-param-reassign
		(acc[item[key]] = acc[item[key]] || []).push(item);
		return acc;
	}, {});
	if (!isEmptyObj(resultObject)) {
		return Object.getOwnPropertyNames(resultObject).map(k => ({
			id: uuidv4(),
			name: resultObject[k][0].emotion,
			messageId: resultObject[k][0].messageId,
			items: resultObject[k],
		}));
	}
	return [];
};

const ChatMessage: FunctionComponent<IChatMessageProps> = function ChatMessage({
	message,
	mentionMessage,
	highlightMessage,
	pollResultsMessage,
	stickerMessage,
	betMessage,
	visibleAvatar,
	visibleName,
	visibleDate,
	visibleMessageSubmenu,
	isLastMessage,
	reactions,
}) {
	const messageRef = useRef<HTMLDivElement>(null);
	const {chatUsersScreen, chatScreen} = useL10n();

	const {id, type, talker, text, isVisible, hasPreview, createdAt, updatedAt} = message;
	const {appReadOnly, language} = useLocalObservable(() => appService);
	const {userData, isUserExternalIdCorrect} = useLocalObservable(() => userServices);
	const {roomData, myTalker, submenuMessage, setSubmenuMessage, submenuButtons} =
		useLocalObservable(() => roomServices);
	const {chatBot} = useLocalObservable(() => chatServices);
	const {togglleVisibleStickersEmoji} = useLocalObservable(() => stickersEmojiServices);
	const [textMultiline, setTextMultiline] = useState(false);
	const {getMessageText, checkingMessageFromChatBot, getBetTitle} = useMessage();

	const myTalkerIsBanned = !!myTalker?.isBanned;
	const messageTalkerIsModer = talker?.isModer;
	const commonCheck =
		!appReadOnly &&
		isUserExternalIdCorrect &&
		userData &&
		userData.isOnboard &&
		talker.user &&
		!myTalkerIsBanned;
	const myMessage = userData && talker.user && userData.id === talker.user.id;

	const checkVisibleMessageSubmenu =
		visibleMessageSubmenu &&
		commonCheck &&
		(roomData?.status === RoomStatus.LIVE || roomData?.status === RoomStatus.ENDED);

	const findLinksInText = () => {
		const arrayFoundLinks = text?.match(/\bhttp(s?):\/\/\S+/gi);
		return arrayFoundLinks?.length && arrayFoundLinks.length > 0 ? arrayFoundLinks[0] : null;
	};

	const longPressEvent = useLongPress(
		() => {
			if (commonCheck && isMobile) {
				setSubmenuMessage({
					messageId: id,
				});
				togglleVisibleStickersEmoji(false);
			}
		},
		undefined,
		LONG_PRESS_DEFAULT_OPTIONS
	);

	const chatMessageClasses = classNames('chat__message-container', {
		'chat__message-container--no-avatar': !visibleAvatar,
		'chat__message-container--right': myMessage,
		'chat__message-container--is-hidden': !myMessage && !isVisible,
		'chat__message-container--mention': mentionMessage,
		'chat__message-container--highlight': highlightMessage,
		'chat__message-container--poll-results': type === MessageType.POLLRESULTS,
		'chat__message-container--sticker': type === MessageType.STICKER,
		'chat__message-container--submenu': visibleMessageSubmenu,
		'chat__message-container--reactions': !!reactions?.length,
		'chat__message-container--bet': type === MessageType.BET,
	});

	const chatMessageTextClasses = classNames('chat__message-text', {
		'chat__message-text--multiline':
			updatedAt || textMultiline || (findLinksInText() && hasPreview),
		'chat__message-text--link-preview': hasPreview,
	});

	const renderLinkInText = () => {
		const findLink = findLinksInText();
		if (findLink) {
			return <LinkPreview url={findLink} />;
		}
		return null;
	};

	const renderHeaderMessage = useCallback(() => {
		if (visibleName && !myMessage && type !== MessageType.STICKER) {
			if (talker.user && chatBot && talker.user.name === chatBot.name) {
				return (
					<div className='chat__message-header'>
						<div className={`chat__message-author color-${talker.user?.color || 0}`}>Watchers</div>
						<div className='chat__message-author-admin'>bot</div>
					</div>
				);
			}
			return (
				<div className='chat__message-header'>
					<div className='chat__message-author'>
						{talker.user?.name ? talker.user.name : 'User deleted'}
					</div>
					{talker.user?.ranking && (
						<div className='chat__message-author-status chat__message-author-status--top'>
							{chatScreen.status.top}-{talker.user.ranking.badge}
						</div>
					)}

					{messageTalkerIsModer && <div className='chat__message-author-admin'>admin</div>}
				</div>
			);
		}
		return null;
	}, [visibleName, myMessage, talker]);

	const renderDateMessage = useCallback(
		() => (
			<div className='chat__message-date'>
				<div className='chat__message-date-item'>
					{getLocaleDateMessage(createdAt, language, chatUsersScreen.days)}
				</div>
			</div>
		),
		[language]
	);

	const renderReplayMessage = useCallback(() => {
		if (mentionMessage && mentionMessage.isVisible && !mentionMessage.deletedAt) {
			return (
				<div className='chat__message-replay' data-replay-id={mentionMessage.id}>
					{mentionMessage.sticker?.pic && (
						<div
							className='chat__message-replay-img'
							style={{backgroundImage: `url('${mentionMessage.sticker.pic}')`}}
						/>
					)}
					<div className='chat__message-replay-content'>
						<div className='chat__message-author'>{mentionMessage.talker.user?.name}</div>
						{getMessageText(mentionMessage) && (
							<div className='chat__message-text'>{getMessageText(mentionMessage)}</div>
						)}
						{mentionMessage.sticker && <div className='chat__message-text'>стикер</div>}
						{mentionMessage.bet && (
							<div className='chat__message-text'>{getBetTitle(mentionMessage)}</div>
						)}
					</div>
				</div>
			);
		}
		return null;
	}, [mentionMessage]);

	const renderTimeMessage = useCallback(
		() => (
			<div className='chat__message-time'>
				{updatedAt
					? `${chatUsersScreen.edited} ${getLocaleTimeMessage(updatedAt)}`
					: getLocaleTimeMessage(createdAt)}
			</div>
		),
		[createdAt, updatedAt]
	);

	useEffect(() => {
		if (messageRef.current && messageRef.current.clientHeight > MESSAGE_TEXT_BASE_LINE_HEIGHT) {
			setTextMultiline(true);
		}
	}, []);

	useEffect(() => {
		submenuMessage && submenuButtons.length
			? document.querySelector('.chat')?.classList.add('chat--overflow')
			: document.querySelector('.chat')?.classList.remove('chat--overflow');
	}, [submenuMessage, submenuButtons]);

	return (
		<div className='chat__message' data-id={message.id}>
			{visibleDate && renderDateMessage()}
			<div className={chatMessageClasses}>
				{!myMessage && (
					<div className='chat__message-left'>
						{visibleAvatar && !highlightMessage && (
							<div className='chat__message-avatar'>
								<Avatar
									src={talker.user?.pic ? talker.user.pic : AnonimAva}
									alt={talker.user?.name ? talker.user.name : 'User deleted'}
									className='chat__message-avatar-img'
								/>
							</div>
						)}
					</div>
				)}

				<div className='chat__message-right'>
					{highlightMessage && !pollResultsMessage && !stickerMessage && !betMessage && (
						<div className='chat__message-body' {...longPressEvent}>
							<Highlight advertisement={highlightMessage} />
						</div>
					)}

					{!highlightMessage && pollResultsMessage && !stickerMessage && !betMessage && (
						<>
							{renderHeaderMessage()}
							<div className='chat__message-body' {...longPressEvent}>
								<PollResults pollResults={pollResultsMessage} />
							</div>
						</>
					)}

					{!highlightMessage && !pollResultsMessage && stickerMessage && !betMessage && (
						<>
							{renderHeaderMessage()}
							<div className='chat__message-body' {...longPressEvent}>
								{renderReplayMessage()}
								<div className='chat__message-text' ref={messageRef}>
									<div className='chat__message-sticker'>
										<LazyLoadImage src={stickerMessage.pic} className='chat__message-sticker-img' />
									</div>
									{reactions && reactions.length > 0 && (
										<Reactions reactions={groupingByKey(reactions)} />
									)}
								</div>
							</div>
						</>
					)}

					{!highlightMessage && !pollResultsMessage && !stickerMessage && !betMessage && (
						<>
							{renderHeaderMessage()}
							<div className='chat__message-body' {...longPressEvent}>
								{renderReplayMessage()}
								<div className={chatMessageTextClasses} ref={messageRef}>
									<div className='chat__message-text-item'>
										{messageTalkerIsModer ? (
											<Linkify options={LINKIFY_OPTIONS}>
												{checkingMessageFromChatBot(text)}
											</Linkify>
										) : (
											checkingMessageFromChatBot(text)
										)}
									</div>
									{messageTalkerIsModer && hasPreview && renderLinkInText()}
									{reactions && reactions.length > 0 && (
										<Reactions reactions={groupingByKey(reactions)} />
									)}
									{renderTimeMessage()}
								</div>
							</div>
						</>
					)}

					{!highlightMessage && !pollResultsMessage && !stickerMessage && betMessage && (
						<>
							{renderHeaderMessage()}
							<div className='chat__message-body' {...longPressEvent}>
								<BetItem bet={betMessage} myMessage={myMessage} />
								{reactions && reactions.length > 0 && (
									<Reactions reactions={groupingByKey(reactions)} />
								)}
							</div>
						</>
					)}

					{checkVisibleMessageSubmenu && (
						<ChatMessageSubmenu message={message} reactions={reactions} />
					)}
				</div>
			</div>
			{isLastMessage && (
				<span className='chat__message-not-viewed'>{chatUsersScreen.unreadMessages}</span>
			)}
		</div>
	);
};

export default observer(ChatMessage);
