import {MODAL_STYLE} from 'constants/constants';
import {PollType} from 'models/enums/Poll.enum';
import modalServices from 'store/modalServices';
import pollServices from 'store/pollServices';
import usePollActions from 'hooks/usePollActions';
import usePollAnalytics from 'hooks/usePollAnalytics';
import useL10n from 'l10n/useL10n';
import {FunctionComponent} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Modal from 'react-modal';
import {PollOptions} from 'components/poll/pollOptions';
import './pollModal.scss';
import Button from 'components/hoc/Button';

const PollModal: FunctionComponent = function PollModal() {
	const {modals} = useL10n();
	const {pollModalVisible, togglePollModalVisible} = useLocalObservable(() => modalServices);
	const {poll, pollAlreadyVoted, pollOptionsChosen, toggllePollToggleButtonAccent} =
		useLocalObservable(() => pollServices);
	const {onSendPollVote, calcPollVotesLength} = usePollActions();
	const {sendPollAnalytics} = usePollAnalytics();

	if (!poll) {
		return null;
	}

	const {text, isMultiple, type} = poll;

	const getSupTitle = () => {
		switch (type) {
			case PollType.QUIZ:
				return poll.isMultiple
					? modals.poll.supTitles.typeQuizMultiple
					: modals.poll.supTitles.typeQuiz;
			case PollType.POLL:
				return poll.isMultiple
					? modals.poll.supTitles.typePollMultiple
					: modals.poll.supTitles.typePoll;
			default:
				return modals.poll.supTitles.default;
		}
	};

	const onPollModalCloseHandler = () => {
		togglePollModalVisible(false);
		toggllePollToggleButtonAccent(false);
		sendPollAnalytics('pq_closed');
	};

	const onPollModalVoteHandler = () => {
		onSendPollVote();
	};

	return (
		<Modal
			className='poll-modal'
			isOpen={pollModalVisible}
			ariaHideApp={false}
			style={{
				overlay: MODAL_STYLE.overlay,
			}}>
			<div className='poll-modal__body'>
				<div className='poll-modal__axis-y'>
					<div className='poll-modal__container'>
						<Button
							className='poll-modal__close'
							aria-label='modal-close'
							onClick={onPollModalCloseHandler}
						/>
						<div className='poll-modal__top'>
							<div className='poll-modal__suptitle'>{getSupTitle()}</div>
							<div className='poll-modal__title'>{text}</div>
						</div>
						<div className='poll-modal__content'>
							<PollOptions viewVertical={poll.options.length < 4} />
						</div>
						<div className='poll-modal__bottom'>
							{!pollAlreadyVoted && isMultiple && (
								<Button
									className='btn btn--poll poll-modal__button'
									onClick={onPollModalVoteHandler}
									disabled={!pollOptionsChosen.length}>
									{type === PollType.QUIZ && isMultiple
										? modals.poll.btns.reply
										: modals.poll.btns.vote}
								</Button>
							)}
							{pollAlreadyVoted && calcPollVotesLength(poll) && (
								<div className='poll-modal__results'>{calcPollVotesLength(poll)}</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default observer(PollModal);
