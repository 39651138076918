import axios from 'axios';
import {apiPath} from 'constants/api';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import checkResponse from 'utils/checkResponse';
import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';
import Log from 'utils/log';

export default class BetService {
	static getBet = async (token: UUID, userId: number, page: number, limit: number) => {
		try {
			const {data, status} = await axios.get(apiPath.getBet(userId, page, limit), {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Get settings error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}
