import {PollType} from 'models/enums/Poll.enum';
import {PollOption} from 'models/poll';
import pollServices from 'store/pollServices';
import {FunctionComponent, useCallback} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import classNames from 'classnames';
import PollOptionItem from './PollOptionItem';
import './pollOptions.scss';

interface IPollOptionsPtops {
	viewType?: string;
	viewVertical?: boolean;
}

const PollOptions: FunctionComponent<IPollOptionsPtops> = function PollOptions({
	viewType,
	viewVertical,
}) {
	const isViewTypeEdit = !!(viewType && viewType === 'edit');

	const {poll, pollAlreadyVoted, pollOptionsBlocked, pollOptionsChosen} = useLocalObservable(
		() => pollServices
	);

	const isViewVertical = !!(poll && poll.options.find(item => item.pic) && viewVertical);

	const pollOptionsClasses = classNames('poll-options', {
		'poll-options--blocked': pollOptionsBlocked,
		'poll-options--view-edit': isViewTypeEdit,
		'poll-options--view-vertical': isViewVertical,
		'poll-options--already-voted': pollAlreadyVoted,
	});

	const checkPollOptionSelected = (option: PollOption) => {
		if (pollOptionsChosen?.length) {
			return (
				!isViewTypeEdit && !!pollOptionsChosen.find(optionChosen => optionChosen.id === option.id)
			);
		}
		return false;
	};

	const renderPollOption = useCallback(
		(option: PollOption) => (
			<PollOptionItem
				key={option.id}
				option={option}
				isInteractive={!pollAlreadyVoted && !isViewTypeEdit}
				isSelected={checkPollOptionSelected(option)}
				isViewTypeEdit={isViewTypeEdit}
				voteVisible={(pollAlreadyVoted || isViewTypeEdit) && poll?.type !== PollType.QUIZ}
			/>
		),
		[pollAlreadyVoted, pollOptionsChosen]
	);

	if (!poll || (poll && !poll.options?.length)) {
		return null;
	}

	return (
		<div className={pollOptionsClasses} data-items={poll.options.length}>
			{poll.options.map(renderPollOption)}
		</div>
	);
};

export default observer(PollOptions);
