import {Message} from 'models/room';
import roomServices from 'store/roomServices';
import useL10n from 'l10n/useL10n';
import useSubmenuActions from 'hooks/useSubmenuActions';
import {FunctionComponent} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Button from 'components/hoc/Button';
import {ReactComponent as IcoMute} from 'assets/svg/ico-mute.svg';

interface IButtonMuteProps {
	message: Message;
}

const ButtonMute: FunctionComponent<IButtonMuteProps> = function ButtonMute({message}) {
	const {chatScreen} = useL10n();
	const {roomId} = useLocalObservable(() => roomServices);
	const {muteUser} = useSubmenuActions();

	const onMuteHandler = () => {
		if (roomId) {
			muteUser(message, roomId);
		}
	};

	return (
		<Button className='chat__submenu-btn' onClick={onMuteHandler}>
			{chatScreen.chatSubmenu.muteSpeaker}
			<IcoMute className='chat__submenu-icon' />
		</Button>
	);
};

export default observer(ButtonMute);
