enum AdvertisementTemplateType {
	'CUSTOM_1',
	'CUSTOM_2',
	'CUSTOM_3',
	'TEMPLATE_1',
	'TEMPLATE_2',
	'TEMPLATE_3',
	'TEMPLATE_4',
	'TEMPLATE_5',
	'TEMPLATE_6',
	'TEMPLATE_7',
	'TEMPLATE_8',
	'CUSTOM_4',
}

export default AdvertisementTemplateType;
