import {FunctionComponent} from 'react';
import {observer} from 'mobx-react-lite';
import './pollOptionVoting.scss';

interface IPollOptionVotingProps {
	voteNumber: number;
}

const PollOptionVoting: FunctionComponent<IPollOptionVotingProps> = function PollOptionVoting({
	voteNumber,
}) {
	return (
		<div className='poll-option-voting'>
			<p className='poll-option-voting__number'>{voteNumber}%</p>
			<div className='poll-option-voting__indicate'>
				<span style={{width: `${voteNumber}%`}} />
			</div>
		</div>
	);
};

export default observer(PollOptionVoting);
