import {MODAL_STYLE} from 'constants/constants';
import modalServices from 'store/modalServices';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Modal from 'react-modal';
import {WaitingSpeakers} from 'components/waitingSpeakers';
import useL10n from 'l10n/useL10n';
import {AppScreens} from 'models/enums/AppScreens.enum';
import {useEffect} from 'react';
import appService from 'store/appService';
import Button from 'components/hoc/Button';

const WaitingSpeakersModal: React.FC = function WaitingSpeakersModal() {
	const translations = useL10n();
	const {waitingSpeakersModalVisible, toggleWaitingSpeakersModal} = useLocalObservable(
		() => modalServices
	);
	const {changeCurrentScreen} = useLocalObservable(() => appService);

	useEffect(() => {
		waitingSpeakersModalVisible
			? changeCurrentScreen(AppScreens.WAITINIGSPEAKERS)
			: changeCurrentScreen(AppScreens.CHAT);
	}, [waitingSpeakersModalVisible]);

	return (
		<Modal
			className='modal'
			isOpen={waitingSpeakersModalVisible}
			ariaHideApp={false}
			style={{
				overlay: MODAL_STYLE.overlay,
			}}>
			<div className='modal__head'>
				<div className='modal__title'>{translations.modals.waitingSpeakers.handsUp}</div>
				<Button className='modal__back' onClick={() => toggleWaitingSpeakersModal(false)} />
			</div>
			<div className='modal__body'>
				<div className='modal__axis-y'>
					<WaitingSpeakers />
				</div>
			</div>
		</Modal>
	);
};

export default observer(WaitingSpeakersModal);
