import {FunctionComponent} from 'react';
import actionMessageServices from 'store/actionMessageServices';
import linkPreviewServices from 'store/linkPreviewServices';
import emotionServices from 'store/emotionServices';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {CSSTransition} from 'react-transition-group';
import classNames from 'classnames';
import {Suggest} from '../suggest';
import {ActionMessage} from '../actionMessage';
import {LinkPreviewWriting} from '../linkPreview/linkPreviewWriting';
import {ControlPanel} from '../controlPanel';
import {Emotions} from '../emotions';
import {StickerEmojiViewer} from '../stickersEmojiPicker';
import './mainBottom.scss';

const TRANSITION_DURATION_EMOTIONS = 250;

const MainBottom: FunctionComponent = function MainBottom() {
	const {isVisibleLinkPreviewWriting} = useLocalObservable(() => linkPreviewServices);
	const {isVisibleActionMessage} = useLocalObservable(() => actionMessageServices);
	const {emotionsVisible} = useLocalObservable(() => emotionServices);

	const mainPageBottomClass = classNames('main-bottom', {
		'main-bottom--pt': isVisibleActionMessage || isVisibleLinkPreviewWriting,
	});

	return (
		<div className={mainPageBottomClass}>
			<CSSTransition
				in={emotionsVisible}
				timeout={TRANSITION_DURATION_EMOTIONS}
				unmountOnExit
				classNames='fade-up'>
				<Emotions />
			</CSSTransition>
			<LinkPreviewWriting />
			<Suggest />
			<ActionMessage />
			<ControlPanel />
			<StickerEmojiViewer />
		</div>
	);
};

export default observer(MainBottom);
