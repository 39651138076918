import {Sticker} from 'models/room';
import {action, makeObservable, observable} from 'mobx';

class StickersEmojiServices {
	@observable
	public visibleStickersEmoji = false;

	@observable
	public stickers: Sticker[] = [];

	@action
	public togglleVisibleStickersEmoji = (value: boolean) => {
		this.visibleStickersEmoji = value;
	};

	@action
	public setStickers = (data: Sticker[]) => {
		this.stickers = data;
	};

	@action
	public removeSticker = (stickerId: number) => {
		if (this.stickers.find(sticker => sticker.id === stickerId)) {
			this.stickers = this.stickers.filter(sticker => sticker.id !== stickerId);
		}
	};

	constructor() {
		makeObservable(this);
	}
}

export default new StickersEmojiServices();
