import useLinkPreview from 'hooks/useLinkPreview';
import {FunctionComponent} from 'react';
import ImgLinkPlaceholder from 'assets/images/img-link-placeholder.jpeg';
import {LinkPreviewSceleton} from './linkPreviewSceleton';
import './linkPreview.scss';

interface ILinkPreviewProps {
	url: string;
	showLoader?: boolean;
	fallbackImageSrc?: string;
	showPlaceholderIfNoImage?: boolean;
}

const LinkPreview: FunctionComponent<ILinkPreviewProps> = function LinkPreview({
	url,
	showLoader = true,
	fallbackImageSrc = ImgLinkPlaceholder,
	showPlaceholderIfNoImage = true,
}) {
	const {loading, metadata} = useLinkPreview(url);

	if (loading && showLoader) {
		return <LinkPreviewSceleton />;
	}

	if (!metadata || !metadata.hostname) {
		return null;
	}

	const {hostname, description, image} = metadata;

	const getSrcImage = () => {
		if (image) {
			if (image.includes('http')) {
				return image;
			}
			return `${url.replace(/\/$/, '')}${image}`;
		}
		return null;
	};

	const onClick = () => window.open(url, '_blank');

	return (
		<div tabIndex={0} role='button' onClick={onClick} onKeyDown={onClick} className='link-preview'>
			<div className='link-preview__content'>
				<div className='link-preview__sitename'>{hostname}</div>
				{description && <span className='link-preview__description'>{description}</span>}
			</div>
			{getSrcImage() && showPlaceholderIfNoImage && (
				<div
					className='link-preview__image'
					style={{
						backgroundImage: `url(${getSrcImage()}), url(${fallbackImageSrc})`,
					}}
				/>
			)}
		</div>
	);
};

export default LinkPreview;
