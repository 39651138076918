export const times = [
	{
		id: 0,
		title: {
			ru: {
				default: '1 секунда',
				prefix: 'ую 1 секунду',
			},
			en: {
				default: '1 second',
			},
		},
		time: 1000,
	},
	{
		id: 1,
		title: {
			ru: {
				default: '2 секунды',
			},
			en: {
				default: '2 seconds',
			},
		},
		time: 2000,
	},
	{
		id: 2,
		title: {
			ru: {
				default: '3 секунды',
			},
			en: {
				default: '3 seconds',
			},
		},
		time: 3000,
	},
	{
		id: 3,
		title: {
			ru: {
				default: '5 секунд',
			},
			en: {
				default: '5 second',
			},
		},
		time: 5000,
	},
	{
		id: 4,
		title: {
			ru: {
				default: '10 секунд',
			},
			en: {
				default: '10 Seconds',
			},
		},
		time: 10000,
	},
	{
		id: 5,
		title: {
			ru: {
				default: '15 секунд',
			},
			en: {
				default: '15 seconds',
			},
		},
		time: 15000,
	},
	{
		id: 6,
		title: {
			ru: {
				default: '20 секунд',
			},
			en: {
				default: '20 seconds',
			},
		},
		time: 20000,
	},
	{
		id: 7,
		title: {
			ru: {
				default: '30 секунд',
			},
			en: {
				default: '30 seconds',
			},
		},
		time: 30000,
	},
	{
		id: 8,
		title: {
			ru: {
				default: '1 минута',
				prefix: 'ую 1 минуту',
			},
			en: {
				default: '1 minute',
			},
		},
		time: 60000,
	},
	{
		id: 9,
		title: {
			ru: {
				default: '2 минуты',
			},
			en: {
				default: '2 minutes',
			},
		},
		time: 120000,
	},
	{
		id: 10,
		title: {
			ru: {
				default: '3 минуты',
			},
			en: {
				default: '3 minutes',
			},
		},
		time: 180000,
	},
	{
		id: 11,
		title: {
			ru: {
				default: '5 минут',
			},
			en: {
				default: '5 minutes',
			},
		},
		time: 300000,
	},
];
