import {ResponseStatusCode} from 'models/enums/Network.enum';

type Obj = Record<string, unknown>;

const checkResponse = (data: Obj | unknown, status: number) => {
	if (!data) {
		throw Error('Empty data');
	}

	if (status === ResponseStatusCode.Unauthorized) {
		throw Error(`Server returned status code ${status}`);
	}

	if (status === ResponseStatusCode.Forbidden) {
		throw Error(`Server returned status code ${status}`);
	}

	if (status > ResponseStatusCode.BadRequest) {
		throw Error(`Server returned status code ${status}`);
	}
};

export default checkResponse;
