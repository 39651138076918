import ResponseStatus from 'models/enums/ResponseStatus.enum';
import AntimatService from 'services/api/AntimatService';

export default () => {
	const containsMat = async (message: string, lang: string, callBackForError?: () => void) => {
		const response = await AntimatService.containsMat(message, lang, callBackForError);
		if (response.status === ResponseStatus.SUCCESS) {
			return {status: ResponseStatus.SUCCESS, ...response.data};
		}
		return {status: ResponseStatus.ERROR, message, isVailable: false};
	};

	return {containsMat};
};
