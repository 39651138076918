import useOnClickOutside from 'hooks/useOnClickOutside';
import {FunctionComponent, useCallback, useState, useRef} from 'react';
import classNames from 'classnames/bind';
import {v4 as uuidv4} from 'uuid';
import Button from 'components/hoc/Button';
import './select.scss';

interface ISelectProps {
	options: SelectOption[];
	onOptionHandler: (option: SelectOption) => void;
	optionIdActive?: string;
}

const Select: FunctionComponent<ISelectProps> = function Select({
	options,
	onOptionHandler,
	optionIdActive,
}) {
	const selectRef = useRef(null);
	const [visibleDropdown, setVisibleDropdown] = useState(false);

	const selectClasses = classNames('select', {
		'select--opened': visibleDropdown,
	});

	useOnClickOutside(selectRef, () => setVisibleDropdown(false));

	const renderOption = useCallback(
		(option: SelectOption) => (
			<div className='select__item' key={uuidv4()}>
				<Button
					onClick={() => {
						onOptionHandler(option);
						setVisibleDropdown(false);
					}}
					className={`select__item-button ${
						optionIdActive && option.id === optionIdActive ? 'select__item-button--selected' : ''
					}`}>
					{option.title}
				</Button>
			</div>
		),
		[optionIdActive]
	);

	return (
		<div className={selectClasses} ref={selectRef}>
			<Button onClick={() => setVisibleDropdown(!visibleDropdown)} className='select__toggle'>
				{options.find(option => option.id === optionIdActive)?.title}
			</Button>
			{visibleDropdown && (
				<div className='select__dropdown'>
					<div className='select__axis-y'>
						<div className='select__items'>{options.map(renderOption)}</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default Select;
