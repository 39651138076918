import {action, makeObservable, observable} from 'mobx';

type LinkPreviewData = {
	title: string | null;
	text: string | null;
};

class LinkPreviewServices {
	@observable
	public isVisibleLinkPreviewWriting = false;

	@observable
	public linkPreviewData: LinkPreviewData | null = null;

	@action
	public toggleIsVisibleLinkPreviewData = (value: boolean, clearLinkPreviewData?: boolean) => {
		this.isVisibleLinkPreviewWriting = value;
		if (clearLinkPreviewData) {
			this.linkPreviewData = null;
		}
	};

	@action
	public setLinkPreviewData = (data: LinkPreviewData | null) => {
		this.linkPreviewData = data;
	};

	constructor() {
		makeObservable(this);
	}
}

export default new LinkPreviewServices();
