import {PollOption} from 'models/poll';
import pollServices from 'store/pollServices';
import {FunctionComponent, useEffect, useRef} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';

const TIMEOUT_ANIMATION = 3000;

interface IPollVotesAnimItemProps {
	pollVote: PollOption;
}

const PollVotesAnimItem: FunctionComponent<IPollVotesAnimItemProps> = function PollVotesAnimItem({
	pollVote,
}) {
	const pollVoteRef: {current: NodeJS.Timeout | null} = useRef(null);
	const {removePollVoteAnim} = useLocalObservable(() => pollServices);

	const clearPollVoteRef = () => {
		if (pollVoteRef.current) {
			clearTimeout(pollVoteRef.current);
		}
	};

	useEffect(() => {
		clearPollVoteRef();
		pollVoteRef.current = setTimeout(() => {
			removePollVoteAnim(pollVote);
		}, TIMEOUT_ANIMATION);
		return () => {
			clearPollVoteRef();
		};
	}, []);

	return (
		<div
			className='poll-votes-anim__item'
			data-anim={pollVote.versionAnim}
			style={{
				backgroundImage: `url(${pollVote.pic})`,
			}}
		/>
	);
};

export default observer(PollVotesAnimItem);
