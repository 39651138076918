import {OnboardStage} from 'models/enums/OnboardStage.enum';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import userServices from 'store/userServices';
import modalServices from 'store/modalServices';
import UserService from 'services/api/UserService';
import {useLocalObservable} from 'mobx-react-lite';

export default () => {
	const {accessToken, userData, updateUserData} = useLocalObservable(() => userServices);
	const {toggleOnboardModal} = useLocalObservable(() => modalServices);

	const onBoardingStageHandler = async () => {
		if (accessToken) {
			const response = await UserService.patchUserData(
				{onboardStage: OnboardStage.NAME},
				accessToken
			);
			if (response.status === ResponseStatus.SUCCESS) {
				if (userData) {
					localStorage.setItem(
						'watcherswebUser',
						JSON.stringify({...userData, onboardStage: OnboardStage.NAME})
					);
					updateUserData({onboardStage: OnboardStage.NAME});
				}
			}
		}

		toggleOnboardModal(true);
	};

	return {
		onBoardingStageHandler,
	};
};
