import {CSSProperties, memo} from 'react';

import {ReactComponent as Warning} from 'assets/svg/ico-toast-warning.svg';
import {ReactComponent as Basket} from 'assets/svg/ico-toast-basket.svg';
import {ReactComponent as Brick} from 'assets/svg/ico-toast-brick.svg';
import {ReactComponent as Close} from 'assets/svg/ico-toast-close.svg';
import {ReactComponent as Copy} from 'assets/svg/ico-toast-copy.svg';
import {ReactComponent as UserBlocked} from 'assets/svg/ico-toast-user-blocked.svg';
import {ReactComponent as UserUnBlocked} from 'assets/svg/ico-toast-user-unblocked.svg';
import {ReactComponent as UserAddedSpeakers} from 'assets/svg/ico-toast-user-added-speakers.svg';
import {ReactComponent as UserRemovedSpeakers} from 'assets/svg/ico-toast-user-removed-speakers.svg';
import {ReactComponent as PlusGreen} from 'assets/svg/ico-submenu-show.svg';
import {ReactComponent as HandPlain} from 'assets/svg/ico-hand-plain.svg';
import {ReactComponent as HandPlainYellow} from 'assets/svg/ico-hand-plain-yellow.svg';
import {ReactComponent as Mic} from 'assets/svg/ico-mic.svg';
import {ReactComponent as MicActive} from 'assets/svg/ico-mic-active.svg';
import {ReactComponent as Pin} from 'assets/svg/ico-toast-pin.svg';
import {ReactComponent as Unpin} from 'assets/svg/ico-toast-unpin.svg';
import {ReactComponent as Poll} from 'assets/svg/ico-toast-poll.svg';
import {ReactComponent as PollEndYellow} from 'assets/svg/ico-pol-end-yellow.svg';
import {ReactComponent as NoInet} from 'assets/svg/ico-noinet.svg';

export enum ToastIconName {
	warning = 'warning',
	basket = 'basket',
	brick = 'brick',
	copy = 'copy',
	userBlocked = 'userBlocked',
	userUnBlocked = 'userUnBlocked',
	userAddedSpeakers = 'userAddedSpeakers',
	userRemovedSpeakers = 'userRemovedSpeakers',
	close = 'close',
	plusGreen = 'plusGreen',
	handPlain = 'handPlain',
	handPlainYellow = 'handPlainYellow',
	mic = 'mic',
	micYellow = 'micYellow',
	pin = 'pin',
	unPin = 'unPin',
	poll = 'poll',
	pollEndYellow = 'pollEndYellow',
	noInet = 'noInet',
}

type ToastIconProps = {
	name: ToastIconName;
	size?: number;
	style?: CSSProperties;
};

const ToastIcon = memo(({name, size = 24, style}: ToastIconProps) => {
	const props = {size, style};
	switch (name) {
		case ToastIconName.warning:
			return <Warning {...props} />;
		case ToastIconName.basket:
			return <Basket {...props} />;
		case ToastIconName.brick:
			return <Brick {...props} />;
		case ToastIconName.close:
			return <Close {...props} />;
		case ToastIconName.copy:
			return <Copy {...props} />;
		case ToastIconName.userBlocked:
			return <UserBlocked {...props} />;
		case ToastIconName.userUnBlocked:
			return <UserUnBlocked {...props} />;
		case ToastIconName.userAddedSpeakers:
			return <UserAddedSpeakers {...props} />;
		case ToastIconName.userRemovedSpeakers:
			return <UserRemovedSpeakers {...props} />;
		case ToastIconName.plusGreen:
			return <PlusGreen {...props} />;
		case ToastIconName.handPlain:
			return <HandPlain {...props} />;
		case ToastIconName.handPlainYellow:
			return <HandPlainYellow {...props} />;
		case ToastIconName.mic:
			return <Mic {...props} />;
		case ToastIconName.micYellow:
			return <MicActive {...props} />;
		case ToastIconName.pin:
			return <Pin {...props} />;
		case ToastIconName.unPin:
			return <Unpin {...props} />;
		case ToastIconName.poll:
			return <Poll {...props} />;
		case ToastIconName.pollEndYellow:
			return <PollEndYellow {...props} />;
		case ToastIconName.noInet:
			return <NoInet {...props} />;
		default:
			return null;
	}
});

export default ToastIcon;
