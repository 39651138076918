import AvatarForm from 'components/avatarForm/AvatarForm';
import useL10n from 'l10n/useL10n';

interface IProfileEditAvatarScreenProps {
	setCurrentScreen: (val: string) => void;
}

const ProfileEditAvatarScreen: React.FC<IProfileEditAvatarScreenProps> =
	function ProfileEditAvatarScreen(props) {
		const {setCurrentScreen} = props;
		const translations = useL10n();
		return (
			<section className='profile-screens'>
				<p className='profile-screens__title'>{translations.avatarScreen.title}</p>
				<AvatarForm currentScreen='profile' setCurrentScreen={() => setCurrentScreen('main')} />
			</section>
		);
	};

export default ProfileEditAvatarScreen;
