import {Talker} from 'models/room';
import {action, makeObservable, observable} from 'mobx';

class ReportModalService {
	@observable
	public isVisible = false;

	@observable
	public reportModalProps: {
		report?: {
			talker: Talker;
			target: 'message' | 'user';
			messageId?: number;
			blockUserByUserAvailable?: boolean;
		};
		ban?: {name: string; userId: number};
	} | null = null;

	@action
	public showReportModal = (props: {
		report?: {
			talker: Talker;
			target: 'message' | 'user';
			messageId?: number;
			blockUserByUserAvailable?: boolean;
		};
		ban?: {name: string; userId: number};
	}) => {
		this.reportModalProps = {...props};
		this.isVisible = true;
	};

	@action
	public hideReportModal = () => {
		this.isVisible = false;
	};

	constructor() {
		makeObservable(this);
	}
}
export default new ReportModalService();
