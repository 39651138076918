import {Message} from 'models/room';
import roomServices from 'store/roomServices';
import useL10n from 'l10n/useL10n';
import useSubmenuActions from 'hooks/useSubmenuActions';
import {FunctionComponent} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Button from 'components/hoc/Button';
import {ReactComponent as Pin} from 'assets/svg/ico-submenu-pin.svg';
import {ReactComponent as UnPin} from 'assets/svg/ico-unpin.svg';

interface IButtonPinnedProps {
	message: Message;
}

const ButtonPinned: FunctionComponent<IButtonPinnedProps> = function ButtonPinned({message}) {
	const {chatScreen} = useL10n();
	const {isPinned: messageIsPinned} = message;
	const {roomId} = useLocalObservable(() => roomServices);
	const {pinMessage} = useSubmenuActions();

	const messagePinHandler = () => {
		if (roomId) {
			pinMessage(message, roomId);
		}
	};

	return (
		<Button className='chat__submenu-btn' onClick={messagePinHandler}>
			{messageIsPinned ? (
				<>
					{chatScreen.chatSubmenu.unpinMessage}
					<UnPin className='chat__submenu-icon' />
				</>
			) : (
				<>
					{chatScreen.chatSubmenu.pinMessage}
					<Pin className='chat__submenu-icon' />
				</>
			)}
		</Button>
	);
};

export default observer(ButtonPinned);
