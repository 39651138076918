/* eslint-disable react/no-danger */
import {FunctionComponent, useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import TooltipPositoinType from 'models/enums/TooltipPositoinType.enum';
import {autoUpdate, flip, shift, useFloating} from '@floating-ui/react-dom';
import classNames from 'classnames';

import './tooltip.scss';

interface ITooltipProps {
	text: string;
	callbackOnHide: () => void;
	referenceElement: any;
	delay?: number;
	position?: TooltipPositoinType;
}

const Tooltip: FunctionComponent<ITooltipProps> = function Tooltip(props) {
	const {delay, text, callbackOnHide, position = TooltipPositoinType.TOP, referenceElement} = props;
	const timeoutCallbackRef: {current: NodeJS.Timeout | null} = useRef(null);
	const [mounted, setMouned] = useState(false);
	const {x, floating, reference, strategy} = useFloating({
		placement: 'bottom-start',
		middleware: [flip(), shift({padding: 8})],
		whileElementsMounted(...args) {
			const cleanup = autoUpdate(...args, {animationFrame: true});
			setMouned(true);
			return cleanup;
		},
	});

	const toolTipClasses = classNames('tooltip', {
		'tooltip--top': position === TooltipPositoinType.TOP,
		'tooltip--bottom': position === TooltipPositoinType.BOTTOM,
	});

	useEffect(() => {
		if (delay) {
			timeoutCallbackRef.current = setTimeout(() => {
				callbackOnHide();
			}, delay);
		}

		return () => {
			if (timeoutCallbackRef.current) {
				clearTimeout(timeoutCallbackRef.current);
			}
		};
	}, []);

	useEffect(() => {
		reference(referenceElement);
	}, [reference, referenceElement]);

	return (
		<div
			className={toolTipClasses}
			ref={floating}
			style={{
				position: strategy,
				left: x ?? 0,
				opacity: mounted ? 1 : 0,
			}}>
			<span
				className='tooltip__arrow'
				style={{
					left: x ? -x + 8 : 8,
				}}
			/>
			<p className='tooltip__text' dangerouslySetInnerHTML={{__html: text}} />
		</div>
	);
};

export default observer(Tooltip);
