import useL10n from 'l10n/useL10n';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {AppScreens} from 'models/enums/AppScreens.enum';
import {useEffect, useState} from 'react';
import appService from 'store/appService';
import roomServices from 'store/roomServices';

import './event.scss';
import classNames from 'classnames';
import UserRole from 'models/enums/UserRole.enum';

const Event: React.FC = function Event() {
	const translations = useL10n();
	const {roomData, myTalker} = useLocalObservable(() => roomServices);
	const {changeCurrentScreen, language} = useLocalObservable(() => appService);
	const [days, setDays] = useState<number>(0);
	const [hours, setHours] = useState<number>(0);
	const [minutes, setMinutes] = useState<number>(0);
	const [seconds, setSeconds] = useState<number>(0);
	const [wainting, setWaiting] = useState(false);
	const [startTime, setStartTime] = useState('');

	const isViewMin =
		(myTalker && myTalker.isModer) ||
		(myTalker && (myTalker.role === UserRole.ADMIN || myTalker.role === UserRole.SPEAKER));
	const locale = ['ru-Ru', 'en-GB'].find(item => item.includes(language)) || 'en-GB';

	const eventClasses = classNames('event', {
		'event--min': isViewMin,
	});

	useEffect(() => {
		if (roomData?.startTime) {
			const countDownDate = new Date(roomData.startTime).getTime();

			const interval = setInterval(() => {
				const now = new Date().getTime();
				const distance = countDownDate - now;

				setDays(Math.floor(distance / (1000 * 60 * 60 * 24)));
				setHours(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
				setMinutes(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
				setSeconds(Math.floor((distance % (1000 * 60)) / 1000));

				if (distance < 0) {
					clearInterval(interval);
					setWaiting(true);
				}
			}, 1000);

			setStartTime(
				new Date(roomData.startTime).toLocaleDateString(locale, {
					month: 'long',
					day: 'numeric',
					hour: 'numeric',
					minute: 'numeric',
				})
			);

			return;
		}
		setWaiting(true);
	}, [roomData?.startTime]);

	useEffect(() => {
		changeCurrentScreen(AppScreens.EVENT);
		return () => {
			changeCurrentScreen(AppScreens.CHAT);
		};
	}, []);

	return (
		<div className={eventClasses}>
			{!isViewMin && (
				<p className='event__title'>
					{wainting ? translations.eventScreen.titleSoon : translations.eventScreen.title}
				</p>
			)}

			{!wainting && (
				<div className='event__container'>
					{!isViewMin ? (
						<p className='event__name'>{roomData?.name}</p>
					) : (
						<p className='event__name'>{`${translations.eventScreen.startTitle} ${startTime}`}</p>
					)}

					<div className='event__countdown'>
						<p className='event__countdown-title'>{translations.eventScreen.subtitle}</p>

						<div className='event__countdown-time'>
							{!!days && (
								<>
									<div className='event__countdown-item'>
										<span className='event__countdown-number'>{days}</span>
										<p className='event__countdown-text'>{translations.eventScreen.days}</p>
									</div>
									<span className='event__countdown-delimeter'>:</span>
								</>
							)}
							{!!hours && (
								<>
									<div className='event__countdown-item'>
										<span className='event__countdown-number'>{hours}</span>
										<p className='event__countdown-text'>{translations.eventScreen.hours}</p>
									</div>
									<span className='event__countdown-delimeter'>:</span>
								</>
							)}

							<div className='event__countdown-item'>
								<span className='event__countdown-number'>{minutes}</span>
								<p className='event__countdown-text'>{translations.eventScreen.minutes}</p>
							</div>

							{!days && (
								<>
									<span className='event__countdown-delimeter'>:</span>
									<div className='event__countdown-item'>
										<span className='event__countdown-number'>{seconds}</span>
										<p className='event__countdown-text'>{translations.eventScreen.seconds}</p>
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			)}

			{wainting && <span className='event__loading' />}
		</div>
	);
};

export default observer(Event);
