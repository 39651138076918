import {FunctionComponent} from 'react';
import {CSSTransition} from 'react-transition-group';
import {ReactComponent as Loader} from 'assets/svg/loader.svg';
import './preloader.scss';

interface IPreloaderProps {
	visible: boolean;
}

const Preloader: FunctionComponent<IPreloaderProps> = function Preloader({visible = false}) {
	return (
		<CSSTransition in={visible} timeout={500} classNames='fade' unmountOnExit>
			<div className='preloader'>
				<Loader />
			</div>
		</CSSTransition>
	);
};

export default Preloader;
