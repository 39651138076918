import appService from 'store/appService';
import roomServices from 'store/roomServices';
import {FunctionComponent} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {Player} from 'components/player';
import RecordingImg from 'assets/images/recording-img.png';
import './recording.scss';

const Recording: FunctionComponent = function Recording() {
	const {language} = useLocalObservable(() => appService);
	const {roomData} = useLocalObservable(() => roomServices);
	const locale = ['ru-Ru', 'en-GB', 'am-Am'].find(item => item.includes(language)) || 'en-GB';

	return (
		<div className='recording'>
			<div className='recording__head'>
				{roomData?.pic && (
					<div className='recording__img' style={{backgroundImage: `url(${RecordingImg})`}} />
				)}
				{roomData?.name && (
					<div className='recording__suptitle'>
						{roomData?.createdAt
							? new Date(roomData.createdAt).toLocaleDateString(locale, {
									day: 'numeric',
									month: 'long',
							  })
							: ''}
						, {roomData?.name}
					</div>
				)}
				{roomData?.about && <div className='recording__title'>{roomData?.about}</div>}
			</div>
			<div className='recording__body'>
				<Player />
			</div>
		</div>
	);
};

export default observer(Recording);
