import {ActionType} from 'models/enums/ActionType.enum';
import {Message} from 'models/room';
import roomServices from 'store/roomServices';
import actionMessageServices from 'store/actionMessageServices';
import useL10n from 'l10n/useL10n';
import {FunctionComponent} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Button from 'components/hoc/Button';
import {ReactComponent as EditIcon} from 'assets/svg/ico-edit.svg';

interface IButtonEditProps {
	message: Message;
}

const ButtonEdit: FunctionComponent<IButtonEditProps> = function ButtonEdit({message}) {
	const {chatScreen} = useL10n();
	const {setSubmenuMessage} = useLocalObservable(() => roomServices);
	const {toggleIsVisibleActionMessage, setActionData} = useLocalObservable(
		() => actionMessageServices
	);

	const setFocusInTextarea = () => {
		const chatTextarea: HTMLTextAreaElement | null = document.querySelector('.chat__textarea-item');
		chatTextarea?.focus();
	};

	const onEditHandler = () => {
		setActionData({type: ActionType.EDIT, message});
		toggleIsVisibleActionMessage(true);
		setSubmenuMessage(null);
		setFocusInTextarea();
	};

	return (
		<Button className='chat__submenu-btn' onClick={onEditHandler}>
			{chatScreen.chatSubmenu.edit}
			<EditIcon className='chat__submenu-icon' />
		</Button>
	);
};

export default observer(ButtonEdit);
