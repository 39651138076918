import {action, makeObservable, observable} from 'mobx';

class SlowModeServices {
	@observable
	public slowMode: SlowMode = {
		global: {
			enable: false,
			time: 1000,
		},
		local: {
			enable: false,
			time: 1000,
		},
	};

	@observable
	public slowModeTimeLeft = 0;

	@observable
	public slowModeTimeLeftOffset = 0;

	@observable
	public slowModeCountdownEnable = false;

	@action
	public togglleSlowMode = (value: any) => {
		this.slowMode = {
			...this.slowMode,
			...value,
		};
	};

	@action
	public setSlowModeTimeLeft = (value: number) => {
		this.slowModeTimeLeft = value;
	};

	@action
	public setslowModeTimeLeftOffset = (value: number) => {
		this.slowModeTimeLeftOffset = value;
	};

	@action
	public toggleSlowModeCountdownEnable = (value: boolean) => {
		this.slowModeCountdownEnable = value;
	};

	constructor() {
		makeObservable(this);
	}
}

export default new SlowModeServices();
