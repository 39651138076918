import LanguageTag from 'models/enums/LanguageTag.enum';
import appService from 'store/appService';
import {useCallback} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import translations from 'l10n/translations';
import languageName from 'l10n/languageName';
import './language.scss';
import Button from 'components/hoc/Button';

const Language: React.FC = function Language() {
	const {language, setLanguage} = useLocalObservable(() => appService);

	const supportedLocaleCodes = translations.getAvailableLanguages();

	const onLanguageHandler = (lang: string) => {
		setLanguage(lang as LanguageTag);
	};

	const renderLanguageItem = useCallback(
		(item: string) => {
			return (
				<div className='language__item' key={item}>
					<Button
						className={`language__button ${item === language ? 'language__button--active' : ''}`}
						onClick={() => onLanguageHandler(item)}>
						{languageName[item as LanguageTag]}
					</Button>
				</div>
			);
		},
		[language]
	);

	return (
		<div className='language'>
			<div className='language__items'>{supportedLocaleCodes.map(renderLanguageItem)}</div>
		</div>
	);
};

export default observer(Language);
