import {useCallback, useRef, useState} from 'react';

const useLongPress = (
	callback: () => void,
	onClick?: () => void,
	{shouldPreventDefault = true, delay = 300} = {}
) => {
	const [longPressTriggered, setLongPressTriggered] = useState(false);
	const [touchMoveTriggered, setTouchMoveTriggered] = useState(false);
	const timeout = useRef<any>();
	const target = useRef<any>();

	const isTouchEvent = (event: any) => {
		return 'touches' in event;
	};

	const preventDefault = (event: any) => {
		if (!isTouchEvent(event)) return;

		if (event.touches.length < 2 && event.preventDefault) {
			event.preventDefault();
		}
	};

	const start = useCallback(
		event => {
			const eventTarget = event.target;
			if (
				eventTarget.tagName.toLowerCase() !== 'a' &&
				!eventTarget.classList.contains('link-preview') &&
				!eventTarget.classList.contains('reactions__item-btn')
			) {
				if (shouldPreventDefault && eventTarget) {
					eventTarget.addEventListener('touchend', preventDefault, {
						passive: false,
					});
					target.current = eventTarget;
				}
				timeout.current = setTimeout(() => {
					callback();
					setLongPressTriggered(true);
				}, delay);
			}
		},
		[callback, delay, shouldPreventDefault]
	);

	const clear = useCallback(
		(event, shouldTriggerClick = true) => {
			timeout.current && clearTimeout(timeout.current);
			if (shouldTriggerClick && !longPressTriggered && onClick && !touchMoveTriggered) {
				onClick();
			}
			setLongPressTriggered(false);
			if (shouldPreventDefault && target.current) {
				target.current.removeEventListener('touchend', preventDefault);
			}
		},
		[shouldPreventDefault, onClick, longPressTriggered]
	);

	return {
		onMouseDown: (e: any) => start(e),
		onTouchStart: (e: any) => start(e),
		onTouchMove: (e: any) => {
			setTouchMoveTriggered(true);
			clear(e, false);
		},
		onMouseUp: (e: any) => clear(e),
		onMouseLeave: (e: any) => clear(e, false),
		onTouchEnd: (e: any) => {
			setTouchMoveTriggered(false);
			clear(e);
		},
	};
};

export default useLongPress;
