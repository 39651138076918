import LanguageTag from 'models/enums/LanguageTag.enum';
import translations from 'l10n/translations';
import {action, makeObservable, observable, computed} from 'mobx';
import {AppScreens} from 'models/enums/AppScreens.enum';
import {NetworkQuality} from 'models/enums/NetworkQuality';
import {AgoraStatus} from 'models/enums/AgoraStatus.enum';

class AppService {
	@observable
	public appReadOnly = false;

	@observable
	public _blockedUsers: User[] = [];

	@observable
	public appVoice = false;

	@observable
	public language: LanguageTag = LanguageTag.ru;

	@observable
	public deletedState = false;

	@observable
	public currentScreen: string | null = null;

	@observable
	public isInternernetActive = true;

	@observable
	public networkQuality: NetworkQuality = NetworkQuality.NORMAL;

	@observable
	public agoraStatus: AgoraStatus = AgoraStatus.DESTROYED;

	@observable
	public agoraMicrophoneActive: MediaDeviceInfo | null = null;

	@observable
	public agoraMicrophones: MediaDeviceInfo[] = [];

	@observable
	public agoraPlaybackDeviceActive: MediaDeviceInfo | null = null;

	@observable
	public agoraPlaybackDevices: MediaDeviceInfo[] = [];

	@observable
	public rules = '';

	@action
	public toggleAppReadOnly = (value: boolean) => {
		this.appReadOnly = value;
	};

	@action
	public setRules = (value: string) => {
		this.rules = value;
	};

	@action
	public changeAgoraStatus = (value: AgoraStatus) => {
		this.agoraStatus = value;
	};

	@action
	public setNetworkQuality = (value: NetworkQuality) => {
		if (value === this.networkQuality) return;
		this.networkQuality = value;
	};

	@action
	public changeInternetConnectionStatus = (value: boolean) => {
		this.isInternernetActive = value;
	};

	@action
	public changeCurrentScreen = (value: AppScreens) => {
		this.currentScreen = value;
	};

	@action
	public changeDeleteState = (value: boolean) => {
		this.deletedState = value;
	};

	@action
	public addBlockedUsers = (data: BlockedUsers) => {
		this._blockedUsers = data.initiator;
	};

	@action
	public setAppVoice = (value: boolean) => {
		this.appVoice = value;
	};

	@action
	public setAgoraMicrophoneActive = (data: MediaDeviceInfo) => {
		this.agoraMicrophoneActive = data;
	};

	@action
	public setAgoraMicrophones = (data: MediaDeviceInfo[]) => {
		this.agoraMicrophones = data;
	};

	@action
	public setAgoraPlaybackDeviceActive = (data: MediaDeviceInfo) => {
		this.agoraPlaybackDeviceActive = data;
	};

	@action
	public setAgoraPlaybackDevices = (data: MediaDeviceInfo[]) => {
		this.agoraPlaybackDevices = data;
	};

	@action
	public addBlockedUser = (blockedUser: User) => {
		const findBlockedUser = !!this._blockedUsers.find(item => item.id === blockedUser.id);
		if (!findBlockedUser) {
			this._blockedUsers.push(blockedUser);
		}
	};

	@action
	public removeBlockedUser = (blockedUserId: number) => {
		this._blockedUsers = this._blockedUsers.filter(item => item.id !== blockedUserId);
	};

	@action
	public setLanguage = (lang: LanguageTag) => {
		translations.setLanguage(lang);
		this.language = lang;
		localStorage.setItem('watcherswebLanguage', lang);
	};

	@computed
	get blockedUsers() {
		return this._blockedUsers?.slice();
	}

	constructor() {
		makeObservable(this);
	}
}

export default new AppService();
