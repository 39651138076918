import {USER_UNNAMED} from 'constants/constants';
import {Talker} from 'models/room';
import roomServices from 'store/roomServices';
import SocketIoServices from 'services/SocketIoServices';
import {useCallback} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import AnonimAva from 'assets/images/anonim-ava.jpg';
import {ReactComponent as IcoRefuseSpeaker} from 'assets/svg/ico-submenu-hide.svg';
import {ReactComponent as IcoAddedSpeaker} from 'assets/svg/ico-toast-user-added-speakers.svg';
import './waitingSpeakers.scss';
import UserRole from 'models/enums/UserRole.enum';
import useL10n from 'l10n/useL10n';
import useModeratorActions from 'hooks/useSubmenuActions';
import Button from 'components/hoc/Button';
import {Avatar} from 'components/avatar';

const WaitingSpeakers: React.FC = function WaitingSpeakers() {
	const {modals} = useL10n();
	const {roomId, talkersWaitingSpeakers} = useLocalObservable(() => roomServices);
	const {changeUserRole} = useModeratorActions();

	const onRejectedHandler = (talkerId: number) => {
		roomId && SocketIoServices.emitRejectHand(roomId, talkerId);
	};

	const onSetRoleHandler = (user: User) => {
		if (roomId && user.id) {
			changeUserRole(user, roomId, UserRole.SPEAKER);
		}
	};

	const renderWaitingSpeaker = useCallback(
		({id, user}: Talker) => (
			<div className='waiting-speakers__item' key={id}>
				<div className='waiting-speakers__item-avatar'>
					<Avatar
						src={user.pic !== '' ? user.pic : AnonimAva}
						alt={user.name && user.name !== '' ? user.name : USER_UNNAMED}
						className='waiting-speakers__item-avatar-img'
					/>
				</div>
				<div className='waiting-speakers__item-right'>
					<p className='waiting-speakers__item-name'>{user.name}</p>
					<div className='waiting-speakers__item-btns'>
						<Button className='waiting-speakers__item-btn' onClick={() => onRejectedHandler(id)}>
							<IcoRefuseSpeaker />
						</Button>
						<Button className='waiting-speakers__item-btn' onClick={() => onSetRoleHandler(user)}>
							<IcoAddedSpeaker />
						</Button>
					</div>
				</div>
			</div>
		),
		[talkersWaitingSpeakers]
	);

	if (!talkersWaitingSpeakers?.length) {
		return (
			<div className='waiting-speakers waiting-speakers--empty'>
				<div className='waiting-speakers__title'>{modals.waitingSpeakers.title}</div>
				<div className='waiting-speakers__text'>{modals.waitingSpeakers.text}</div>
			</div>
		);
	}

	return <div className='waiting-speakers'>{talkersWaitingSpeakers.map(renderWaitingSpeaker)}</div>;
};

export default observer(WaitingSpeakers);
