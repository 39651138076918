import {Tooltip} from 'models/tooltip';
import {action, makeObservable, observable} from 'mobx';

class TooltipsServices {
	@observable
	public tooltip: Tooltip | null = null;

	@action
	public toggleTooltip = (data: Tooltip | null) => {
		this.tooltip = data;
	};

	constructor() {
		makeObservable(this);
	}
}

export default new TooltipsServices();
