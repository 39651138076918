import {times} from 'data/slowModeData';
import windowLocalStorage from 'utils/windowLocalStorage';
import {findValueByKeyInObject} from 'utils/findValueByKeyInObject';
import LanguageTag from 'models/enums/LanguageTag.enum';
import RoomStatus from 'models/enums/RoomStatus.enum';
import TooltipPositoinType from 'models/enums/TooltipPositoinType.enum';
import TooltipType from 'models/enums/TooltipType.enum';
import {Tooltip as TooltipModel} from 'models/tooltip';
import {NetworkQuality} from 'models/enums/NetworkQuality';
import useL10n from 'l10n/useL10n';
import useAnalytics from 'hooks/useAnalytics';
import useOnboarding from 'hooks/useOnboarding';
import appService from 'store/appService';
import userServices from 'store/userServices';
import roomServices from 'store/roomServices';
import modalServices from 'store/modalServices';
import tooltipsServices from 'store/tooltipsServices';
import slowModeServices from 'store/slowModeServices';
import {useEffect, useRef, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {ReactComponent as IcoSettings} from 'assets/svg/ico-settings.svg';
import {ReactComponent as NoInet} from 'assets/svg/ico-noinet.svg';
import {ReactComponent as BadInet} from 'assets/svg/ico-badinet.svg';
import {ReactComponent as IcoSlowmode} from 'assets/svg/ico-slowmode.svg';
import Button from 'components/hoc/Button';
import Tooltip from 'components/tooltip/Tooltip';
import {Users} from 'pages/main/components/users';
import './header.scss';

const Header: React.FC = function Header() {
	const timeoutBlockedUsersToolTipVisibilityRef: {current: NodeJS.Timeout | null} = useRef(null);
	const referenceNoInet = useRef<HTMLButtonElement>(null);
	const referenceSettings = useRef<HTMLDivElement>(null);
	const referenceSlowMode = useRef<HTMLDivElement>(null);
	const tooltipRef: {current: TooltipModel | null} = useRef(null);
	const [noInetTooltip, setNoInetTooltip] = useState(false);
	const [slowModeTooltip, setSlowModeTooltip] = useState(false);
	const [blockedUsersToolTipVisibility, setBlockedUsersToolTipVisibility] = useState(false);
	const {appReadOnly, language, isInternernetActive, networkQuality, blockedUsers} =
		useLocalObservable(() => appService);
	const {userData, isUserExternalIdCorrect} = useLocalObservable(() => userServices);
	const {roomData, myTalker} = useLocalObservable(() => roomServices);
	const {showSettingsModal} = useLocalObservable(() => modalServices);
	const {tooltip, toggleTooltip} = useLocalObservable(() => tooltipsServices);
	const {slowMode} = useLocalObservable(() => slowModeServices);
	const {toasts, tooltips} = useL10n();
	const {onBoardingStageHandler} = useOnboarding();
	const {sendAnalytics} = useAnalytics();

	const isMyTalkerModer = !!myTalker?.isModer;
	const watcherswebTooltips = windowLocalStorage.get<Tooltips>('watcherswebTooltips');

	const checkForBlockedUsersTooltip = () => {
		return (
			!isMyTalkerModer &&
			blockedUsers.length &&
			(!tooltipRef.current ||
				!tooltipRef.current.shown ||
				(tooltipRef.current &&
					tooltipRef.current.shown &&
					tooltipRef.current.type === TooltipType.INFORMATIONAL)) &&
			(!watcherswebTooltips ||
				(watcherswebTooltips && typeof watcherswebTooltips.blockedUsers === 'undefined'))
		);
	};

	const checkShownTooltipTypeTriggered = () => {
		return (
			tooltipRef.current &&
			tooltipRef.current.shown &&
			tooltipRef.current.type === TooltipType.TRIGGER
		);
	};

	const textForSlowModeTooltip = () => {
		let findtTime = times.find(item => item.time === slowMode.local.time);
		if (slowMode.global.enable && !slowMode.local.enable) {
			findtTime = times.find(item => item.time === slowMode.global.time);
		}
		if (findtTime) {
			const findTitle = findValueByKeyInObject<{default: string; prefix?: string}>(
				findtTime.title,
				language,
				{default: findtTime.title.ru.default}
			);
			return tooltips.slowMode.header(
				language === LanguageTag.ru
					? findTitle.prefix || `ые ${findTitle.default}`
					: findTitle.default
			);
		}
		return '';
	};

	const onSettingsHandler = () => {
		if (!appReadOnly && isUserExternalIdCorrect) {
			sendAnalytics('settings_opened');
			showSettingsModal();
		}
	};

	useEffect(() => {
		return () => {
			if (timeoutBlockedUsersToolTipVisibilityRef.current) {
				clearTimeout(timeoutBlockedUsersToolTipVisibilityRef.current);
			}
		};
	}, []);

	useEffect(() => {
		if (checkForBlockedUsersTooltip()) {
			timeoutBlockedUsersToolTipVisibilityRef.current = setTimeout(() => {
				if (checkForBlockedUsersTooltip()) {
					setBlockedUsersToolTipVisibility(true);
					windowLocalStorage.set('watcherswebTooltips', {
						...watcherswebTooltips,
						blockedUsers: false,
					});
					if (!tooltipRef.current?.shown) {
						toggleTooltip({shown: true, type: TooltipType.TRIGGER});
					}
				}
			}, 3000);
		}
	}, [myTalker, blockedUsers.length, tooltipRef.current]);

	useEffect(() => {
		if (isInternernetActive && networkQuality === NetworkQuality.POOR) {
			sendAnalytics('bad_connection');
		}
	}, [isInternernetActive, networkQuality]);

	return (
		<div className='header'>
			<div className='header__left'>
				<div className='header__settings-wrapper' ref={referenceSettings}>
					<Button
						className='header__settings'
						onClick={userData?.isOnboard ? onSettingsHandler : onBoardingStageHandler}>
						<IcoSettings />
					</Button>

					{blockedUsersToolTipVisibility && (
						<Tooltip
							referenceElement={referenceSettings.current}
							position={TooltipPositoinType.BOTTOM}
							text={tooltips.blockedUsers}
							delay={3000}
							callbackOnHide={() => {
								setBlockedUsersToolTipVisibility(false);
								if (checkShownTooltipTypeTriggered()) {
									toggleTooltip(null);
								}
							}}
						/>
					)}
				</div>

				{roomData?.status === RoomStatus.LIVE && <Users />}

				{isMyTalkerModer && (slowMode.global.enable || slowMode.local.enable) && (
					<div className='header__slowmode-wrapper' ref={referenceSlowMode}>
						<Button
							className='header__slowmode-btn'
							onClick={() => setSlowModeTooltip(!slowModeTooltip)}>
							<IcoSlowmode />
						</Button>

						{slowModeTooltip && textForSlowModeTooltip() && (
							<Tooltip
								referenceElement={referenceSlowMode.current}
								position={TooltipPositoinType.BOTTOM}
								text={textForSlowModeTooltip()}
								delay={3000}
								callbackOnHide={() => setSlowModeTooltip(false)}
							/>
						)}
					</div>
				)}

				{(!isInternernetActive ||
					(isInternernetActive && networkQuality === NetworkQuality.POOR)) && (
					<div className='header__noinet-wrapper'>
						<Button
							className='header__noinet-btn'
							workWithoutInet
							innerRef={referenceNoInet}
							onClick={() => setNoInetTooltip(!noInetTooltip)}>
							{!isInternernetActive ? (
								<NoInet className='header__noinet-icon' />
							) : (
								<BadInet className='header__noinet-icon' />
							)}
						</Button>

						{noInetTooltip && (
							<Tooltip
								referenceElement={referenceNoInet.current}
								position={TooltipPositoinType.BOTTOM}
								text={!isInternernetActive ? toasts.noInet : tooltips.badInet}
								delay={3000}
								callbackOnHide={() => {
									setNoInetTooltip(false);
									if (tooltip?.shown) {
										toggleTooltip(null);
									}
								}}
							/>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default observer(Header);
