import {USER_UNNAMED} from 'constants/constants';
import useAnalytics from 'hooks/useAnalytics';
import {useCallback, useEffect, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import './Users.scss';
import roomServices from 'store/roomServices';
import modalServices from 'store/modalServices';
import AnonimAva from 'assets/images/anonim-ava.jpg';

import {ReactComponent as IcoUsers} from 'assets/svg/ico-users.svg';
import {Talker, Talkers} from 'models/room';
import UserRole from 'models/enums/UserRole.enum';
import classNames from 'classnames';
import Button from 'components/hoc/Button';

const Users: React.FC = function Users() {
	const {sendAnalytics} = useAnalytics();
	const {roomData, talkers} = useLocalObservable(() => roomServices);
	const {showVisibleModalUsers} = useLocalObservable(() => modalServices);
	const [currentTalkers, setCurrentTalkers] = useState<Talkers>([]);

	useEffect(() => {
		const moderTalkers: Talkers = talkers.filter(t => t.isModer && t.role !== UserRole.GUEST);
		const speakers: Talkers = talkers.filter(
			t => t.role === UserRole.SPEAKER && !moderTalkers.find(moderTalker => moderTalker.id === t.id)
		);

		setCurrentTalkers([...moderTalkers, ...speakers]);
	}, [talkers]);

	const userVOD = (talker: Talker) => {
		return classNames('users__list-item', {
			'users__list-item--active': talker.voiceActive,
		});
	};

	const renderNowSpeakers = useCallback(
		(talker: Talker) => (
			<div key={talker.id} className={userVOD(talker)}>
				<img
					className='users__list-item-img'
					src={talker.user.pic !== '' ? talker.user.pic : AnonimAva}
					alt={talker.user.name !== '' ? talker.user.name : USER_UNNAMED}
				/>
			</div>
		),
		[currentTalkers]
	);

	return (
		<div className='users'>
			{roomData?.isSpeak && currentTalkers.length > 0 && (
				<div className='users__list'>{currentTalkers.slice(0, 2).map(renderNowSpeakers)}</div>
			)}
			<div className='users__counter'>
				<IcoUsers />
				<span className='users__counter-number'>{talkers.length}</span>
			</div>
			<Button
				className='users__click-area'
				onClick={() => {
					sendAnalytics('user-list_openned');
					showVisibleModalUsers();
				}}
			/>
		</div>
	);
};

export default observer(Users);
