/* eslint-disable no-param-reassign */
const scrollOnDrag = (elem: HTMLElement) => {
	elem.style.cursor = 'grab';

	let pos = {top: 0, left: 0, x: 0, y: 0};

	const mouseMoveHandler = (e: MouseEvent) => {
		const dx = e.clientX - pos.x;
		const dy = e.clientY - pos.y;

		elem.scrollTop = pos.top - dy;
		elem.scrollLeft = pos.left - dx;
	};

	const mouseUpHandler = () => {
		elem.style.cursor = 'grab';
		elem.style.removeProperty('user-select');

		document.removeEventListener('mousemove', mouseMoveHandler);
		document.removeEventListener('mouseup', mouseUpHandler);
	};

	const mouseDownHandler = (e: MouseEvent) => {
		elem.style.cursor = 'grabbing';
		elem.style.userSelect = 'none';

		pos = {
			left: elem.scrollLeft,
			top: elem.scrollTop,
			x: e.clientX,
			y: e.clientY,
		};

		document.addEventListener('mousemove', mouseMoveHandler);
		document.addEventListener('mouseup', mouseUpHandler);
	};

	elem.addEventListener('mousedown', mouseDownHandler);
};

export default scrollOnDrag;
