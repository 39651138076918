import {PollMessageData, PollOption} from 'models/poll';
import useL10n from 'l10n/useL10n';
import usePollActions from 'hooks/usePollActions';
import {FunctionComponent, useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {PollOptionVoting} from 'components/poll/pollOptionVoting';
import './pollResults.scss';

interface IPollResultsProps {
	pollResults: PollMessageData;
}

const PollResults: FunctionComponent<IPollResultsProps> = function PollResults({pollResults}) {
	const {poll} = pollResults;
	const {poll: pollTranslate} = useL10n();
	const {calcPollOptionVote, calcPollVotesLength} = usePollActions();

	const renderPollResultsOption = useCallback(
		(option: PollOption) => (
			<div className='poll-results__option' key={option.id}>
				{option.pic && (
					<div
						className='poll-results__option-img'
						style={{backgroundImage: `url(${option.pic})`}}
					/>
				)}
				<div className='poll-results__option-content'>
					<p className='poll-results__option-title'>{option.text}</p>
					<PollOptionVoting voteNumber={calcPollOptionVote(poll.options, option.id)} />
				</div>
			</div>
		),
		[]
	);

	return (
		<div className='poll-results'>
			<div className='poll-results__text'>{poll.text}</div>
			<div className='poll-results__subtext'>{pollTranslate.results.text}</div>
			<div className='poll-results__options'>{poll.options.map(renderPollResultsOption)}</div>
			<div className='poll-results__votes'>
				{/* {pollVotesLength() ? pollVotesLength() : `0 ${pollTranslate.results.words[2]}`} */}
				{calcPollVotesLength(poll)
					? calcPollVotesLength(poll)
					: `${pollTranslate.results.votes}: 0`}
			</div>
		</div>
	);
};

export default observer(PollResults);
