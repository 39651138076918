import {Emotion, Reaction} from 'models/room';
import roomServices from 'store/roomServices';
import emotionServices from 'store/emotionServices';
import useReactions from 'hooks/useReactions';
import {FunctionComponent} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {v4 as uuidv4} from 'uuid';

interface IChatMessageSubmenuReactionsProps {
	messageId: number;
	reactions?: Reaction[];
}

const ChatMessageSubmenuReactions: FunctionComponent<IChatMessageSubmenuReactionsProps> =
	function ChatMessageSubmenuReactions({messageId, reactions}) {
		const {setSubmenuMessage} = useLocalObservable(() => roomServices);
		const {emotions} = useLocalObservable(() => emotionServices);
		const {reactionItemHandler} = useReactions();

		const onClickSubmenuReactionItemHandler = (emotion: Emotion) => {
			reactionItemHandler({
				id: uuidv4(),
				name: emotion.name,
				messageId,
				items: reactions || [],
			});
			setSubmenuMessage(null);
		};

		const renderReactionsBtn = (emotion: Emotion) => (
			<button
				type='button'
				key={emotion.id}
				data-id={emotion.id}
				className='chat__submenu-reactions-btn'
				style={{backgroundImage: `url(${emotion.src})`}}
				onClick={() => onClickSubmenuReactionItemHandler(emotion)}
				aria-hidden='true'
			/>
		);

		return (
			<div className='chat__submenu-reactions'>
				<div className='chat__submenu-reactions-axis-x'>
					<div className='chat__submenu-reactions-items'>{emotions.map(renderReactionsBtn)}</div>
				</div>
			</div>
		);
	};

export default observer(ChatMessageSubmenuReactions);
