import useL10n from 'l10n/useL10n';
import ChatBotWarnings from 'models/enums/ChatBotWarnings';
import MessageType from 'models/enums/MessageType.enum';
import {Message} from 'models/room';

export default () => {
	const {chatBot, modals} = useL10n();

	const visibleMessageElements = (index: number, message: Message, messages: Message[]) => {
		const {talker, createdAt} = message;

		let visibleAvatar = true;
		let visibleName = true;
		let visibleDate = false;

		const nextMessage = messages[index + 1];
		const prevMessage = messages[index - 1];

		if (nextMessage && nextMessage.talker.id === talker.id) {
			visibleAvatar = false;
		}

		if (prevMessage) {
			if (prevMessage.talker.id === talker.id) {
				visibleName = false;
			}

			visibleDate = prevMessage.createdAt.split('T')[0] !== createdAt.split('T')[0];
		}

		return {visibleAvatar, visibleName, visibleDate};
	};

	const checkingMessageFromChatBot = (messageText: string) => {
		switch (messageText) {
			case ChatBotWarnings.DISABLED:
				return chatBot.warnings.disabled;
			case ChatBotWarnings.OVERLOADED:
				return chatBot.warnings.overloaded;
			default:
				return messageText;
		}
	};

	const getMessageText = (messageData: Message) => {
		return messageData.type === MessageType.POLLRESULTS && messageData.pollText
			? JSON.parse(messageData.pollText).poll.text || ''
			: checkingMessageFromChatBot(messageData.text) || '';
	};

	const getBetTitle = (messageData: Message) => {
		const bet = JSON.parse(messageData.bet);
		return `${bet.betType}. ${bet.positions.length} ${modals.shareBet.bet} - ${modals.shareBet.totalFactor}: ${bet.totalFactor}`;
	};

	return {getMessageText, checkingMessageFromChatBot, getBetTitle, visibleMessageElements};
};
