import UserRole from 'models/enums/UserRole.enum';
import {Message} from 'models/room';
import roomServices from 'store/roomServices';
import modalServices from 'store/modalServices';
import useL10n from 'l10n/useL10n';
import useSubmenuActions from 'hooks/useSubmenuActions';
import {FunctionComponent} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Button from 'components/hoc/Button';
import {ReactComponent as RemoveFromSpeakers} from 'assets/svg/ico-submenu-remove-speaker.svg';
import {ReactComponent as IcoAddSpeaker} from 'assets/svg/ico-speaker-add.svg';

interface IButtonChangeRoleProps {
	message: Message;
}

const ButtonChangeRole: FunctionComponent<IButtonChangeRoleProps> = function ButtonChangeRole({
	message,
}) {
	const {chatScreen} = useL10n();
	const {talker} = message;
	const {roomId} = useLocalObservable(() => roomServices);
	const {hideVisibleModalUsers} = useLocalObservable(() => modalServices);
	const {changeUserRole} = useSubmenuActions();

	const isTalkerSpeaker = talker.role === UserRole.SPEAKER;

	const onChangeRoleHandler = () => {
		hideVisibleModalUsers();
		if (roomId && message.talker.user) {
			changeUserRole(
				message.talker.user,
				roomId,
				talker?.role === UserRole.SPEAKER ? UserRole.GUEST : UserRole.SPEAKER
			);
		}
	};

	return (
		<Button className='chat__submenu-btn' onClick={onChangeRoleHandler}>
			{isTalkerSpeaker ? (
				<>
					{chatScreen.chatSubmenu.removeFromSpeakers}
					<RemoveFromSpeakers className='chat__submenu-icon' />
				</>
			) : (
				<>
					{chatScreen.chatSubmenu.setSpeaker}
					<IcoAddSpeaker className='chat__submenu-icon' />
				</>
			)}
		</Button>
	);
};

export default observer(ButtonChangeRole);
