import {action, computed, makeObservable, observable} from 'mobx';
import {ToastMessage} from 'models/toast';
import {v4 as uuidv4} from 'uuid';

class ToastService {
	@observable
	private _toasts: ToastMessage[] = [];

	@action.bound
	addToast = (toast: Omit<ToastMessage, 'id' | 'timestamp'>) => {
		this._toasts = [...this._toasts, {id: uuidv4(), timestamp: new Date(), ...toast}];
	};

	@action.bound
	deleteToast = (toast: ToastMessage) => {
		this._toasts = this._toasts.filter(t => t.id !== toast.id);
	};

	@action.bound
	deleteToastByMessage = (message: string) => {
		this._toasts = this._toasts.filter(t => t.message !== message);
	};

	@computed
	get toasts() {
		return this._toasts.slice();
	}

	constructor() {
		makeObservable(this);
	}
}

export default new ToastService();
