import chatServices from 'store/chatServices';
import {FunctionComponent} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import LoadingSkeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './chat.scss';

const ChatIntro: FunctionComponent = function ChatIntro() {
	const {greetingMessage} = useLocalObservable(() => chatServices);

	return (
		<div className='chat__intro'>
			<div className='chat__intro-messages'>
				{[0, 1].map((i: number) => (
					<div className='chat__intro-message' key={i}>
						<div className='chat__intro-message-avatar'>
							<LoadingSkeleton circle />
						</div>
						<div className='chat__intro-message-body'>
							<LoadingSkeleton count={2} />
						</div>
					</div>
				))}
			</div>
			{greetingMessage && (greetingMessage.title || greetingMessage.text) && (
				<div className='chat__intro-content'>
					{greetingMessage.title && <h3 className='chat__intro-title'>{greetingMessage.title}</h3>}
					{greetingMessage.text && <p className='chat__intro-text'>{greetingMessage.text}</p>}
				</div>
			)}
		</div>
	);
};

export default observer(ChatIntro);
