import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useCallback, useEffect, useRef, useState} from 'react';
import Modal from 'react-modal';

import useL10n from 'l10n/useL10n';
import modalServices from 'store/modalServices';
import betServices from 'store/betServices';

import {MODAL_STYLE} from 'constants/constants';

import {Bet} from 'models/bet';

import Button from 'components/hoc/Button';
import BetItem from 'components/bet/BetItem';

import './shareBet.scss';
import {Preloader} from 'components/preloader';
import useBet from 'hooks/useBet';
import {debounce} from 'lodash';

const ShareBetModal: FunctionComponent = function ShareBet() {
	const {modals} = useL10n();
	const {shareBetModal, toggleShareBetModal} = useLocalObservable(() => modalServices);
	const {bets, clearBets} = useLocalObservable(() => betServices);

	const [visiblePreloaderBottom, setVisiblePreloaderBottom] = useState(false);
	const [visiblePreloader, setVisiblePreloader] = useState(false);

	const {getBetsWithServices, checkScrollBottom} = useBet();

	const userBetsRef = useRef<HTMLDivElement>(null);
	const userBetsScrollRef = useRef<HTMLDivElement>(null);

	const onScrollHandler = useCallback(
		debounce(
			scrollEvent =>
				checkScrollBottom(scrollEvent, userBetsScrollRef.current, setVisiblePreloaderBottom),
			50
		),
		[bets, visiblePreloader, visiblePreloaderBottom]
	);

	const getBets = async () => {
		setVisiblePreloader(true);
		await getBetsWithServices();
		setVisiblePreloader(false);
	};

	useEffect(() => {
		shareBetModal && getBets();
		!shareBetModal && clearBets();
	}, [shareBetModal]);

	const renderUserBet = (item: Bet, key: number | string) => (
		<BetItem key={key} bet={item} isShareBetModal />
	);

	return (
		<Modal
			className='modal modal--share-bet'
			isOpen={shareBetModal}
			ariaHideApp={false}
			style={{
				overlay: MODAL_STYLE.overlay,
			}}>
			<div className='modal__head'>
				<div className='modal__title'>{modals.shareBet.title}</div>

				<Button className='modal__close' onClick={() => toggleShareBetModal(false)}>
					close
				</Button>
			</div>
			<div className='modal__body'>
				<div className='modal__axis-y'>
					<div className='share-bet'>
						<Preloader visible={visiblePreloader} />
						{!bets[0].length ? (
							<div className='share-bet__stub'>
								<div className='share-bet__stub-pic'> </div>
								<div className='share-bet__stub-content'>
									<p className='share-bet__stub-title'>{modals.shareBet.noBetsTitle}</p>
									<p className='share-bet__stub-text'>{modals.shareBet.noBetsText}</p>
								</div>
							</div>
						) : (
							<div className='share-bet__axis-y' onScroll={onScrollHandler} ref={userBetsScrollRef}>
								<div className='share-bet__list' ref={userBetsRef}>
									{bets.map(item => item.map(renderUserBet))}
								</div>

								<Preloader visible={visiblePreloaderBottom} />
							</div>
						)}
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default observer(ShareBetModal);
