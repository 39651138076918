import {action, makeObservable, observable} from 'mobx';

class SuggestServices {
	@observable
	public visibleSuggest = false;

	@action
	public toggleVisibleSuggest = (value: boolean) => {
		this.visibleSuggest = value;
	};

	constructor() {
		makeObservable(this);
	}
}

export default new SuggestServices();
