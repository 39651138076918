enum SocketEvents {
	Connect = 'connect',
	Connected = 'connected',
	ConnectError = 'connect_error',
	Error = 'error',
	Disconnect = 'disconnect',
	Message = 'message',
	MessageNotSent = 'messageNotSent',
	EditMessage = 'editMessage',
	MessageEdited = 'messageEdited',
	DeleteMessage = 'deleteMessage',
	MessageDeleted = 'messageDeleted',
	MessagesDeleted = 'messagesDeleted',
	SetMessagesVisible = 'setMessagesVisible',
	SetMessageVisible = 'setMessageVisible',
	MessagesVisibleSet = 'messagesVisibleSet',
	MessageVisibleSet = 'messageVisibleSet',
	Join = 'join',
	Leave = 'leave',
	UserJoined = 'userJoined',
	SuccessfullyJoined = 'successfullyJoined',
	TalkerBanSet = 'talkerBanSet',
	SetBan = 'setBan',
	UserLeft = 'userLeft',
	CouldNotJoin = 'couldNotJoin',
	UserJoinedAgora = 'userJoinedAgora',
	TalkerModerSet = 'talkerModerSet',
	RoleSet = 'roleSet',
	SetRole = 'setRole',
	ToggleHand = 'toggleHand',
	HandToggled = 'handToggled',
	SetMute = 'setMute',
	MuteSet = 'muteSet',
	UserBanSet = 'userBanSet',
	Emotion = 'emotion',
	RoomSpeakSet = 'roomSpeakSet',
	RoomStatusSet = 'roomStatusSet',
	Stat = 'stat',
	RejectHand = 'rejectHand',
	UserUpdated = 'userUpdated',
	MessagePinned = 'messagePinned',
	PinMessage = 'pinMessage',
	PollCreated = 'pollCreated',
	PollUpdated = 'pollUpdated',
	PollDeleted = 'pollDeleted',
	PollEnded = 'pollEnded',
	VoteCreated = 'voteCreated',
	BlockSet = 'blockSet',
	CreateReaction = 'createReaction',
	DeleteReaction = 'deleteReaction',
	ReactionCreated = 'reactionCreated',
	ReactionDeleted = 'reactionDeleted',
	SetSlowmode = 'setSlowmode',
	RoomSlowmodeSet = 'roomSlowmodeSet',
	RoomSlowmodeDelaySet = 'roomSlowmodeDelaySet',
	SettingsUpdated = 'settingsUpdated',
	ShareBet = 'shareBet',
}

export default SocketEvents;
