/* eslint-disable max-lines */
import UserRole from 'models/enums/UserRole.enum';
import SocketEvents from 'models/enums/SocketEvents.enum';
import {BanReason} from 'models/enums/BanModerReasons.enum';
import {
	TalkerBanSetted,
	CouldNotJoin,
	GetMessageListener,
	HandToggledListener,
	MessageNotSentListener,
	MessageEditedListener,
	MessageDeletedListener,
	MessagesDeletedListener,
	MessagesVisibleSetListener,
	MessageVisibleSetListener,
	OnRoleSet,
	OnTalkerModerSet,
	ReciveAgoraCreds,
	UserJoinedListener,
	UserLeftListener,
	UserBanSetListener,
	EmotionListener,
	RoomSpeakListener,
	RoomStatusListener,
	MuteSetListener,
	UserUpdated,
	MessagePinned,
	PollListener,
	PollEndedListener,
	VoteCreated,
	BlockSetListener,
	SuccessfullyJoinedListener,
	ReactionListener,
	RoomSlowmodeListener,
	SettingsUpdatedListener,
} from 'models/socketio';
import {AnalyticsProps, EmitMessage} from 'models/room';
import socketIOClient, {Socket} from 'socket.io-client';
import Log from 'utils/log';

type SocketSubscribeEvents = () => void;
type SocketUnSubscribeEvents = () => void;

export default class SocketIoServices {
	static socket: Socket | null = null;

	static init = async (
		token: string,
		socketSubscribeEvents?: SocketSubscribeEvents,
		socketUnSubscribeEvents?: SocketUnSubscribeEvents
	) => {
		try {
			this.socket = socketIOClient(`${process.env.REACT_APP_API_URL}`, {
				auth: {
					token,
				},
				transports: ['websocket'],
				upgrade: false,
			});

			this.listenServerEvents(socketSubscribeEvents, socketUnSubscribeEvents);
		} catch (error) {
			Log.error('Init socket.io: ', error);
		}
	};

	static listenServerEvents = (
		socketSubscribeEvents?: SocketSubscribeEvents,
		socketUnSubscribeEvents?: SocketUnSubscribeEvents
	) => {
		this.socket?.on(SocketEvents.Connect, () => {
			if (socketSubscribeEvents) {
				socketSubscribeEvents();
			}
			Log.debug(`socket.io -> socketid: ${this.socket?.id} -> connect - success`);
		});

		this.socket?.on(SocketEvents.Connected, data => {
			Log.debug(`socket.io -> socketid: ${this.socket?.id} -> connected: `, data);
		});

		this.socket?.on(SocketEvents.ConnectError, error => {
			Log.error(`socket.io -> socketid: ${this.socket?.id} -> connected - error: `, error);
			if (socketUnSubscribeEvents) {
				socketUnSubscribeEvents();
			}
		});

		this.socket?.on(SocketEvents.Error, error => {
			Log.error('socket.io -> error', error);
			if (socketUnSubscribeEvents) {
				socketUnSubscribeEvents();
			}
		});

		this.socket?.on(SocketEvents.Disconnect, () => {
			Log.log('socket.io -> disconnect');
			if (socketUnSubscribeEvents) {
				socketUnSubscribeEvents();
			}
		});
	};

	/* -----------------  ON ------------------ */

	static onUserJoined = (listener: UserJoinedListener) => {
		this.socket?.on(SocketEvents.UserJoined, listener);
	};

	static onUserLeft = (listener: UserLeftListener) => {
		this.socket?.on(SocketEvents.UserLeft, listener);
	};

	static onGetMessage = (listener: GetMessageListener) => {
		this.socket?.on(SocketEvents.Message, listener);
	};

	static onMessageNotSent = (listener: MessageNotSentListener) => {
		this.socket?.on(SocketEvents.MessageNotSent, listener);
	};

	static onMessageEdited = (listener: MessageEditedListener) => {
		this.socket?.on(SocketEvents.MessageEdited, listener);
	};

	static onMessageDeleted = (listener: MessageDeletedListener) => {
		this.socket?.on(SocketEvents.MessageDeleted, listener);
	};

	static onMessagesDeleted = (listener: MessagesDeletedListener) => {
		this.socket?.on(SocketEvents.MessagesDeleted, listener);
	};

	static onMessagesVisibleSet = (listener: MessagesVisibleSetListener) => {
		this.socket?.on(SocketEvents.MessagesVisibleSet, listener);
	};

	static onMessageVisibleSet = (listener: MessageVisibleSetListener) => {
		this.socket?.on(SocketEvents.MessageVisibleSet, listener);
	};

	static onTalkerSetBan = (listener: TalkerBanSetted) => {
		this.socket?.on(SocketEvents.TalkerBanSet, listener);
	};

	static onCouldNotJoin = (listener: CouldNotJoin) => {
		this.socket?.on(SocketEvents.CouldNotJoin, listener);
	};

	static reciveAgoraCreds = (listener: ReciveAgoraCreds) => {
		this.socket?.on(SocketEvents.UserJoinedAgora, listener);
	};

	static onChangeRole = (listener: OnRoleSet) => {
		this.socket?.on(SocketEvents.RoleSet, listener);
	};

	static onChangeModer = (listener: OnTalkerModerSet) => {
		this.socket?.on(SocketEvents.TalkerModerSet, listener);
	};

	static onHandToggled = (listener: HandToggledListener) => {
		this.socket?.on(SocketEvents.HandToggled, listener);
	};

	static onUserBanSet = (listener: UserBanSetListener) => {
		this.socket?.on(SocketEvents.UserBanSet, listener);
	};

	static onEmotion = (listener: EmotionListener) => {
		this.socket?.on(SocketEvents.Emotion, listener);
	};

	static onRoomSpeakSet = (listener: RoomSpeakListener) => {
		this.socket?.on(SocketEvents.RoomSpeakSet, listener);
	};

	static onRoomStatusSet = (listener: RoomStatusListener) => {
		this.socket?.on(SocketEvents.RoomStatusSet, listener);
	};

	static onMuteSet = (listener: MuteSetListener) => {
		this.socket?.on(SocketEvents.MuteSet, listener);
	};

	static onUserUpdated = (listener: UserUpdated) => {
		this.socket?.on(SocketEvents.UserUpdated, listener);
	};

	static onMessagePinned = (listener: MessagePinned) => {
		this.socket?.on(SocketEvents.MessagePinned, listener);
	};

	static onPollCreated = (listener: PollListener) => {
		this.socket?.on(SocketEvents.PollCreated, listener);
	};

	static onPollUpdated = (listener: PollListener) => {
		this.socket?.on(SocketEvents.PollUpdated, listener);
	};

	static onPollDeleted = (listener: PollListener) => {
		this.socket?.on(SocketEvents.PollDeleted, listener);
	};

	static onPollEnded = (listener: PollEndedListener) => {
		this.socket?.on(SocketEvents.PollEnded, listener);
	};

	static onVoteCreated = (listener: VoteCreated) => {
		this.socket?.on(SocketEvents.VoteCreated, listener);
	};

	static onBlockSet = (listener: BlockSetListener) => {
		this.socket?.on(SocketEvents.BlockSet, listener);
	};

	static onSuccessfullyJoined = (listener: SuccessfullyJoinedListener) => {
		this.socket?.on(SocketEvents.SuccessfullyJoined, listener);
	};

	static onReactionCreated = (listener: ReactionListener) => {
		this.socket?.on(SocketEvents.ReactionCreated, listener);
	};

	static onReactionDeleted = (listener: ReactionListener) => {
		this.socket?.on(SocketEvents.ReactionDeleted, listener);
	};

	static onRoomSlowmodeSet = (listener: RoomSlowmodeListener) => {
		this.socket?.on(SocketEvents.RoomSlowmodeSet, listener);
	};

	static onRoomSlowmodeDelaySet = (listener: RoomSlowmodeListener) => {
		this.socket?.on(SocketEvents.RoomSlowmodeDelaySet, listener);
	};

	static onSettingsUpdated = (listener: SettingsUpdatedListener) => {
		this.socket?.on(SocketEvents.SettingsUpdated, listener);
	};

	/* -----------------  END - ON ------------------ */

	/* -----------------  OFF  ------------------ */

	static offUserJoined = (listener: UserJoinedListener) => {
		this.socket?.off(SocketEvents.UserJoined, listener);
	};

	static offUserLeft = (listener: UserLeftListener) => {
		this.socket?.off(SocketEvents.UserLeft, listener);
	};

	static offGetMessage = (listener: GetMessageListener) => {
		this.socket?.off(SocketEvents.Message, listener);
	};

	static offMessageNotSent = (listener: MessageNotSentListener) => {
		this.socket?.off(SocketEvents.MessageNotSent, listener);
	};

	static offMessageEdited = (listener: MessageEditedListener) => {
		this.socket?.off(SocketEvents.MessageEdited, listener);
	};

	static offMessageDeleted = (listener: MessageDeletedListener) => {
		this.socket?.off(SocketEvents.MessageDeleted, listener);
	};

	static offMessagesDeleted = (listener: MessagesDeletedListener) => {
		this.socket?.off(SocketEvents.MessagesDeleted, listener);
	};

	static offMessagesVisibleSet = (listener: MessagesVisibleSetListener) => {
		this.socket?.off(SocketEvents.MessagesVisibleSet, listener);
	};

	static offMessageVisibleSet = (listener: MessageVisibleSetListener) => {
		this.socket?.off(SocketEvents.MessageVisibleSet, listener);
	};

	static offTalkerSetBan = (listener: TalkerBanSetted) => {
		this.socket?.off(SocketEvents.TalkerBanSet, listener);
	};

	static offCouldNotJoin = (listener: CouldNotJoin) => {
		this.socket?.off(SocketEvents.CouldNotJoin, listener);
	};

	static offReciveAgoraCreds = (listener: ReciveAgoraCreds) => {
		this.socket?.off(SocketEvents.UserJoinedAgora, listener);
	};

	static offChangeRole = (listener: OnRoleSet) => {
		this.socket?.off(SocketEvents.RoleSet, listener);
	};

	static offChangeModer = (listener: OnTalkerModerSet) => {
		this.socket?.off(SocketEvents.TalkerModerSet, listener);
	};

	static offHandToggled = (listener: HandToggledListener) => {
		this.socket?.off(SocketEvents.HandToggled, listener);
	};

	static offUserBanSet = (listener: UserBanSetListener) => {
		this.socket?.off(SocketEvents.UserBanSet, listener);
	};

	static offEmotion = (listener: EmotionListener) => {
		this.socket?.off(SocketEvents.Emotion, listener);
	};

	static offRoomSpeakSet = (listener: RoomSpeakListener) => {
		this.socket?.off(SocketEvents.RoomSpeakSet, listener);
	};

	static offRoomStatusSet = (listener: RoomStatusListener) => {
		this.socket?.off(SocketEvents.RoomStatusSet, listener);
	};

	static offMuteSet = (listener: MuteSetListener) => {
		this.socket?.off(SocketEvents.MuteSet, listener);
	};

	static offUserUpdated = (listener: UserUpdated) => {
		this.socket?.off(SocketEvents.UserUpdated, listener);
	};

	static offMessagePinned = (listener: MessagePinned) => {
		this.socket?.off(SocketEvents.MessagePinned, listener);
	};

	static offPollCreated = (listener: PollListener) => {
		this.socket?.off(SocketEvents.PollCreated, listener);
	};

	static offPollUpdated = (listener: PollListener) => {
		this.socket?.off(SocketEvents.PollUpdated, listener);
	};

	static offPollDeleted = (listener: PollListener) => {
		this.socket?.off(SocketEvents.PollDeleted, listener);
	};

	static offPollEnded = (listener: PollEndedListener) => {
		this.socket?.off(SocketEvents.PollEnded, listener);
	};

	static offVoteCreated = (listener: VoteCreated) => {
		this.socket?.off(SocketEvents.VoteCreated, listener);
	};

	static offBlockSet = (listener: BlockSetListener) => {
		this.socket?.off(SocketEvents.BlockSet, listener);
	};

	static offSuccessfullyJoined = (listener: SuccessfullyJoinedListener) => {
		this.socket?.off(SocketEvents.SuccessfullyJoined, listener);
	};

	static offReactionCreated = (listener: ReactionListener) => {
		this.socket?.off(SocketEvents.ReactionCreated, listener);
	};

	static offReactionDeleted = (listener: ReactionListener) => {
		this.socket?.off(SocketEvents.ReactionDeleted, listener);
	};

	static offRoomSlowmodeSet = (listener: RoomSlowmodeListener) => {
		this.socket?.off(SocketEvents.RoomSlowmodeSet, listener);
	};

	static offRoomSlowmodeDelaySet = (listener: RoomSlowmodeListener) => {
		this.socket?.off(SocketEvents.RoomSlowmodeDelaySet, listener);
	};

	static offSettingsUpdated = (listener: SettingsUpdatedListener) => {
		this.socket?.off(SocketEvents.SettingsUpdated, listener);
	};

	/* -----------------  END - OFF ------------------ */

	/* -----------------  EMIT ------------------ */

	static emitJoin = (externalRoomId: string, roomName?: string) => {
		const emitObj: {externalRoomId: string; roomName?: string} = {externalRoomId};
		if (roomName) emitObj.roomName = roomName;
		this.socket?.emit(SocketEvents.Join, emitObj);
	};

	static emitLeave = (externalRoomId: string) => {
		this.socket?.emit(SocketEvents.Leave, {externalRoomId});
	};

	static emitMessage = (data: EmitMessage) => {
		const {text, pollText, externalRoomId, mentionMessageId, stickerId, hasPreview, type, bet} =
			data;
		const emitObj: EmitMessage = {
			externalRoomId,
		};
		if (text) {
			emitObj.text = text;
		}
		if (pollText) {
			emitObj.pollText = pollText;
		}
		if (mentionMessageId) {
			emitObj.mentionMessageId = mentionMessageId;
		}
		if (stickerId) {
			emitObj.stickerId = stickerId;
		}
		if (typeof hasPreview !== 'undefined') {
			emitObj.hasPreview = hasPreview;
		}
		if (typeof type !== 'undefined') {
			emitObj.type = type;
		}
		if (bet) emitObj.bet = bet;

		this.socket?.emit(SocketEvents.Message, emitObj);
	};

	static emitEditMessage = (data: {
		messageId: number;
		text: string;
		externalRoomId: string;
		hasPreview: boolean;
	}) => {
		const {messageId, text, externalRoomId, hasPreview} = data;
		this.socket?.emit(SocketEvents.EditMessage, {messageId, text, externalRoomId, hasPreview});
	};

	static emitDeleteMessage = (messageId: number, externalRoomId: string) => {
		this.socket?.emit(SocketEvents.DeleteMessage, {
			messageId,
			externalRoomId,
		});
	};

	static emitSetMessagesVisible = (userId: number, externalRoomId: string, isVisible: boolean) => {
		this.socket?.emit(SocketEvents.SetMessagesVisible, {
			userId,
			externalRoomId,
			isVisible,
		});
	};

	static emitSetMessageVisible = (
		messageId: number,
		externalRoomId: string,
		isVisible: boolean
	) => {
		this.socket?.emit(SocketEvents.SetMessageVisible, {
			messageId,
			externalRoomId,
			isVisible,
		});
	};

	static setBan = (
		userId: number,
		externalRoomId: string,
		isBanned: boolean,
		reason?: BanReason
	) => {
		this.socket?.emit(SocketEvents.SetBan, {
			userId,
			externalRoomId,
			isBanned,
			reason,
		});
	};

	static emitToggleHand = (externalRoomId: string) => {
		this.socket?.emit(SocketEvents.ToggleHand, {externalRoomId});
	};

	static emitSetRole = (userId: number, externalRoomId: string, role: UserRole) => {
		this.socket?.emit(SocketEvents.SetRole, {
			userId,
			externalRoomId,
			role,
		});
	};

	static emitEmotion = (emotion: {name: string}, externalRoomId: string) => {
		this.socket?.emit(SocketEvents.Emotion, {
			emotion,
			externalRoomId,
		});
	};

	static emitMute = (externalRoomId: string, isMuted: boolean, userId?: number) => {
		this.socket?.emit(SocketEvents.SetMute, {
			userId,
			externalRoomId,
			isMuted,
		});
	};

	static emitAnalytics = (emitAnalyticsData: AnalyticsProps) => {
		this.socket?.emit(SocketEvents.Stat, {...emitAnalyticsData});
	};

	static emitRejectHand = (externalRoomId: string, talkerId: number) => {
		this.socket?.emit(SocketEvents.RejectHand, {externalRoomId, talkerId});
	};

	static emitPinMessage = (externalRoomId: string, messageId: number | null) => {
		this.socket?.emit(SocketEvents.PinMessage, {externalRoomId, messageId});
	};

	static emitCreateReaction = (data: {
		emotion: string;
		externalRoomId: string;
		messageId: number;
	}) => {
		const {emotion, externalRoomId, messageId} = data;
		this.socket?.emit(SocketEvents.CreateReaction, {
			emotion,
			externalRoomId,
			messageId,
		});
	};

	static emitDeleteReaction = (data: {
		emotion: string;
		externalRoomId: string;
		messageId: number;
	}) => {
		const {emotion, externalRoomId, messageId} = data;
		this.socket?.emit(SocketEvents.DeleteReaction, {
			emotion,
			externalRoomId,
			messageId,
		});
	};

	static emitSlowMode = (data: {
		externalRoomId: string;
		isSlowmode: boolean;
		slowmodeDelayMS: number;
	}) => {
		const {externalRoomId, isSlowmode, slowmodeDelayMS} = data;
		this.socket?.emit(SocketEvents.SetSlowmode, {
			externalRoomId,
			isSlowmode,
			slowmodeDelayMS,
		});
	};

	static emitShareBet = (data: EmitMessage) => {
		const {externalRoomId, bet} = data;
		const obj: EmitMessage = {
			bet,
			externalRoomId,
		};

		this.socket?.emit(SocketEvents.ShareBet, obj);
	};

	/* -----------------  End - EMIT ------------------ */

	static soketDisconnect = () => {
		if (this.socket !== null) {
			this.socket?.disconnect();
			this.socket = null;
		}
	};
}
