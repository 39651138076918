import {Message, MessageAdvertisement} from 'models/room';
import appService from 'store/appService';
import userServices from 'store/userServices';
import roomServices from 'store/roomServices';
import useAnalytics from 'hooks/useAnalytics';
import RoomService from 'services/api/RoomService';
import {useEffect, useRef} from 'react';
import {useLocalObservable} from 'mobx-react-lite';

type AdvertisementItem = {
	roomId: string;
	items: number[];
};

const MAX_SIZE_LS_VIEWED_ADVERTISMENTS_KB = 2000; // 2000Kb (2Mb)

const useAdvertisement = () => {
	const isInternernetActiveRef = useRef(false);
	const {isInternernetActive} = useLocalObservable(() => appService);
	const {accessToken} = useLocalObservable(() => userServices);
	const {roomId} = useLocalObservable(() => roomServices);
	const {sendAnalytics} = useAnalytics();

	useEffect(() => {
		isInternernetActiveRef.current = isInternernetActive;
	}, [isInternernetActive]);

	const sendViewAdvertisementViaAPI = async (advertisement: MessageAdvertisement) => {
		const {id, title, text} = advertisement;
		if (accessToken) {
			await RoomService.sendViewAdvertisement(id, accessToken);
			sendAnalytics('hl_viewed', {hl_content: title || text});
		}
	};

	const localStorageSetViewedAdvertisements = (data: any) => {
		localStorage.setItem('watcherswebViewedAdvertisements', JSON.stringify(data));
	};

	const localStorageGetViewedAdvertisements = () => {
		return localStorage.getItem('watcherswebViewedAdvertisements');
	};

	const localStorageClearViewedAdvertisements = () => {
		const LSViewedAdvertisements = localStorageGetViewedAdvertisements();
		let LSViewedAdvertisementsLength = 0;
		let LSViewedAdvertisementsSize = 0;

		if (LSViewedAdvertisements) {
			LSViewedAdvertisementsLength = localStorage.watcherswebViewedAdvertisements.length * 2;
		}

		LSViewedAdvertisementsSize = +(LSViewedAdvertisementsLength / 1024).toFixed(2); // kb

		if (LSViewedAdvertisementsSize > MAX_SIZE_LS_VIEWED_ADVERTISMENTS_KB) {
			localStorageSetViewedAdvertisements([]);
		}
	};

	const checkAdvertisementInViewport = (
		viewedAdvertisementsItemsId: number[],
		advertisementMessages: Message[],
		chatClientHeight: number
	) => {
		const tempIdItems: number[] = [];

		advertisementMessages.forEach(({id, advertisement}) => {
			const selectedMessage = document.querySelector(`.chat__message[data-id="${id}"]`);
			if (selectedMessage && selectedMessage instanceof HTMLElement) {
				const selectedMessageRect = selectedMessage.getBoundingClientRect();
				const selectedMessageBorder = selectedMessageRect.top + selectedMessageRect.height / 2;
				if (
					advertisement &&
					!viewedAdvertisementsItemsId.includes(advertisement.id) &&
					selectedMessageBorder >= 0 &&
					selectedMessageBorder <= chatClientHeight
				) {
					tempIdItems.push(advertisement.id);
					sendViewAdvertisementViaAPI(advertisement);
				}
			}
		});

		return tempIdItems;
	};

	const checkViewedAdvertisement = (advertisementMessages: Message[], chatClientHeight: number) => {
		if (roomId && isInternernetActiveRef.current) {
			const LSViewedAdvertisements = localStorageGetViewedAdvertisements();
			const LSViewedAdvertisementsParse: AdvertisementItem[] = LSViewedAdvertisements
				? JSON.parse(LSViewedAdvertisements)
				: [];
			const findRoom = LSViewedAdvertisementsParse.find(
				(item: AdvertisementItem) => item.roomId === roomId
			);

			let LSViewedAdvertisementsData: AdvertisementItem[] = [];
			let advertisementIdItems: number[] = [];

			const commonParams: [advertisementMessages: Message[], chatClientHeight: number] = [
				advertisementMessages,
				chatClientHeight,
			];

			if (findRoom) {
				advertisementIdItems = checkAdvertisementInViewport(findRoom.items, ...commonParams);

				if (advertisementIdItems.length) {
					LSViewedAdvertisementsData = LSViewedAdvertisementsParse.map(el => {
						if (el.roomId === roomId) {
							return {...el, items: [...el.items, ...advertisementIdItems]};
						}
						return el;
					});

					localStorageSetViewedAdvertisements(LSViewedAdvertisementsData);
				}

				return;
			}

			advertisementIdItems = checkAdvertisementInViewport([], ...commonParams);

			if (advertisementIdItems.length) {
				LSViewedAdvertisementsData = [
					...LSViewedAdvertisementsParse,
					{roomId, items: advertisementIdItems},
				];

				localStorageSetViewedAdvertisements(LSViewedAdvertisementsData);
			}
		}
	};

	return {checkViewedAdvertisement, localStorageClearViewedAdvertisements};
};

export default useAdvertisement;
