import useL10n from 'l10n/useL10n';
import useAnalytics from 'hooks/useAnalytics';
import {FunctionComponent, useEffect} from 'react';
import {observer} from 'mobx-react-lite';

const AudioDone: FunctionComponent = function AudioDone() {
	const {feedback} = useL10n();
	const {sendAnalytics} = useAnalytics();

	useEffect(() => {
		sendAnalytics('ff_stream_success');
	}, []);

	return (
		<div className='audio__done'>
			<div className='audio__title'>{feedback.audio.done.title}</div>
			<div className='audio__text'>{feedback.audio.done.text}</div>
		</div>
	);
};

export default observer(AudioDone);
