import showErrorCritical from 'utils/showErrorCritical';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import * as amplitude from '@amplitude/analytics-browser';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import {Error} from 'pages/error';
import App from 'components/app/index';

const rootNode = document.getElementById('root');

const isLocalStorageAvailable = () => {
	const test = 'test';
	try {
		localStorage.setItem(test, test);
		localStorage.removeItem(test);
		return true;
	} catch (e) {
		return false;
	}
};

if (process.env.REACT_APP_SENTRY_DSN && process.env.REACT_APP_PROJECT) {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		environment: process.env.REACT_APP_PROJECT,
		integrations: [
			new BrowserTracing({
				heartbeatInterval: 1000,
				startTransactionOnPageLoad: false,
			}),
		],
		tracesSampleRate: 0.6,
	});
}

if (isLocalStorageAvailable()) {
	try {
		if (process.env.REACT_APP_AMPLITUDE_API_KEY) {
			amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY);
		}
		ReactDOM.render(
			<BrowserRouter>
				<DndProvider backend={HTML5Backend}>
					<App />
				</DndProvider>
			</BrowserRouter>,
			rootNode
		);
	} catch (e: any) {
		showErrorCritical(e);
	}
} else {
	ReactDOM.render(
		<BrowserRouter>
			<Error errorNumber={3} />
		</BrowserRouter>,
		rootNode
	);
}
