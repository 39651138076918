import {Message} from 'models/room';
import roomServices from 'store/roomServices';
import useL10n from 'l10n/useL10n';
import useSubmenuActions from 'hooks/useSubmenuActions';
import {FunctionComponent} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Button from 'components/hoc/Button';
import {ReactComponent as BusketIcon} from 'assets/svg/ico-submenu-busket.svg';

interface IButtonRemoveProps {
	message: Message;
}

const ButtonRemove: FunctionComponent<IButtonRemoveProps> = function ButtonRemove({message}) {
	const {chatScreen} = useL10n();
	const {roomId} = useLocalObservable(() => roomServices);
	const {removeMessage} = useSubmenuActions();

	const onRemoveHandler = () => {
		if (roomId) {
			removeMessage(message, roomId);
		}
	};

	return (
		<Button className='chat__submenu-btn chat__submenu-btn--warn' onClick={onRemoveHandler}>
			{chatScreen.chatSubmenu.delete}
			<BusketIcon className='chat__submenu-icon' />
		</Button>
	);
};

export default observer(ButtonRemove);
