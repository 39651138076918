import {FunctionComponent} from 'react';
import LoadingSkeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './linkPreviewSceleton.scss';

interface ILinkPreviewSceletonProps {
	imageHeight?: string | number;
	margin?: string | number;
}

const LinkPreviewSceleton: FunctionComponent<ILinkPreviewSceletonProps> =
	function LinkPreviewSceleton({imageHeight = '166px', margin}) {
		return (
			<div className='link-preview-sceleton' style={{margin}}>
				<div className='link-preview-sceleton__container'>
					<LoadingSkeleton count={3} />
				</div>
				<LoadingSkeleton height={imageHeight} />
			</div>
		);
	};

export default LinkPreviewSceleton;
