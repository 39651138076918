import {MODAL_STYLE} from 'constants/constants';
import modalServices from 'store/modalServices';
import {FunctionComponent, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Modal from 'react-modal';
import classNames from 'classnames';
import {Recording} from 'components/recording';
import Button from 'components/hoc/Button';

const RecordingModal: FunctionComponent = function RecordingModal() {
	const {recordingModalVisible} = useLocalObservable(() => modalServices);

	const [isViewSmall, setIsViewSmall] = useState(false);

	const modalClasses = classNames('modal modal--record', {
		'modal--record-min': isViewSmall,
	});

	const onToggleViewModalHandler = () => {
		setIsViewSmall(!isViewSmall);
	};

	return (
		<Modal
			className={modalClasses}
			overlayClassName='ReactModal__Overlay--min'
			isOpen={recordingModalVisible}
			ariaHideApp={false}
			style={{
				overlay: MODAL_STYLE.overlay,
			}}>
			<Button className='modal__toggle-view' onClick={onToggleViewModalHandler} />
			<Recording />
		</Modal>
	);
};

export default observer(RecordingModal);
