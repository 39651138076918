/* eslint-disable no-console */

/**
 * based: https://github.com/Dhaiwat10/react-link-preview
 */

import log from 'utils/log';
import {ILinkPreviewResponse} from 'models/room';
import {LINK_PREVIEW_PROXY} from 'constants/constants';
import {useEffect, useRef, useState} from 'react';
import axios from 'axios';

const useLinkPreview = (incomingUrl?: string) => {
	const _isMounted = useRef(true);
	const abortControllerRef = useRef<AbortController>(new AbortController());
	const [loading, setLoading] = useState(true);
	const [metadata, setMetadata] = useState<ILinkPreviewResponse | null>();

	const resetLocalState = () => {
		if (_isMounted.current) {
			setMetadata(null);
			setLoading(false);
		}
	};

	const getMetadata = async (url: string) => {
		try {
			const response = await axios.get(`${LINK_PREVIEW_PROXY}${url}`, {
				signal: abortControllerRef.current.signal,
			});
			if (response?.data?.metadata) {
				return response.data.metadata;
			}
			return null;
		} catch (error) {
			log.error('Link preview get metadata for writing message: ', error);
		}
		return null;
	};

	const abortGetMetadata = () => {
		abortControllerRef.current.abort();
		abortControllerRef.current = new AbortController();
	};

	useEffect(() => {
		_isMounted.current = true;
		setLoading(true);

		(async () => {
			try {
				if (incomingUrl) {
					const response = await axios.get(`${LINK_PREVIEW_PROXY}${incomingUrl}`);
					if (response?.data?.metadata) {
						if (_isMounted.current) {
							setMetadata(response.data.metadata as unknown as ILinkPreviewResponse);
							setLoading(false);
							return;
						}
					}
				}
				resetLocalState();
			} catch (error) {
				log.error('Link preview get metadata for chat: ', error);
				resetLocalState();
			}
		})();

		return () => {
			_isMounted.current = false;
		};
	}, [incomingUrl]);

	return {
		_isMounted,
		loading,
		metadata,
		getMetadata,
		abortGetMetadata,
	};
};

export default useLinkPreview;
