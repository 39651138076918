/* eslint-disable max-lines */
import _ from 'lodash';
import {containsClass, getParent} from 'utils/helpers';
import {encryptionUserName} from 'utils/encryptionUserName';
import {AppScreens} from 'models/enums/AppScreens.enum';
import LanguageTag from 'models/enums/LanguageTag.enum';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import UserVipStatus from 'models/enums/UserVipStatus.enum';
import {BanReason} from 'models/enums/BanModerReasons.enum';
import useAnalytics from 'hooks/useAnalytics';
import useAdvertisement from 'hooks/useAdvertisement';
import useAntimat from 'hooks/useAntimat';
import useBanUsers from 'hooks/useBanUsers';
import OauthService from 'services/api/OauthService';
import SocketIoServices from 'services/SocketIoServices';
import appService from 'store/appService';
import userServices from 'store/userServices';
import roomServices from 'store/roomServices';
import emotionServices from 'store/emotionServices';
import pollServices from 'store/pollServices';
import modalServices from 'store/modalServices';
import {useCallback, useEffect, useState} from 'react';
import {Switch, Route, useLocation, useHistory} from 'react-router-dom';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {isMobile} from 'react-device-detect';
import * as amplitude from '@amplitude/analytics-browser';
import RoomService from 'services/api/RoomService';
import Error from 'pages/error/Error';
import Restore from 'pages/restore/Restore';
import Main from 'pages/main/Main';

import {Preloader} from 'components/preloader';
import {Header} from 'components/header';
import ToastList from 'components/toasts/ToastList';
import Alert from 'components/alert/Alert';
import Button from 'components/hoc/Button';

import './app.scss';
import UserService from 'services/api/UserService';
import {RegisterResponseData} from 'models/responses';
import {RegisterRequest} from 'models/requests';

const HTMLNode = document.querySelector('html');

const App = function App() {
	const {search} = useLocation();
	const history = useHistory();
	const {sendAnalytics} = useAnalytics();
	const {localStorageClearViewedAdvertisements} = useAdvertisement();
	const {containsMat} = useAntimat();

	const [visiblePreloader, setVisiblePreloader] = useState(true);
	const [eventSessionStartedSent, setEventSessionStartedSent] = useState(false);
	const [eventRoomOpenedSent, setEventRoomOpenedSent] = useState(false);
	const watcherswebUser = localStorage.getItem('watcherswebUser');
	const watcherswebRoomId = localStorage.getItem('watcherswebRoomId');
	const watcherswebLanguage = localStorage.getItem('watcherswebLanguage');

	const {
		setAccessToken,
		setUserData,
		userId: storeUserId,
		setUserId,
		setUserModer,
		updateUserData,
	} = useLocalObservable(() => userServices);
	const {
		roomId: storeRoomId,
		setRoomId,
		setRoomName,
		submenuMessage,
		setSubmenuMessage,
		restApiDataLoaded,
	} = useLocalObservable(() => roomServices);
	const {
		toggleAppReadOnly,
		language,
		isInternernetActive,
		deletedState,
		changeDeleteState,
		currentScreen,
		setLanguage,
	} = useLocalObservable(() => appService);
	const {emotionsVisible, toggleEmotionsVisible} = useLocalObservable(() => emotionServices);
	const {pollTooltipVisible, toggllePollTooltipVisible} = useLocalObservable(() => pollServices);
	const {toggleFeedbackAudioModal} = useLocalObservable(() => modalServices);
	const {showAlertForBanReason} = useBanUsers();

	const roomNameFromUrl = new URLSearchParams(search).get('title');
	const langFromUrl = new URLSearchParams(search).get('lang');
	const colorSchemeMode = new URLSearchParams(search).get('mode');
	let userId = new URLSearchParams(search).get('userId');
	let roomId = new URLSearchParams(search).get('roomId');
	const moder = new URLSearchParams(search).get('moder');
	const status = new URLSearchParams(search).get('statusname');
	let nickname = new URLSearchParams(search).get('nickname');
	const picId = new URLSearchParams(search).get('imageId');
	let pic = new URLSearchParams(search).get('image');
	const readonlyFromUrl = new URLSearchParams(search).get('readonly');

	const APP_OPTIONS = process.env.REACT_APP_AUTH;
	const APP_OPTIONS_PARSE = APP_OPTIONS && JSON.parse(String(APP_OPTIONS).replace(/\\/g, ''));

	const onCloseHandler = () => {
		// toggleFeedbackAudioModal(true);
		window.parent.postMessage({type: 'watchersWindowClose'}, '*');
	};

	const sendPostMessage = useCallback(
		_.debounce(cs => {
			window.parent.postMessage({type: 'changeScreen', body: cs}, '*');
		}, 500),
		[]
	);

	const onChangeColorScheme = (event: MediaQueryListEvent) => {
		const newColorScheme = event.matches ? 'dark' : 'light';
		HTMLNode?.setAttribute('mode', newColorScheme);
	};

	const saveExternalRoomId = (externalRoomId: string) => {
		setRoomId(externalRoomId);
		localStorage.setItem('watcherswebRoomId', externalRoomId);
	};

	const onClickDocumentHandler = (event: any) => {
		const eventTarget = event.target;
		if (!getParent(eventTarget, 'chat__submenu') && submenuMessage) {
			setSubmenuMessage(null);
		}

		if (
			!containsClass(eventTarget, 'emotions__item') &&
			!containsClass(eventTarget, 'control-panel__btn--emotions') &&
			emotionsVisible
		) {
			toggleEmotionsVisible(false);
		}

		if (!getParent(eventTarget, 'poll-toggle') && pollTooltipVisible) {
			toggllePollTooltipVisible(false);
		}
	};

	const onGesturestarthandler = (event: any) => {
		event.preventDefault();
	};

	useEffect(() => {
		currentScreen !== null && sendPostMessage(currentScreen);

		window.onmessage = e => {
			if (e.data.type === 'getCurrentScreen') {
				window.parent.postMessage({type: 'getCurrentScreen', body: currentScreen}, '*');
			}

			if (e.data.type === 'getCurrentUserStatus') {
				if (
					currentScreen === AppScreens.INTRO ||
					currentScreen === AppScreens.SETNAME ||
					currentScreen === AppScreens.SETAVATAR
				) {
					window.parent.postMessage({type: 'userStatusChanged', body: 'userUnregistered'}, '*');
				} else {
					window.parent.postMessage({type: 'userStatusChanged', body: 'userRegistered'}, '*');
				}
			}

			if (e.data.watchersTitleUpdate) {
				setRoomName(e.data.watchersTitleUpdate);
			}
		};
	}, [currentScreen]);

	const checkAndUpdateUserData = async (data: RegisterResponseData, externalId: string) => {
		let patchUserDataObj: {name?: string; picId?: string; pic?: string} = {};
		let userDataObjForLS = {};
		if (nickname && data.user.name !== encryptionUserName(nickname)) {
			patchUserDataObj = {
				name: encryptionUserName(nickname),
			};
		}
		if (picId && data.user.pic?.search(picId) === -1) {
			patchUserDataObj = {
				...patchUserDataObj,
				picId,
			};
		}
		if (pic && data.user.pic !== pic) {
			patchUserDataObj = {
				...patchUserDataObj,
				pic,
			};
		}

		if (patchUserDataObj.name || patchUserDataObj.picId || patchUserDataObj.pic) {
			const response = await UserService.patchUserData(patchUserDataObj, data.access_token);
			if (response.status === ResponseStatus.SUCCESS) {
				updateUserData(response.data);
				userDataObjForLS = response.data;
			} else {
				updateUserData(patchUserDataObj);
				userDataObjForLS = patchUserDataObj;
			}
		}

		localStorage.setItem(
			'watcherswebUser',
			JSON.stringify({
				...data.user,
				externalId,
				...userDataObjForLS,
			})
		);
	};

	useEffect(() => {
		localStorageClearViewedAdvertisements();

		if (APP_OPTIONS_PARSE.colorSchemeMode) {
			if (window.matchMedia && !colorSchemeMode) {
				const windowMatchMediaColorSchemeSark = window.matchMedia('(prefers-color-scheme: dark)');

				if (windowMatchMediaColorSchemeSark.matches) {
					HTMLNode?.setAttribute('mode', 'dark');
				}

				windowMatchMediaColorSchemeSark.addEventListener('change', onChangeColorScheme);
			} else if (colorSchemeMode) {
				HTMLNode?.setAttribute('mode', colorSchemeMode);
			}
		}

		if (langFromUrl) {
			const languageItem = Object.values(LanguageTag).find(
				item => item === langFromUrl.toLowerCase()
			);
			if (languageItem) {
				setLanguage(languageItem);
			}
		}

		if (watcherswebLanguage && !langFromUrl) {
			setLanguage(watcherswebLanguage as LanguageTag);
		}

		if (roomNameFromUrl) {
			setRoomName(roomNameFromUrl);
		}

		if (readonlyFromUrl && readonlyFromUrl === 'true') {
			toggleAppReadOnly(true);
			if (!userId) userId = '0';
		}

		if (isMobile) {
			document.addEventListener('gesturestart', onGesturestarthandler);
		}

		return () => {
			if (APP_OPTIONS_PARSE.colorSchemeMode) {
				if (window.matchMedia && !colorSchemeMode) {
					window
						.matchMedia('(prefers-color-scheme: dark)')
						.removeEventListener('change', onChangeColorScheme);
				}
			}

			if (isMobile) {
				document.removeEventListener('gesturestart', onGesturestarthandler);
			}
		};
	}, []);

	useEffect(() => {
		(async () => {
			if (isInternernetActive) {
				if (watcherswebRoomId && !roomId) {
					roomId = watcherswebRoomId.toLowerCase();
				}

				if (
					watcherswebUser &&
					userId &&
					userId !== 'null' &&
					JSON.parse(watcherswebUser).externalId !== userId
				) {
					localStorage.clear();
				}

				if (watcherswebUser && !userId) {
					const parseWatchersWebUser = JSON.parse(watcherswebUser);
					setUserData(parseWatchersWebUser);
					userId = parseWatchersWebUser.externalId;
					nickname = JSON.parse(watcherswebUser).name;
					if (parseWatchersWebUser.pic) {
						pic = parseWatchersWebUser.pic;
					}
				}

				if (APP_OPTIONS_PARSE.nickname) {
					if (!nickname) {
						history.push('/error');
						return;
					}

					const lang = langFromUrl ? langFromUrl.toLowerCase() : language;
					const {isVailable} = await containsMat(nickname, lang);
					if (nickname && watcherswebUser && !isVailable) {
						showAlertForBanReason(BanReason.NICKNAME);
						return;
					}
				}

				if (userId && roomId) {
					let decryptResult = true;
					let regParams: RegisterRequest = {
						externalId: userId,
					};

					amplitude.setUserId(userId);

					if (
						APP_OPTIONS_PARSE.statusname &&
						status &&
						Object.keys(UserVipStatus).includes(status.toUpperCase())
					) {
						regParams = {
							...regParams,
							isVip: true,
							vipStatus: status.toUpperCase() as UserVipStatus,
						};
					}

					if (APP_OPTIONS_PARSE.nickname && nickname) {
						regParams = {
							...regParams,
							name: nickname,
						};
					}

					if (APP_OPTIONS_PARSE.imageId && picId) {
						regParams = {
							...regParams,
							picId,
						};
					}

					if (APP_OPTIONS_PARSE.image && pic) {
						regParams = {
							...regParams,
							pic,
						};
					}

					if (APP_OPTIONS_PARSE.moderator && moder) {
						setUserModer(moder === 'true');
					}

					if (APP_OPTIONS_PARSE.decrypt) {
						if (+userId !== 0) {
							const decrypResponce = await OauthService.decryptUserId(userId);
							if (decrypResponce.status === ResponseStatus.SUCCESS) {
								const {result} = decrypResponce.data;
								decryptResult = result;
							}
						}
					}

					setUserId(userId);

					const response = await OauthService.registerUser(regParams);
					if (response.status === ResponseStatus.SUCCESS && decryptResult) {
						localStorage.setItem(
							'watcherswebUser',
							JSON.stringify({...response.data.user, externalId: userId})
						);
						setUserData({
							...response.data.user,
							externalId: userId,
							ban: {...response.data.ban},
						});
						setAccessToken(response.data.access_token);
						changeDeleteState(false);

						if (
							APP_OPTIONS_PARSE.nickname ||
							APP_OPTIONS_PARSE.imageId ||
							APP_OPTIONS_PARSE.image
						) {
							await checkAndUpdateUserData(response.data, userId);
						}

						if (roomNameFromUrl && APP_OPTIONS_PARSE.checkRealRoomId) {
							const checkRealRoomIdResponse = await RoomService.checkRealRoomId(
								roomId,
								roomNameFromUrl,
								response.data.access_token
							);
							if (checkRealRoomIdResponse.status === ResponseStatus.SUCCESS) {
								saveExternalRoomId(checkRealRoomIdResponse.data.externalRoomId);
							} else {
								saveExternalRoomId(roomId);
							}
						} else {
							saveExternalRoomId(roomId);
						}

						if (response.data.isDeleted || deletedState) {
							changeDeleteState(true);
							history.push('/restore');
							return;
						}

						history.push('/');
						return;
					}
				}

				if (!window.location.pathname.includes('error')) {
					history.push('/error');
				}
			}
		})();
	}, [isInternernetActive]);

	useEffect(() => {
		if (storeUserId && storeRoomId && !eventSessionStartedSent) {
			setEventSessionStartedSent(true);
			sendAnalytics('session_started');
		}
	}, [storeUserId, storeRoomId]);

	useEffect(() => {
		if (submenuMessage || emotionsVisible || pollTooltipVisible) {
			document.addEventListener('touchstart', onClickDocumentHandler);
			document.addEventListener('mousedown', onClickDocumentHandler);
		}

		return () => {
			document.removeEventListener('touchstart', onClickDocumentHandler);
			document.removeEventListener('mousedown', onClickDocumentHandler);
		};
	}, [submenuMessage, emotionsVisible, pollTooltipVisible]);

	useEffect(() => {
		const {messagesLoaded, pinnedMessageLoaded, talkersLoaded, bannedTalkersLoaded} =
			restApiDataLoaded;
		if (
			messagesLoaded &&
			pinnedMessageLoaded &&
			talkersLoaded &&
			bannedTalkersLoaded &&
			SocketIoServices.socket
		) {
			setVisiblePreloader(false);
		}
	}, [restApiDataLoaded, SocketIoServices.socket]);

	useEffect(() => {
		if (restApiDataLoaded.messagesLoaded && !eventRoomOpenedSent) {
			setEventRoomOpenedSent(true);
			sendAnalytics('room_opened');
		}
	}, [restApiDataLoaded.messagesLoaded]);

	return (
		<Switch>
			<Route exact path='/'>
				<Preloader visible={visiblePreloader} />
				<Button onClick={onCloseHandler} workWithoutInet className='app-close-button' />
				<Header />
				<Main />
				<ToastList />
				<Alert />
			</Route>

			<Route path='/restore'>
				<Restore />
			</Route>

			<Route path='/error'>
				<Error />
			</Route>
		</Switch>
	);
};

export default observer(App);
