/* eslint-disable jsx-a11y/media-has-caption */
import {getParent} from 'utils/helpers';
import roomServices from 'store/roomServices';
import useL10n from 'l10n/useL10n';
import {FunctionComponent, useRef, useState, useEffect} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import classNames from 'classnames';
import {ReactComponent as IcoPlay} from 'assets/svg/ico-player-play.svg';
import {ReactComponent as IcoPause} from 'assets/svg/ico-player-pause.svg';
import {ReactComponent as IcoSeekBack} from 'assets/svg/ico-player-seek-back.svg';
import {ReactComponent as IcoSeekForward} from 'assets/svg/ico-player-seek-forward.svg';
import './player.scss';

const playbackRateItems = [
	{id: 0, value: 1},
	{id: 1, value: 1.5},
	{id: 2, value: 2},
];

const Player: FunctionComponent = function Player() {
	const audioRef = useRef<HTMLAudioElement>(null);
	const progressBarRef = useRef<HTMLInputElement>(null);

	const {roomData} = useLocalObservable(() => roomServices);

	const [isPlaying, setIsPlaying] = useState(false);
	const [isDisabledSkipBack, setIsDisabledSkipBack] = useState(true);
	const [isDisabledSkipForward, setIsDisabledSkipForward] = useState(true);
	const [currentTimeTrack, setCurrentTimeTrack] = useState(0);
	const [durationTrack, setDurationTrack] = useState(0);
	const [playbackRate, setPlaybackRate] = useState(1);
	const [isVisiblePlayerSelectDropdown, setIsVisiblePlayerSelectDropdown] = useState(false);

	const {modals} = useL10n();

	const playerSelectToggleClasses = classNames('player__select-toggle', {
		'player__select-toggle--active': isVisiblePlayerSelectDropdown,
	});

	const onClickPlayerHandler = (e: any) => {
		const eventTarget = e.target;
		if (eventTarget && !getParent(eventTarget, 'player__select') && isVisiblePlayerSelectDropdown) {
			setIsVisiblePlayerSelectDropdown(false);
		}
	};

	const formatTime = (time: number) => {
		if (time) {
			const minutes = Math.floor(time / 60);
			const formatMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
			const seconds = Math.floor(time % 60);
			const formatSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
			return `${formatMinutes}:${formatSeconds}`;
		}
		return '00:00';
	};

	const onLoadedMetadataHandler = () => {
		if (audioRef.current) {
			const {currentTime, duration} = audioRef.current;
			setCurrentTimeTrack(currentTime);
			setDurationTrack(duration);

			if (progressBarRef.current) {
				progressBarRef.current.max = duration.toFixed(0);
			}

			if (duration - currentTime > 15) {
				setIsDisabledSkipForward(false);
			}
		}
	};

	const onTimeUpdateHandler = () => {
		if (progressBarRef.current && audioRef.current) {
			const {currentTime, duration} = audioRef.current;
			const rangeValue = ((currentTime / duration) * 100).toFixed();
			setCurrentTimeTrack(currentTime);
			progressBarRef.current.value = `${currentTime}`;
			progressBarRef.current.style.setProperty('--range-progress', `${rangeValue}%`);

			if (duration > 15) {
				if (currentTime > 15) {
					setIsDisabledSkipBack(false);
				} else {
					setIsDisabledSkipBack(true);
				}

				if (duration - currentTime > 15) {
					setIsDisabledSkipForward(false);
				} else {
					setIsDisabledSkipForward(true);
				}
			}
		}
	};

	const onEndedHandler = () => {
		setIsPlaying(false);
		setIsDisabledSkipBack(true);
		setIsDisabledSkipForward(true);
		setCurrentTimeTrack(0);
		setPlaybackRate(1);
		if (audioRef.current) {
			audioRef.current.currentTime = 0;
		}
	};

	const onProgressBarHandler = () => {
		if (audioRef.current && progressBarRef.current) {
			audioRef.current.currentTime = parseInt(progressBarRef.current.value, 10);
		}
	};

	const onControlsSkipHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
		const target = e.target as HTMLButtonElement;
		const skipDirection = target.getAttribute('data-skip');

		if (audioRef.current) {
			if (skipDirection === 'forward') {
				if (
					!isDisabledSkipForward &&
					audioRef.current.currentTime + 15 < audioRef.current.duration
				) {
					audioRef.current.currentTime += 15;
				}

				return;
			}

			if (!isDisabledSkipBack && audioRef.current.currentTime > 16) {
				audioRef.current.currentTime -= 15;
			}
		}
	};

	const onControlsPlayHandler = () => {
		if (audioRef.current) {
			if (isPlaying) {
				audioRef.current.pause();
				setIsPlaying(prev => !prev);
				return;
			}

			audioRef.current.play();
			setIsPlaying(prev => !prev);
		}
	};

	const onPlayerSelectButtonHandler = (value: number) => {
		if (value !== playbackRate && audioRef.current) {
			setPlaybackRate(value);
			setIsVisiblePlayerSelectDropdown(false);
			audioRef.current.playbackRate = value;
		}
	};

	const renderPlayerSelectItem = (item: {id: number; value: number}) => {
		return (
			<div className='player__select-item' key={item.id}>
				<button
					type='button'
					className='player__select-button'
					onClick={() => onPlayerSelectButtonHandler(item.value)}>
					{item.value}x
				</button>
			</div>
		);
	};

	useEffect(() => {
		document.addEventListener('click', onClickPlayerHandler);
		return () => {
			document.removeEventListener('click', onClickPlayerHandler);
		};
	}, [isVisiblePlayerSelectDropdown]);

	if (!roomData?.record) {
		return (
			<div className='player player--no-audio'>
				<div className='player__text'>{modals.record.text}</div>
			</div>
		);
	}

	return (
		<div className='player'>
			<div className='player__head'>
				<div className='player__progressbar'>
					<audio
						controls
						preload='true'
						src={roomData?.record}
						className='player__audio'
						onLoadedMetadata={onLoadedMetadataHandler}
						onTimeUpdate={onTimeUpdateHandler}
						onEnded={onEndedHandler}
						ref={audioRef}
					/>
					<input
						type='range'
						defaultValue='0'
						className='player__progressbar-range'
						onChange={onProgressBarHandler}
						ref={progressBarRef}
					/>
					<div className='player__progressbar-times'>
						<div className='player__progressbar-time'>{formatTime(currentTimeTrack)}</div>
						<div className='player__progressbar-time'>{formatTime(durationTrack)}</div>
					</div>
				</div>
			</div>
			<div className='player__body'>
				<div className='player__controls'>
					<div className='player__select'>
						<button
							type='button'
							className={playerSelectToggleClasses}
							onClick={() => setIsVisiblePlayerSelectDropdown(!isVisiblePlayerSelectDropdown)}>
							{playbackRate}x
						</button>
						{isVisiblePlayerSelectDropdown && (
							<div className='player__select-dropdown'>
								<div className='player__select-items'>
									{playbackRateItems.map(renderPlayerSelectItem)}
								</div>
							</div>
						)}
					</div>
					<button
						type='button'
						className='player__controls-skip'
						data-skip='back'
						disabled={isDisabledSkipBack}
						onClick={onControlsSkipHandler}>
						<IcoSeekBack />
					</button>
					<button
						type='button'
						className='player__controls-play'
						aria-label='play'
						onClick={onControlsPlayHandler}>
						{isPlaying ? <IcoPause /> : <IcoPlay />}
					</button>
					<button
						type='button'
						className='player__controls-skip'
						data-skip='forward'
						disabled={isDisabledSkipForward}
						onClick={onControlsSkipHandler}>
						<IcoSeekForward />
					</button>
				</div>
			</div>
		</div>
	);
};

export default observer(Player);
