import {MODAL_STYLE} from 'constants/constants';
import modalServices from 'store/modalServices';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Modal from 'react-modal';
import {Settings} from 'components/settings';
import useL10n from 'l10n/useL10n';
import useAnalytics from 'hooks/useAnalytics';
import {AppScreens} from 'models/enums/AppScreens.enum';
import appService from 'store/appService';
import {useEffect} from 'react';
import Button from 'components/hoc/Button';

const SettingsModal: React.FC = function SettingsModal() {
	const {settingsModalVisible, hideSettingsModal} = useLocalObservable(() => modalServices);
	const {changeCurrentScreen} = useLocalObservable(() => appService);
	const translations = useL10n();
	const {sendAnalytics} = useAnalytics();

	useEffect(() => {
		settingsModalVisible
			? changeCurrentScreen(AppScreens.SETTINGS)
			: changeCurrentScreen(AppScreens.CHAT);
	}, [settingsModalVisible]);

	const closeModalHandler = () => {
		sendAnalytics('settings_closed');
		hideSettingsModal();
	};

	return (
		<Modal
			className='modal'
			isOpen={settingsModalVisible}
			ariaHideApp={false}
			style={{
				overlay: MODAL_STYLE.overlay,
			}}>
			<div className='modal__head'>
				<Button className='modal__close' onClick={closeModalHandler}>
					close
				</Button>
				<div className='modal__title'>{translations.settingsScreen.title}</div>
			</div>
			<div className='modal__body'>
				<div className='modal__axis-y'>
					<Settings />
				</div>
			</div>
		</Modal>
	);
};

export default observer(SettingsModal);
