import {InputProps, TextAreaProps} from 'models/hoc';
import * as React from 'react';
import checkRealTextLength from 'utils/checkRealTextLength';

const Field: React.FC<InputProps | TextAreaProps> = function Field(props) {
	const onChangeHandler = (
		event: React.ChangeEvent<HTMLInputElement> & React.ChangeEvent<HTMLTextAreaElement>
	) => {
		if (props.maxLength && checkRealTextLength(event.target.value) > props.maxLength) {
			let valueLenghtForSlice = 0;
			Array.from(event.target.value).forEach(character => {
				if (props.maxLength && props.maxLength > valueLenghtForSlice + character.length) {
					valueLenghtForSlice += character.length;
				}
			});

			// eslint-disable-next-line no-param-reassign
			event.target.value = event.target.value.slice(0, valueLenghtForSlice);
		}
		props.onChange(event);
	};

	switch (props.fieldType) {
		case 'input':
			return (
				<input
					ref={props.innerRef}
					className={props.className}
					placeholder={props.placeholder}
					type={props.type}
					value={props.value}
					onChange={onChangeHandler}
					onFocus={props.onFocus}
					onKeyDown={props.onKeyDown}
					onInput={props.onInput}
					onBlur={props.onBlur}
				/>
			);
		case 'textarea':
			return (
				<textarea
					ref={props.innerRef}
					className={props.className}
					placeholder={props.placeholder}
					rows={props.rows}
					maxLength={props.maxLength}
					cols={props.cols}
					value={props.value}
					autoComplete={props.autoComplete}
					autoCorrect={props.autoCorrect}
					spellCheck={props.spellCheck}
					readOnly={props.readOnly}
					onChange={onChangeHandler}
					onFocus={props.onFocus}
					onKeyDown={props.onKeyDown}
					onKeyUp={props.onKeyUp}
					onInput={props.onInput}
					onBlur={props.onBlur}
				/>
			);
		default:
			return null;
	}
};

export default Field;
