import StickersEmojiType from 'models/enums/StickersEmojiType.enum';
import {StickersEmojiControlsBtn} from 'models/room';
import {ReactComponent as IcoEmoji} from 'assets/svg/ico-emoji.svg';
import {ReactComponent as IcoStickers} from 'assets/svg/ico-stickers.svg';

export const stickersEmojiBtnsData: StickersEmojiControlsBtn[] = [
	{
		id: 0,
		title: {
			ru: 'Стикеры',
			en: 'Stickers',
		},
		icon: <IcoStickers />,
		tabName: StickersEmojiType.STICKERS,
	},
	{
		id: 1,
		title: {
			ru: 'Emoji',
			en: 'Emoji',
		},
		icon: <IcoEmoji />,
		tabName: StickersEmojiType.EMOJI,
	},
];
