import {FunctionComponent} from 'react';
import classNames from 'classnames/bind';
import {ReactComponent as IcoStar} from 'assets/svg/ico-star.svg';
import './star.scss';

interface IStarProps {
	selected: boolean;
}

const Star: FunctionComponent<IStarProps> = function Star({selected}) {
	const starClasses = classNames('star', {
		'star--selected': selected,
	});

	return (
		<div className={starClasses}>
			<IcoStar className='star__ico' />
		</div>
	);
};

export default Star;
