import LocalizedStrings, {LocalizedStrings as LocalizedStringsType} from 'react-localization';
import {Dictionary} from 'l10n/dictionaries/dictionary';
import en from './dictionaries/en';
import ru from './dictionaries/ru';

const translations = {
	en,
	ru,
};

class L10n {
	private static instance: LocalizedStringsType<Dictionary>;

	public static shared = () => {
		if (!L10n.instance) {
			L10n.instance = new LocalizedStrings(translations);
		}
		return L10n.instance;
	};
}

export default L10n.shared();
