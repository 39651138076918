import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {BanReason} from 'models/enums/BanModerReasons.enum';
import {AlertBtnType} from 'models/enums/Alert.enum';
import useL10n from 'l10n/useL10n';
import userServices from 'store/userServices';
import appService from 'store/appService';
import roomServices from 'store/roomServices';
import modalServices from 'store/modalServices';
import alertService from 'store/alertService';
import RoomService from 'services/api/RoomService';
import {useLocalObservable} from 'mobx-react-lite';

export default () => {
	const {alerts: alertsTranslate} = useL10n();
	const {accessToken} = useLocalObservable(() => userServices);
	const {addBlockedUsers} = useLocalObservable(() => appService);
	const {addBlockedUsersForFilteredMessages} = useLocalObservable(() => roomServices);
	const {toggleRulesModal} = useLocalObservable(() => modalServices);
	const {showAlert} = useLocalObservable(() => alertService);

	const getBanUsersWithServices = async () => {
		if (accessToken) {
			const response = await RoomService.getBlockedUsers(accessToken);
			if (response.status === ResponseStatus.SUCCESS) {
				addBlockedUsers(response.data);
				addBlockedUsersForFilteredMessages(response.data);
			}
		}
	};

	const showAlertForBanReason = (reason: BanReason) => {
		if (reason === BanReason.NICKNAME) {
			showAlert({
				zIndex: 999,
				title: alertsTranslate.banInAppBadNickname.title,
				subtitle: alertsTranslate.banInAppBadNickname.subtitle,
				buttons: [
					{
						text: alertsTranslate.btns.rules,
						type: AlertBtnType.NORMAL,
						onPress: () => {
							toggleRulesModal(true);
						},
					},
				],
				closeOnBackdropPress: false,
			});
		}
	};

	return {getBanUsersWithServices, showAlertForBanReason};
};
