import {Message} from 'models/room';
import roomServices from 'store/roomServices';
import useL10n from 'l10n/useL10n';
import useSubmenuActions from 'hooks/useSubmenuActions';
import {FunctionComponent} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Button from 'components/hoc/Button';
import {ReactComponent as Hide} from 'assets/svg/ico-submenu-hide.svg';
import {ReactComponent as Show} from 'assets/svg/ico-submenu-show.svg';

interface IButtonMessagesVisibleProps {
	message: Message;
}

const ButtonMessagesVisible: FunctionComponent<IButtonMessagesVisibleProps> =
	function ButtonMessagesVisible({message}) {
		const {chatScreen} = useL10n();
		const {isVisible: messageIsVisible} = message;
		const {roomId, setSubmenuMessage} = useLocalObservable(() => roomServices);
		const {messageVisible} = useSubmenuActions();

		const onMessagesVisibleHandler = () => {
			if (roomId) {
				messageVisible(message, roomId, messageIsVisible);
				setSubmenuMessage(null);
			}
		};

		return (
			<Button className='chat__submenu-btn' onClick={onMessagesVisibleHandler}>
				{messageIsVisible ? (
					<>
						{chatScreen.chatSubmenu.hideOnlyMessage}
						<Hide className='chat__submenu-icon' />
					</>
				) : (
					<>
						{chatScreen.chatSubmenu.showOnlyMessage}
						<Show className='chat__submenu-icon' />
					</>
				)}
			</Button>
		);
	};

export default observer(ButtonMessagesVisible);
