import {MODAL_STYLE} from 'constants/constants';
import modalServices from 'store/modalServices';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Modal from 'react-modal';
import {ReactComponent as IcoWarning} from 'assets/svg/ico-warning.svg';
import useL10n from 'l10n/useL10n';
import ChatNameForm from 'components/chatNameForm/ChatNameForm';
import './changeNameModal.scss';

const ChangeNameModal: React.FC = function BlockedModal() {
	const {changeNameModal} = useLocalObservable(() => modalServices);
	const translations = useL10n();

	return (
		<Modal
			className='namechanging'
			isOpen={changeNameModal}
			ariaHideApp={false}
			style={{
				overlay: MODAL_STYLE.overlay,
			}}>
			<div className='namechanging__header'>
				<IcoWarning />
				<div className='namechanging__header-container'>
					<p className='namechanging__title'>{translations.modals.changeName.title}</p>
					<p className='namechanging__subtitle'>{translations.modals.changeName.subtitle}</p>
				</div>
			</div>
			<div className='namechanging__body'>
				<ChatNameForm fromChangeNameModal />
			</div>
		</Modal>
	);
};

export default observer(ChangeNameModal);
