import AdvertisementTemplateType from 'models/enums/AdvertisementTemplateType';
import {MessageAdvertisement} from 'models/room';
import {FunctionComponent} from 'react';
import HighlightContainer from './HighlightContainer';
import './highlight.scss';

interface IChatAdvertisementProps {
	advertisement: MessageAdvertisement;
}

const Advertisement: FunctionComponent<IChatAdvertisementProps> = function Advertisement(props) {
	const {advertisement} = props;
	const templateName = AdvertisementTemplateType[advertisement.template];

	return (
		<div className={`highlight ${templateName ? `highlight--${templateName.toLowerCase()}` : ''}`}>
			<HighlightContainer advertisement={advertisement} templateName={templateName} />
		</div>
	);
};

export default Advertisement;
