import windowLocalStorage from 'utils/windowLocalStorage';
import appService from 'store/appService';
import AgoraServices from 'services/api/AgoraService';
import {useLocalObservable} from 'mobx-react-lite';

const useAgora = () => {
	const {
		setAgoraMicrophoneActive,
		setAgoraMicrophones,
		setAgoraPlaybackDeviceActive,
		setAgoraPlaybackDevices,
	} = useLocalObservable(() => appService);

	const getMicrophones = async () => {
		const microphones = await AgoraServices.getMicrophones();
		if (microphones.length > 1) {
			const LSAgoraMicrophone = windowLocalStorage.get<MediaDeviceInfo>(
				'watcherswebAgoraMicrophone'
			);
			setAgoraMicrophones(microphones);
			if (
				LSAgoraMicrophone &&
				microphones.find((mic: MediaDeviceInfo) => mic.deviceId === LSAgoraMicrophone.deviceId)
			) {
				AgoraServices.setMicrophone(LSAgoraMicrophone.deviceId);
				setAgoraMicrophoneActive(LSAgoraMicrophone);
				return;
			}
			setAgoraMicrophoneActive(microphones[0]);
			windowLocalStorage.set('watcherswebAgoraMicrophone', microphones[0]);
		}
	};

	const getPlaybackDevices = async () => {
		const playbackDevices = await AgoraServices.getPlaybackDevices();
		if (playbackDevices.length > 1) {
			const LSAgoraPlaybackDevice = windowLocalStorage.get<MediaDeviceInfo>(
				'watcherswebAgoraPlaybackDevice'
			);
			setAgoraPlaybackDevices(playbackDevices);
			if (
				LSAgoraPlaybackDevice &&
				playbackDevices.find(
					(playbackDevice: MediaDeviceInfo) =>
						playbackDevice.deviceId === LSAgoraPlaybackDevice.deviceId
				)
			) {
				AgoraServices.setPlaybackDevice(LSAgoraPlaybackDevice.deviceId);
				setAgoraPlaybackDeviceActive(LSAgoraPlaybackDevice);
				return;
			}
			setAgoraPlaybackDeviceActive(playbackDevices[0]);
			windowLocalStorage.set('watcherswebAgoraPlaybackDevice', playbackDevices[0]);
		}
	};

	return {getMicrophones, getPlaybackDevices};
};

export default useAgora;
