/* eslint-disable no-useless-escape */
const encryptionUserName = (userName: string, indexStart = 3, indexEnd = 9) => {
	const trimUserName = userName.trim();
	if (/^((8|\+7)[\- ]?)?(\(?\d{3,4}\)?[\- ]?)?[\d\- ]{5,10}$/.test(trimUserName)) {
		const trueIndexEnd = trimUserName.length < 12 ? indexEnd : 10;
		let updateUserName = '';
		Array.from(trimUserName).forEach((letter: string, index: number) => {
			if (index > indexStart && index < trueIndexEnd) {
				updateUserName += '*';
				return;
			}
			updateUserName += letter;
		});
		return updateUserName;
	}

	return trimUserName;
};

export {encryptionUserName};
