enum AppScreens {
	INTRO = 'introScreen',
	CHAT = 'chatScreen',
	SETTINGS = 'settingsScreen',
	PROFILE = 'profileScreen',
	AVATAR = 'profileAvatarScreen',
	NAME = 'profileNameScreen',
	BLOCKLIST = 'blockListScreen',
	USERS = 'usersScreen',
	WAITINIGSPEAKERS = 'waitingSpeakersScreen',
	APPBAN = 'appBanScreen',
	EVENT = 'eventScreen',
	ERROR = 'errorScreen',
	AGREEMENTS = 'agreementsScreen',
	SETNAME = 'introSetNameScreen',
	SETAVATAR = 'introSetAvatarScreen',
	POLICY = 'policyScreen',
	LICENSE = 'licenseScreen',
	ACCOUNTDELETED = 'accountDeletedScreen',
}

export {AppScreens};
