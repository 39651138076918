import {ToastMessage} from 'models/toast';
import toastService from 'store/toastService';
import {FunctionComponent, useCallback} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {TransitionGroup} from 'react-transition-group';
import Toast from './Toast';
import './toasts.scss';

const ToastList: FunctionComponent = function ToastList() {
	const {toasts} = useLocalObservable(() => toastService);

	const renderToast = useCallback(
		(toast: ToastMessage) => <Toast value={toast} key={toast.id} />,
		[toasts.length]
	);

	return (
		<div className='toasts'>
			<TransitionGroup>{toasts.map(renderToast)}</TransitionGroup>
		</div>
	);
};

export default observer(ToastList);
