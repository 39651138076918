import {Emotion} from 'models/room';
import emotionServices from 'store/emotionServices';
import {FunctionComponent, useCallback} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import EmotionAnimItem from './EmotionAnimItem';
import './emotions-anim.scss';

const EmotionsAnim: FunctionComponent = function EmotionsAnim() {
	const {emotionsAnim} = useLocalObservable(() => emotionServices);

	const renderEmotionAnim = useCallback(
		(emotion: Emotion) => <EmotionAnimItem key={emotion.uuid} emotion={emotion} />,
		[emotionsAnim]
	);

	return <div className='emotions-anim'>{emotionsAnim.map(renderEmotionAnim)}</div>;
};

export default observer(EmotionsAnim);
