/* eslint-disable max-lines */
import RoomStatus from 'models/enums/RoomStatus.enum';
import MessageType from 'models/enums/MessageType.enum';
import {Message, Reaction, SubmenuButton} from 'models/room';
import appService from 'store/appService';
import userServices from 'store/userServices';
import roomServices from 'store/roomServices';
import chatServices from 'store/chatServices';
import {FunctionComponent, useEffect, useRef, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {v4 as uuidv4} from 'uuid';
import classNames from 'classnames/bind';
import {
	ButtonBlock,
	ButtonChangeRole,
	ButtonCopy,
	ButtonEdit,
	ButtonMessagesVisible,
	ButtonMute,
	ButtonPinned,
	ButtonRemove,
	ButtonReplay,
	ButtonReport,
} from './chatMessageSubmenuButtons';
import {ChatMessageSubmenuReactions} from './chatMessageSubmenuReactions';

const REACTIONS_HEIGHT = 50; // px

interface IChatMessageSubmenuProps {
	message: Message;
	reactions?: Reaction[];
}

const ChatMessageSubmenu: FunctionComponent<IChatMessageSubmenuProps> =
	function ChatMessageSubmenu({message, reactions}) {
		const {id: messageId, type: messageType, talker} = message;
		const submenuRef = useRef<HTMLDivElement>(null);
		const [submenuVisible, setSubmenuVisible] = useState(false);
		const [submenuAboveMessage, setSubmenuAboveMessage] = useState(true);
		const {appVoice} = useLocalObservable(() => appService);
		const {userData} = useLocalObservable(() => userServices);
		const {myTalker, roomData, submenuButtons, setSubmenuButtons} = useLocalObservable(
			() => roomServices
		);
		const {chatBot} = useLocalObservable(() => chatServices);

		const isMyTalkerModer = !!myTalker?.isModer;
		const isTalkerModer = talker?.isModer;
		const isTalkerSpeaking = !talker.isMuted;
		const isAuthor = userData && talker.user && userData.id === talker.user?.id;
		const isChatBotMessage = talker.user && chatBot && talker.user.name === chatBot.name;
		const roomStatusLive = roomData?.status === RoomStatus.LIVE;
		const isCustomImageHighlight =
			messageType === MessageType.ADVERTISEMENT && message.advertisement?.template === 11;

		const chatMessageSubmenuClasses = classNames('chat__submenu', {
			'chat__submenu--visible': submenuVisible,
			'chat__submenu--top': submenuAboveMessage,
			'chat__submenu--right': isAuthor,
		});

		const chatSubmenuItemsClasses = () => {
			const baseClassName = 'chat__submenu-items';
			const filterSubmenuButtonsSmall = submenuButtons.filter((b: any) => b.buttonSizeSmall);
			if (submenuButtons.length > 2 && filterSubmenuButtonsSmall.length) {
				return `${baseClassName} ${baseClassName}--have-small-${filterSubmenuButtonsSmall.length}`;
			}
			return baseClassName;
		};

		const setSubmenuPosition = () => {
			const subMenuHeight = submenuRef.current
				? submenuRef.current.clientHeight + REACTIONS_HEIGHT
				: 0;
			let chatMessagesPaddingTop = 0;
			const chatScroll = document.querySelector('.chat__axis-y');
			const chatMessages = document.querySelector('.chat__messages');
			const chatMessageItem: HTMLElement | null = document.querySelector(
				`.chat__message[data-id="${messageId}"]`
			);

			if (chatMessages && getComputedStyle(chatMessages)) {
				chatMessagesPaddingTop = parseInt(getComputedStyle(chatMessages).paddingTop, 10);
			}

			if (!chatMessageItem || !chatScroll) {
				setSubmenuAboveMessage(true);
				return;
			}

			const isAboveMessage =
				chatMessageItem.offsetTop >= chatScroll.scrollTop + chatMessagesPaddingTop + subMenuHeight;

			setSubmenuAboveMessage(isAboveMessage);
		};

		const buttonsData: SubmenuButton[] = [
			{
				button: <ButtonReplay message={message} />,
				buttonType: [
					MessageType.USER,
					MessageType.POLLRESULTS,
					MessageType.STICKER,
					MessageType.BET,
				],
				buttonForTalkersAll: true,
				buttonForMyTalkerModer: true,
				buttonSizeSmall: true,
				buttonAdditionalCondition: roomStatusLive,
			},
			{
				button: <ButtonCopy message={message} />,
				buttonType: [MessageType.USER, MessageType.ADVERTISEMENT],
				buttonForTalkersAll: true,
				buttonForMyTalkerModer: true,
				buttonSizeSmall: true,
				buttonAdditionalCondition: !isCustomImageHighlight,
			},
			{
				button: <ButtonPinned message={message} />,
				buttonType: [MessageType.USER, MessageType.ADVERTISEMENT, MessageType.POLLRESULTS],
				buttonForTalkersAll: false,
				buttonForMyTalkerModer: true,
				buttonSizeSmall: true,
				buttonAdditionalCondition: roomStatusLive,
			},
			{
				button: <ButtonEdit message={message} />,
				buttonType: [MessageType.USER],
				buttonForTalkersAll: true,
				buttonForMyTalkerModer: true,
				buttonSizeSmall: false,
				buttonAdditionalCondition: !!isAuthor && roomStatusLive,
			},
			{
				button: <ButtonChangeRole message={message} />,
				buttonType: [MessageType.USER],
				buttonForTalkersAll: false,
				buttonForMyTalkerModer: true,
				buttonSizeSmall: false,
				buttonAdditionalCondition:
					!isAuthor &&
					isMyTalkerModer &&
					!isTalkerModer &&
					appVoice &&
					!isChatBotMessage &&
					roomStatusLive,
			},
			{
				button: <ButtonMute message={message} />,
				buttonType: [MessageType.USER],
				buttonForTalkersAll: false,
				buttonForMyTalkerModer: true,
				buttonSizeSmall: false,
				buttonAdditionalCondition:
					!isAuthor &&
					isMyTalkerModer &&
					!isTalkerModer &&
					appVoice &&
					isTalkerSpeaking &&
					roomStatusLive,
			},
			{
				button: <ButtonMessagesVisible message={message} />,
				buttonType: [
					MessageType.USER,
					MessageType.POLLRESULTS,
					MessageType.STICKER,
					MessageType.BET,
				],
				buttonForTalkersAll: false,
				buttonForMyTalkerModer: true,
				buttonSizeSmall: false,
				buttonAdditionalCondition: !isAuthor && roomStatusLive,
			},
			{
				button: <ButtonReport message={message} />,
				buttonType: [MessageType.USER],
				buttonForTalkersAll: true,
				buttonForMyTalkerModer: false,
				buttonSizeSmall: false,
				buttonAdditionalCondition: !isAuthor && roomStatusLive,
			},
			{
				button: <ButtonRemove message={message} />,
				buttonType: [
					MessageType.USER,
					MessageType.POLLRESULTS,
					MessageType.STICKER,
					MessageType.BET,
				],
				buttonForTalkersAll: true,
				buttonForMyTalkerModer: true,
				buttonSizeSmall: false,
				buttonAdditionalCondition:
					(!!isAuthor ||
						(isMyTalkerModer && !isTalkerModer && messageType === MessageType.POLLRESULTS)) &&
					roomStatusLive,
			},
			{
				button: <ButtonBlock message={message} />,
				buttonType: [MessageType.USER],
				buttonForTalkersAll: true,
				buttonForMyTalkerModer: true,
				buttonSizeSmall: false,
				buttonAdditionalCondition:
					!isAuthor && !isTalkerModer && !isChatBotMessage && roomStatusLive,
			},
		];

		const getSubmenuButtons = () => {
			const filterButtons = buttonsData.filter(
				item =>
					item.button &&
					item.buttonType.includes(messageType) &&
					item.buttonAdditionalCondition &&
					((item.buttonForTalkersAll && !isMyTalkerModer) ||
						item.buttonForMyTalkerModer === isMyTalkerModer)
			);
			if (filterButtons.length) {
				setSubmenuButtons(filterButtons);
			}
		};

		const renderButton = (item: SubmenuButton) => {
			const {button, buttonSizeSmall} = item;
			return (
				<div
					className={`chat__submenu-item ${buttonSizeSmall ? 'chat__submenu-item--small' : ''}`}
					key={uuidv4()}>
					{button}
				</div>
			);
		};

		useEffect(() => {
			if (submenuButtons.length) {
				setSubmenuPosition();
				setSubmenuVisible(true);
			}
		}, [submenuButtons]);

		useEffect(() => {
			getSubmenuButtons();
		}, [message, myTalker]);

		return (
			<div ref={submenuRef} className={chatMessageSubmenuClasses}>
				{roomStatusLive &&
					(messageType === MessageType.USER ||
						messageType === MessageType.STICKER ||
						messageType === MessageType.BET) && (
						<ChatMessageSubmenuReactions messageId={messageId} reactions={reactions} />
					)}
				<div className={chatSubmenuItemsClasses()}>{submenuButtons.map(renderButton)}</div>
			</div>
		);
	};

export default observer(ChatMessageSubmenu);
