const showErrorCritical = (e: any) => {
	const htmlErrorCticial = `
		<div class='error error--cticial'>
			<div class='error__header'>
				<div class='error__container'>
					<p class='error__title'>Error</p>
				</div>
			</div>
			<p class='error__text'>URL: ${window.location.href}</p>
			<p class='error__text'>DATA: ${e}</p>
		</div>
	`;
	const newDiv = document.createElement('div');
	newDiv.classList.add('error-wrapper');
	newDiv.innerHTML = htmlErrorCticial;
	setTimeout(() => {
		document.querySelector('body')?.append(newDiv);
	}, 500);
};

export default showErrorCritical;
