enum PollType {
	POLL = 'POLL',
	QUIZ = 'QUIZ',
}

enum PollStatus {
	ACTIVE = 'ACTIVE',
	ENDED = 'ENDED',
	PLANNED = 'PLANNED',
}

enum PollScreens {
	SETTINGS = 'settings',
	QUESTION = 'question',
	ANSWERS = 'answers',
	PREVIEW = 'preview',
}

enum PollEditActionType {
	EDIT = 'EDIT',
	END = 'END',
}

export {PollType, PollStatus, PollScreens, PollEditActionType};
