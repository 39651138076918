/* eslint-disable max-lines */
import {TALKER_STUB} from 'constants/constants';
import useL10n from 'l10n/useL10n';
import useSettings from 'hooks/useSettings';
import {useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';
import {useLocalObservable} from 'mobx-react-lite';
import {v4 as uuidv4} from 'uuid';
import UserRole from 'models/enums/UserRole.enum';
import RoomStatus from 'models/enums/RoomStatus.enum';
import {PollStatus} from 'models/enums/Poll.enum';
import {BanReason} from 'models/enums/BanModerReasons.enum';
import MessageNotSentReasonType from 'models/enums/MessageNotSentReasonType';
import {Room, Talker, Message, AgoraCreds, Emotion, Reaction} from 'models/room';
import {Poll, PollOption} from 'models/poll';
import appService from 'store/appService';
import emotionServices from 'store/emotionServices';
import roomServices from 'store/roomServices';
import toastService from 'store/toastService';
import userServices from 'store/userServices';
import pollServices from 'store/pollServices';
import modalServices from 'store/modalServices';
import controlPanelServices from 'store/controlPanelServices';
import stickersEmojiServices from 'store/stickersEmojiServices';
import slowModeServices from 'store/slowModeServices';
import AgoraServices from 'services/api/AgoraService';
import {ToastIconName} from 'components/toasts/ToastIcons';

export default () => {
	const {search} = useLocation();
	const translations = useL10n();
	const myTalkerRef = useRef<Talker | null>();
	const chatScrollPositionBottomRef = useRef(false);
	const previousMessagesModeRef = useRef(false);
	const slowModeRef = useRef<SlowMode>();
	const {userData, updateUserData} = useLocalObservable(() => userServices);
	const {
		roomId,
		addMessage,
		removeMessages,
		removeMessageByPollId,
		updateMessage,
		updateTalkerMessagesByTalkerId,
		updateVisibleMessagesByUserId,
		myTalker,
		setMyTalker,
		updateMyTalker,
		addTalker,
		updateTalker,
		updateTalkerMic,
		removeTalker,
		setAgoraCreds,
		addBannedTalker,
		removeBannedTalker,
		setRoomData,
		updateRoomData,
		updateUserInfo,
		updatePinnedMessagePinStatus,
		blockedUsersForFilteredMessages,
		toggleBlockedUserForFilteredMessages,
		removeMentionMessageForBlockedUser,
		toggleClearChatTextarea,
		chatScrollPositionBottom,
		previousMessagesMode,
		removeMessage,
		addReactionToMessage,
		removeReactionToMessage,
		addUnrecordedMessage,
		removeUnrecordedMessage,
	} = useLocalObservable(() => roomServices);
	const {appVoice, setAppVoice, removeBlockedUser, changeAgoraStatus} = useLocalObservable(
		() => appService
	);
	const {addToast} = useLocalObservable(() => toastService);
	const {emotions, addEmotion} = useLocalObservable(() => emotionServices);
	const {setPoll, removePoll, setPollOptionVote, addPollVoteAnim, toggllePollTooltipVisible} =
		useLocalObservable(() => pollServices);
	const {
		togglePollCreateModalVisible,
		togglePollEditModalVisible,
		hideAllPollModals,
		toggleChangeNameModal,
		toggleRecordingModal,
	} = useLocalObservable(() => modalServices);
	const {toggleVisibleButtons} = useLocalObservable(() => controlPanelServices);
	const {removeSticker} = useLocalObservable(() => stickersEmojiServices);
	const {slowMode, togglleSlowMode} = useLocalObservable(() => slowModeServices);
	const {getSettingsWithServices} = useSettings();

	const banReasonTranslations = [
		translations.modals.ban.btns.insults,
		translations.modals.ban.btns.personal,
		translations.modals.ban.btns.flood,
		translations.modals.ban.btns.links,
		translations.modals.ban.btns.violence,
		translations.modals.ban.btns.fraud,
		translations.modals.ban.btns.nickname,
		translations.modals.ban.btns.politicial,
		translations.modals.ban.btns.repeated,
		translations.modals.ban.btns.auto,
		translations.modals.ban.btns.spam,
	];

	let animationVersion = true;
	let animationVersionPollVote = 1;

	useEffect(() => {
		if (myTalker) {
			myTalkerRef.current = myTalker;
		}
	}, [myTalker]);

	useEffect(() => {
		if (chatScrollPositionBottomRef.current !== chatScrollPositionBottom) {
			chatScrollPositionBottomRef.current = chatScrollPositionBottom;
		}
	}, [chatScrollPositionBottom]);

	useEffect(() => {
		if (previousMessagesModeRef.current !== previousMessagesMode) {
			previousMessagesModeRef.current = previousMessagesMode;
		}
	}, [previousMessagesMode]);

	useEffect(() => {
		slowModeRef.current = slowMode;
	}, [slowMode]);

	const showUserNameInChat = () => {
		const nicknameFromUrl = new URLSearchParams(search).get('nickname');
		const userNameFromLS = localStorage.getItem('watcherswebShowedUserName');
		if (nicknameFromUrl && (!userNameFromLS || userNameFromLS !== nicknameFromUrl)) {
			localStorage.setItem('watcherswebShowedUserName', nicknameFromUrl);
			addToast({
				imgSrc: userData?.pic,
				iconName: ToastIconName.warning,
				liveTime: 5000,
				message: translations.toasts.yourUserNameInChat(nicknameFromUrl),
				cancellable: true,
			});
		}
	};

	const onSuccessfullyJoined = (data: {room: Room; talker: Talker; ban: {reason: number}}) => {
		const {room, talker, ban} = data;
		setMyTalker(talker);
		setRoomData(room);
		showUserNameInChat();
		if (room.isSpeak && (room.status === RoomStatus.LIVE || room.status === RoomStatus.SOON)) {
			setAppVoice(true);
		}
		if (room.status === RoomStatus.ENDED && room.record) {
			toggleRecordingModal(true);
		}
		if (ban && ban.reason === BanReason.NICKNAME) {
			toggleChangeNameModal(true);
		}
	};

	const onUserJoinedHandler = (data: {room: Room; talker: Talker}) => {
		const {talker} = data;
		addTalker(talker);
	};

	const onUserLeftHandler = (talker: Talker) => {
		removeTalker(talker.id);
		if (myTalkerRef.current && myTalkerRef.current.id === talker.id) {
			setMyTalker(null);
		}
	};

	const onGetMessageHandler = (data: {message: Message}) => {
		let localMessage = data.message;

		if (localMessage && !localMessage.talker) {
			localMessage = {...localMessage, talker: TALKER_STUB};
		}

		const localMessageUserId = localMessage.talker.user?.id;

		localMessage.isNotReaded =
			localMessageUserId && blockedUsersForFilteredMessages.includes(localMessageUserId)
				? true
				: !chatScrollPositionBottomRef.current;

		if (previousMessagesModeRef.current) {
			addUnrecordedMessage(localMessage);
		} else {
			addMessage(localMessage);
		}

		if (localMessage.talker.user?.id !== userData?.id) {
			window.parent.postMessage({type: 'haveNewMessage'}, '*');
		}
	};

	const onMessageNotSentHandler = (data: {
		reason: MessageNotSentReasonType;
		stickerId?: number;
	}) => {
		const {reason, stickerId} = data;
		if (reason === MessageNotSentReasonType.STICKER_UNAVAILABLE && stickerId) {
			removeSticker(stickerId);
		}
	};

	const onMessageEditedHandler = (data: {message: Message}) => {
		let localMessage = data.message;

		if (localMessage && !localMessage.talker) {
			localMessage = {...localMessage, talker: TALKER_STUB};
		}

		updateMessage(localMessage);
	};

	const onMessagesDeletedHandler = (data: {isAll: boolean}) => {
		if (data.isAll) {
			removeMessages();
		}
	};

	const onMessagesVisibleHandler = (data: {userId: number; isVisible: boolean}) => {
		const {userId, isVisible} = data;
		const talkerIsModer = !!myTalkerRef.current?.isModer;
		if (talkerIsModer || (!talkerIsModer && userData?.id !== userId)) {
			updateVisibleMessagesByUserId(userId, isVisible);
		}

		if (talkerIsModer) {
			addToast({
				iconName: isVisible ? ToastIconName.plusGreen : ToastIconName.brick,
				liveTime: 3000,
				message: isVisible ? translations.toasts.messagesShown : translations.toasts.messagesHidden,
				messageColor: isVisible ? 'rgba(0, 178, 71, 1)' : undefined,
				cancellable: true,
			});
		}
	};

	const onMessageVisibleHandler = (data: {message: Message}) => {
		const {message} = data;
		const {isVisible, talker} = message;
		const talkerIsModer = !!myTalkerRef.current?.isModer;
		if (talkerIsModer || (!talkerIsModer && userData?.id !== talker.user?.id)) {
			updateMessage(message);
		}

		if (talkerIsModer) {
			addToast({
				iconName: isVisible ? ToastIconName.plusGreen : ToastIconName.brick,
				liveTime: 3000,
				message: isVisible ? translations.toasts.messageShown : translations.toasts.messageHidden,
				messageColor: isVisible ? 'rgba(0, 178, 71, 1)' : undefined,
				cancellable: true,
			});
		}
	};

	const talkerBanSetHandler = (data: {talker: Talker; ban: {reason: number}}) => {
		const {talker, ban} = data;
		const {isBanned, user} = talker;
		const talkerIsModer = !!myTalkerRef.current?.isModer;

		updateTalker(data.talker);

		if (userData?.id === user.id) {
			updateMyTalker(data.talker);

			toggleVisibleButtons(true);
			data.talker.isBanned && toggleClearChatTextarea(true);

			if (ban) {
				if (ban.reason === BanReason.NICKNAME) {
					toggleChangeNameModal(true);
				}

				if (typeof banReasonTranslations[ban.reason] !== 'undefined') {
					addToast({
						iconName: isBanned ? ToastIconName.userBlocked : ToastIconName.userUnBlocked,
						liveTime: 3000,
						message: isBanned
							? translations.toasts.youareblocked(
									banReasonTranslations[data.ban.reason].toLowerCase()
							  )
							: translations.toasts.youareunblocked,
						cancellable: true,
					});
				}
			}
		}

		if (talkerIsModer) {
			addToast({
				iconName: isBanned ? ToastIconName.userBlocked : ToastIconName.userUnBlocked,
				liveTime: 3000,
				message: isBanned ? translations.toasts.userBlocked : translations.toasts.userUnBlocked,
				cancellable: true,
			});

			isBanned ? addBannedTalker(data.talker) : removeBannedTalker(data.talker);
		}
	};

	const onUserBanSetHandler = (data: {
		user: User;
		ban: {reason: number; expires: UTCDateString};
	}) => {
		if (userData?.id === data.user.id) {
			updateUserData({isBanned: data.user.isBanned, ban: {...data.ban}});
		}
	};

	const reciveChangeRole = (data: {talker: Talker}) => {
		const {talker} = data;
		const talkerIsModer = talker.isModer;
		const realRole =
			talkerIsModer || talker.role === UserRole.SPEAKER ? UserRole.SPEAKER : UserRole.GUEST;
		updateTalker(talker);
		if (userData?.id === talker.user?.id) {
			AgoraServices.setClientRole(realRole, talker.isMuted, changeAgoraStatus);

			updateMyTalker(talker);
		}

		if (!talkerIsModer) {
			if (userData?.id === talker.user?.id) {
				addToast({
					iconName:
						talker.role === UserRole.SPEAKER
							? ToastIconName.micYellow
							: ToastIconName.userRemovedSpeakers,
					liveTime: 3000,
					message:
						talker.role === UserRole.SPEAKER
							? translations.toasts.youAreSpeaker
							: translations.toasts.youAreNotSpeaker,
					cancellable: true,
				});
				return;
			}

			const userName = talker.user.name || 'User';
			addToast({
				iconName:
					talker.role === UserRole.SPEAKER
						? ToastIconName.userAddedSpeakers
						: ToastIconName.userRemovedSpeakers,
				liveTime: 3000,
				message:
					talker.role === UserRole.SPEAKER
						? translations.toasts.userIsSpeaker(userName)
						: translations.toasts.userNotSpeaker(userName),
				cancellable: true,
			});
		}
	};

	const onChangeModerHandler = (data: {talker: Talker}) => {
		const {talker} = data;
		const talkerIsModer = talker.isModer;
		const realRole =
			talkerIsModer || talker.role === UserRole.SPEAKER ? UserRole.SPEAKER : UserRole.GUEST;
		const {youAreModerator, youAreNoModerator} = translations.toasts;
		updateTalker(talker);

		if (talker.id) {
			updateTalkerMessagesByTalkerId(talker.id, 'isModer', !!talker.isModer);
		}

		if (userData?.id === talker.user?.id) {
			AgoraServices.setClientRole(realRole, talker.isMuted, changeAgoraStatus);
			updateMyTalker(talker);

			addToast({
				iconName: ToastIconName.warning,
				liveTime: 3000,
				message: talker.isModer ? youAreModerator : youAreNoModerator,
				cancellable: true,
			});
		}
	};

	const onHandToggledHandler = (data: {talker: Talker; byModer: boolean}) => {
		const {talker} = data;
		updateTalker(talker);

		if (userData?.id === talker.user?.id) {
			updateMyTalker(talker);

			if (data.byModer) {
				addToast({
					iconName: ToastIconName.handPlainYellow,
					liveTime: 3000,
					message: translations.toasts.requestDecline,
					cancellable: true,
				});
			}

			if (talker.hand) {
				addToast({
					iconName: ToastIconName.handPlainYellow,
					liveTime: 3000,
					message: translations.toasts.requestSent,
					cancellable: true,
				});
			}
		}
	};

	const reciveAgoraCredsHandler = (data: AgoraCreds) => {
		setAgoraCreds(data);
	};

	const onUserUpdatedHandler = (data: {user: User}) => {
		const {user} = data;
		updateUserInfo(user);
		if (userData?.id === user.id) {
			updateUserData(user);
		}
	};

	const onEmotionHandler = (data: {emotion: {name: string}}) => {
		const {emotion} = data;
		const findEmotion = emotions.find(emotionLocal => emotionLocal.name === emotion.name);
		if (findEmotion) {
			const generateEmotion: Emotion = {
				...findEmotion,
				uuid: uuidv4(),
				versionAnim: animationVersion ? 'first' : 'second',
			};
			animationVersion = !animationVersion;
			addEmotion(generateEmotion);
		}
	};

	const onRoomSpeakSetHandler = (data: {room: Room}) => {
		const {isSpeak} = data.room;
		setAppVoice(isSpeak);
		updateRoomData({isSpeak});
	};

	const onRoomStatusSet = (data: {room: Room}) => {
		const {room} = data;
		setRoomData(room);
		if (room.status === RoomStatus.ENDED) {
			setAppVoice(false);
			if (room.record) {
				toggleRecordingModal(true);
			}
		}
	};

	const onMuteSetHandler = (data: {
		talker: Talker;
		byModer: boolean;
		initiatorModerUser?: User;
	}) => {
		const {talker, byModer, initiatorModerUser} = data;

		if (talker.user?.id) {
			updateTalkerMic(talker.user?.id, talker.isMuted);
		}

		if (userData?.id === talker.user?.id) {
			updateMyTalker(talker);

			if (byModer && initiatorModerUser?.id !== talker.user?.id) {
				AgoraServices.switchMicrophone(talker.isMuted);

				if (talker.isMuted) {
					addToast({
						iconName: ToastIconName.mic,
						liveTime: 3000,
						message: translations.toasts.micMuted,
						cancellable: true,
					});
				}
			}
		}
	};

	const onMessagePin = (data: {message: Message}) => {
		let localMessage = data.message;

		if (localMessage && !localMessage.talker) {
			localMessage = {...localMessage, talker: TALKER_STUB};
		}

		updatePinnedMessagePinStatus(localMessage);
	};

	const onPollCreatedHandler = (data: {poll: Poll}) => {
		const {poll} = data;
		if (roomId && poll) {
			togglePollCreateModalVisible(false);
			setPoll(poll, roomId);
			toggllePollTooltipVisible(true);
			if (userData && userData.id === poll.creatorUser.id) {
				togglePollEditModalVisible(true);
			}
		}
	};

	const onPollUpdatedHandler = (data: {poll: Poll}) => {
		const {poll} = data;
		if (roomId && poll && poll.status !== PollStatus.PLANNED) {
			togglePollCreateModalVisible(false);
			setPoll(poll, roomId);
		}
	};

	const onPollDeletedHandler = (data: {poll: Poll}) => {
		hideAllPollModals();
		removeMessageByPollId(data.poll.id);
		removePoll();
	};

	const onPollEndedHandler = () => {
		hideAllPollModals();
		removePoll();
	};

	const onVoteCreatedHandler = (data: {pollOption: PollOption}) => {
		const {pollOption} = data;
		if (data.pollOption) {
			setPollOptionVote(data.pollOption);

			const generatePollOptionAnim: PollOption = {
				...pollOption,
				uuid: uuidv4(),
				versionAnim: `anim${animationVersionPollVote}`,
			};
			animationVersionPollVote = animationVersionPollVote < 5 ? animationVersionPollVote + 1 : 1;
			addPollVoteAnim(generatePollOptionAnim);
		}
	};

	const onBlockSetHandler = (data: BlockSet) => {
		const {isBlocked, initiatorId, targetId} = data;

		if (initiatorId === userData?.id) {
			toggleBlockedUserForFilteredMessages(targetId, isBlocked);
			if (isBlocked) {
				removeMentionMessageForBlockedUser(targetId);
				return;
			}
			removeBlockedUser(targetId);
		}

		if (targetId === userData?.id) {
			toggleBlockedUserForFilteredMessages(initiatorId, isBlocked);
			if (isBlocked) {
				removeMentionMessageForBlockedUser(initiatorId);
			}
		}
	};

	const onRemoveMessageHandler = (data: {messageId: number}) => {
		if (userData) {
			removeMessage(data.messageId, userData, () => {
				addToast({
					iconName: ToastIconName.basket,
					liveTime: 3000,
					message: translations.toasts.messageRemoved,
					cancellable: true,
				});
			});

			if (previousMessagesModeRef.current) {
				removeUnrecordedMessage(data.messageId);
			}
		}
	};

	const onReactionCreatedHandler = (data: {reaction: Reaction}) => {
		addReactionToMessage(data.reaction);
	};

	const onReactionDeletedHandler = (data: {reaction: Reaction}) => {
		removeReactionToMessage(data.reaction);
	};

	const onRoomSlowmodeSetHandler = (data: {room: Room}) => {
		const {isSlowmode, slowmodeDelayMS} = data.room;
		updateRoomData({isSlowmode, slowmodeDelayMS});
		togglleSlowMode({
			local: {
				enable: isSlowmode,
				time: slowmodeDelayMS || 1000,
			},
		});
		if (!isSlowmode) {
			getSettingsWithServices();
		}
	};

	const onRoomSlowmodeDelaySetHandler = (data: {room: Room}) => {
		const {slowmodeDelayMS} = data.room;
		updateRoomData({slowmodeDelayMS});
		togglleSlowMode({
			local: {
				enable: !!slowModeRef.current?.local.enable,
				time: slowmodeDelayMS || 1000,
			},
		});
	};

	const onSettingsUpdatedHandler = (data: {settings: Settings}) => {
		const {isSlowmode, slowmodeDelayMS} = data.settings;
		if (typeof isSlowmode !== 'undefined' && typeof slowmodeDelayMS !== 'undefined') {
			togglleSlowMode({
				global: {
					enable: isSlowmode,
					time: slowmodeDelayMS || 1000,
				},
			});
		}
	};

	return {
		onUserJoinedHandler,
		onSuccessfullyJoined,
		onUserLeftHandler,
		onGetMessageHandler,
		onMessageNotSentHandler,
		onMessageEditedHandler,
		onMessagesDeletedHandler,
		onMessagesVisibleHandler,
		onMessageVisibleHandler,
		talkerBanSetHandler,
		onUserBanSetHandler,
		reciveChangeRole,
		onChangeModerHandler,
		onHandToggledHandler,
		reciveAgoraCredsHandler,
		onEmotionHandler,
		onRoomSpeakSetHandler,
		onRoomStatusSet,
		onMuteSetHandler,
		onUserUpdatedHandler,
		onMessagePin,
		onPollCreatedHandler,
		onPollDeletedHandler,
		onPollUpdatedHandler,
		onPollEndedHandler,
		onVoteCreatedHandler,
		onBlockSetHandler,
		onRemoveMessageHandler,
		onReactionCreatedHandler,
		onReactionDeletedHandler,
		onRoomSlowmodeSetHandler,
		onRoomSlowmodeDelaySetHandler,
		onSettingsUpdatedHandler,
	};
};
