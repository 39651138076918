import AudioFeedbackSteps from 'models/enums/AudioFeedbackSteps';
import useFeedbackAudio from 'hooks/useFeedbackAudio';
import useL10n from 'l10n/useL10n';
import useAnalytics from 'hooks/useAnalytics';
import feedbackServices from 'store/feedbackServices';
import modalServices from 'store/modalServices';
import {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {useLocalObservable} from 'mobx-react-lite';
import {v4 as uuidv4} from 'uuid';
import {
	isDesktop,
	browserName,
	fullBrowserVersion,
	osName,
	osVersion,
	deviceType,
} from 'react-device-detect';
import {Star} from 'components/ui';

const AudioQuality: FunctionComponent = function AudioQuality() {
	const [hoveredStar, setHoveredStar] = useState<null | number>(null);
	const [selectedStar, setSelectedStar] = useState<null | number>(null);
	const {setAudioFeedbackData, toggleAudioFeedbackActiveStep} = useLocalObservable(
		() => feedbackServices
	);
	const {toggleFeedbackAudioModal} = useLocalObservable(() => modalServices);
	const {sendAudioFeedbackAndExitApp} = useFeedbackAudio();
	const {feedback} = useL10n();
	const {sendAnalytics} = useAnalytics();

	const onHoverStarHandler = (e: any) => {
		if (isDesktop) {
			const value = e.type === 'mouseleave' ? null : +e.currentTarget.dataset.star;
			setHoveredStar(value);
		}
	};

	const onClickStarHandler = (e: any) => {
		const value = +e.currentTarget.dataset.star;
		if (selectedStar === value) {
			setSelectedStar(null);
			setAudioFeedbackData({rating: 0});
			return;
		}
		setSelectedStar(value);
		setAudioFeedbackData({rating: value});
		sendAnalytics('ff_stream_evaluated', {evaluation: value});
	};

	const onRateQualityHandler = () => {
		if (selectedStar && selectedStar < 5) {
			toggleAudioFeedbackActiveStep(AudioFeedbackSteps.REASON);
			return;
		}
		sendAudioFeedbackAndExitApp();
		toggleFeedbackAudioModal(false);
	};

	const renderAudioStarsItem = useCallback(
		(item, index) => (
			<div className='audio__stars-item' key={uuidv4()}>
				<button
					type='button'
					className='audio__stars-btn'
					data-star={index + 1}
					onClick={onClickStarHandler}
					onMouseEnter={onHoverStarHandler}
					onMouseLeave={onHoverStarHandler}>
					<Star
						selected={
							!!(hoveredStar && index + 1 <= hoveredStar) ||
							!!(selectedStar && index + 1 <= selectedStar)
						}
					/>
					<span />
				</button>
			</div>
		),
		[hoveredStar, selectedStar]
	);

	useEffect(() => {
		setAudioFeedbackData({
			info: JSON.stringify({
				osName,
				osVersion,
				browserName,
				fullBrowserVersion,
				deviceType,
			}),
		});
	}, []);

	return (
		<div className='audio__quality'>
			<div className='audio__title'>{feedback.audio.quality.title}</div>
			<div className='audio__stars'>{[...Array(5)].map(renderAudioStarsItem)}</div>
			<button
				type='button'
				className='audio__btn audio__btn--accent'
				disabled={!selectedStar}
				onClick={onRateQualityHandler}>
				{feedback.audio.btns.rate}
			</button>
		</div>
	);
};

export default AudioQuality;
