import AudioFeedbackSteps from 'models/enums/AudioFeedbackSteps';
import useL10n from 'l10n/useL10n';
import feedbackServices from 'store/feedbackServices';
import {FunctionComponent} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';

const AudioConnection: FunctionComponent = function AudioConnection() {
	const {toggleAudioFeedbackActiveStep} = useLocalObservable(() => feedbackServices);

	const {feedback} = useL10n();

	const onCheckConnectionHandler = () => {
		toggleAudioFeedbackActiveStep(AudioFeedbackSteps.STATISTICS);
	};

	return (
		<div className='audio__connection'>
			<div className='audio__title'>{feedback.audio.connection.title}</div>
			<div className='audio__text'>{feedback.audio.connection.text}</div>
			<button
				type='button'
				className='audio__btn audio__btn--accent'
				onClick={onCheckConnectionHandler}>
				{feedback.audio.btns.checkConnection}
			</button>
		</div>
	);
};

export default observer(AudioConnection);
