import UserRole from 'models/enums/UserRole.enum';
import {FilterUsersBtn} from 'models/room';
import {ReactComponent as IcoMessage} from 'assets/svg/ico-message.svg';
import {ReactComponent as IcoSpeaker} from 'assets/svg/ico-speaker.svg';

export const filterUsersBtnsData: FilterUsersBtn[] = [
	{
		id: 0,
		title: {
			ru: 'Все',
			en: 'All',
		},
		icon: <IcoMessage />,
		role: UserRole.ALL,
	},
	{
		id: 1,
		title: {
			ru: 'Спикеры',
			en: 'Speakers',
		},
		icon: <IcoSpeaker />,
		role: UserRole.SPEAKER,
	},
];
