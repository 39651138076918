function qs(className: string, root = document) {
	return root.querySelector(className);
}

function qsAll(className: string, root = document) {
	return root.querySelectorAll(className);
}

function getParent(element: any, className: string) {
	while (element && element.parentNode) {
		// eslint-disable-next-line no-param-reassign
		element = element.parentNode;
		if (element.classList && element.classList.contains(className)) return element;
	}
	return false;
}

const containsClass = (element: any, className: string) => element.classList.contains(className);

export {qs, qsAll, getParent, containsClass};
