/* eslint-disable jsx-a11y/label-has-associated-control */
import {FunctionComponent} from 'react';
import {observer} from 'mobx-react-lite';
import {v4 as uuidv4} from 'uuid';
import './switcher.scss';

interface ISwitcherProps {
	checked: boolean;
	onChange: () => void;
}

const Switcher: FunctionComponent<ISwitcherProps> = function Switcher(props) {
	const id = uuidv4();
	return (
		<div className='switcher'>
			<input id={id} type='checkbox' className='switcher__checkbox' {...props} />
			<label className='switcher__label' htmlFor={id} />
		</div>
	);
};

export default observer(Switcher);
