import ResponseStatus from 'models/enums/ResponseStatus.enum';
import userServices from 'store/userServices';
import stickersEmojiServices from 'store/stickersEmojiServices';
import RoomService from 'services/api/RoomService';
import {useLocalObservable} from 'mobx-react-lite';

const useStickers = () => {
	const {accessToken} = useLocalObservable(() => userServices);
	const {setStickers} = useLocalObservable(() => stickersEmojiServices);

	const getStickersWithServices = async () => {
		if (accessToken) {
			const response = await RoomService.getStickers(accessToken);
			if (response.status === ResponseStatus.SUCCESS) {
				setStickers(response.data);
			}
		}
	};

	return {getStickersWithServices};
};

export default useStickers;
