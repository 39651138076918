import appService from 'store/appService';
import {useLocalObservable} from 'mobx-react-lite';
import {useMemo} from 'react';
import translations from 'l10n/translations';
import {Dictionary} from 'l10n/dictionaries/dictionary';

export default (): Dictionary => {
	const {language} = useLocalObservable(() => appService);

	return useMemo(() => {
		translations.setLanguage(language);

		return translations;
	}, [language]);
};
