import Log from 'utils/log';
import AgoraRTC, {
	ClientConfig,
	IAgoraRTCClient,
	IAgoraRTCRemoteUser,
	IMicrophoneAudioTrack,
} from 'agora-rtc-sdk-ng';
import {AgoraCreds} from 'models/room';
import UserRole from 'models/enums/UserRole.enum';
import {AgoraStatus} from 'models/enums/AgoraStatus.enum';

const config: ClientConfig = {
	mode: 'live',
	codec: 'vp8',
};

class AgoraServices {
	client: null | IAgoraRTCClient = null;

	microphoneTrack: IMicrophoneAudioTrack | null = null;

	uid: any = null;

	constructor() {
		if (process.env.REACT_APP_ENVIRONMENT !== 'dev' || process.env.REACT_APP_DISABLE_AGORA_LOG) {
			AgoraRTC.setLogLevel(4);
		}
	}

	public async init(
		creds: AgoraCreds,
		isSpeaker: boolean,
		muted: boolean,
		VODCallback: any,
		networkQualityCallback: any,
		setAgoraMicrophone: any,
		setAgoraMicrophones: any,
		changeAgoraStatus: any
	) {
		try {
			if (this.client === null) {
				this.client = AgoraRTC.createClient({...config, role: isSpeaker ? 'host' : 'audience'});
			}

			AgoraRTC.onMicrophoneChanged = async ({state, device}) => {
				if (this.microphoneTrack) {
					const oldMicrophones = await AgoraRTC.getMicrophones();
					let deviceActive: MediaDeviceInfo | null = null;

					if (oldMicrophones.length) {
						setAgoraMicrophones(oldMicrophones);
					}

					if (state === 'ACTIVE') {
						deviceActive = device;
					} else if (device.label === this.microphoneTrack.getTrackLabel()) {
						if (oldMicrophones.length) {
							const {0: firstDevice} = oldMicrophones;
							deviceActive = firstDevice;
						}
					}

					if (deviceActive) {
						setAgoraMicrophone(deviceActive);
						this.microphoneTrack.setDevice(deviceActive.deviceId);
					}
				}
			};

			this.client?.on(
				'user-published',
				async (user: IAgoraRTCRemoteUser, mediaType: 'audio' | 'video') => {
					await this.client?.subscribe(user, mediaType);
					if (mediaType === 'audio') {
						user.audioTrack?.play();
					}
				}
			);

			this.client?.on('network-quality', quality => {
				networkQualityCallback(quality, this.client?.getRTCStats());
			});

			this.client?.on('user-unpublished', (user: IAgoraRTCRemoteUser, type: 'audio' | 'video') => {
				if (type === 'audio') {
					user.audioTrack?.stop();
				}
			});

			this.client?.enableAudioVolumeIndicator();
			this.client?.on('volume-indicator', result => {
				result.forEach(volume => {
					VODCallback(volume.uid, volume.level);
				});
			});

			if (creds.appId && creds.token && creds.channelName && creds.talker) {
				[this.uid, this.microphoneTrack] = await Promise.all([
					this.client?.join(creds.appId, creds.channelName, creds.token, creds.talker.id),
					AgoraRTC.createMicrophoneAudioTrack(),
				]);
			}

			if (isSpeaker) {
				this.initMicrophoneTrack(muted, changeAgoraStatus);
				return;
			}

			changeAgoraStatus(AgoraStatus.INITED);
		} catch (error) {
			changeAgoraStatus(AgoraStatus.DESTROYED);
			Log.error('Init agora voice: ', error);
		}
	}

	public async initMicrophoneTrack(muted: boolean, initMicrophoneCallback?: any) {
		if (this.client) {
			this.microphoneTrack = await AgoraRTC.createMicrophoneAudioTrack();
			await this.microphoneTrack?.setEnabled(true);
			await this.client?.setClientRole('host');
			await this.client.publish(this.microphoneTrack);
			await this.microphoneTrack?.setMuted(muted);
			if (initMicrophoneCallback) {
				initMicrophoneCallback(AgoraStatus.INITED);
			}
		}
	}

	public async getMicrophones() {
		const microphones = await AgoraRTC.getMicrophones();
		return microphones;
	}

	public async setMicrophone(deviceId: string) {
		if (this.microphoneTrack) {
			this.microphoneTrack.setDevice(deviceId);
		}
	}

	public async switchMicrophone(muted: boolean) {
		try {
			await this.microphoneTrack?.setMuted(muted);
		} catch (err) {
			Log.error(`error in mute mic-> ${JSON.stringify(err, null, 2)}`);
		}
	}

	public async getPlaybackDevices() {
		const playbackDevices = await AgoraRTC.getPlaybackDevices();
		return playbackDevices;
	}

	public async setPlaybackDevice(deviceId: string) {
		if (this.client && this.client.remoteUsers.length) {
			this.client.remoteUsers.forEach(item => item.audioTrack?.setPlaybackDevice(deviceId));
		}
	}

	public setClientRole = async (role: UserRole, muted: boolean, initMicrophoneCallback: any) => {
		const isSpeaker = role === UserRole.SPEAKER;
		try {
			if (isSpeaker) {
				this.microphoneTrack === null && this.initMicrophoneTrack(muted, initMicrophoneCallback);
			} else {
				await this.microphoneTrack?.setMuted(false);
				await this.client?.unpublish();
				await this.microphoneTrack?.setEnabled(false);
				this.microphoneTrack = null;
				await this.client?.setClientRole('audience');
			}
		} catch (err) {
			Log.error(`error in switching the client role-> ${JSON.stringify(err, null, 2)}`);
		}
	};

	public async destroy(changeAgoraStatus: any) {
		changeAgoraStatus(AgoraStatus.DESTROYED);
		this.client?.removeAllListeners();
		await this.client?.unpublish();
		await this.client?.leave();
	}
}

export default new AgoraServices();
