import {times} from 'data/slowModeData';
import {MODAL_STYLE} from 'constants/constants';
import {findValueByKeyInObject} from 'utils/findValueByKeyInObject';
import appService from 'store/appService';
import roomServices from 'store/roomServices';
import slowModeServices from 'store/slowModeServices';
import modalServices from 'store/modalServices';
import SocketIoServices from 'services/SocketIoServices';
import useL10n from 'l10n/useL10n';
import {FunctionComponent, useCallback} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Modal from 'react-modal';
import debounce from 'lodash/debounce';
import Button from 'components/hoc/Button';
import {Switcher} from 'components/switcher';

const SlowModeModal: FunctionComponent = function SlowModeModal() {
	const {modals} = useL10n();
	const {language} = useLocalObservable(() => appService);
	const {roomId} = useLocalObservable(() => roomServices);
	const {slowMode, togglleSlowMode} = useLocalObservable(() => slowModeServices);
	const {slowModeModal, toggleSlowModeModal, toggleSlowModeTimeoutModal} = useLocalObservable(
		() => modalServices
	);

	const getSlowModeTimeTitle = () => {
		const findItem = times.find(item => item.time === slowMode.local.time);
		if (findItem) {
			const findTitle = findValueByKeyInObject<{default: string; prefix?: string}>(
				findItem.title,
				language,
				{default: findItem.title.ru.default}
			);
			return <span>{findTitle.default}</span>;
		}
		return null;
	};

	const onCloseModalHandler = () => {
		toggleSlowModeModal(false);
	};

	const onChangeSlowModeTimeoutSwitcherHandler = useCallback(
		debounce(
			() => {
				if (roomId) {
					togglleSlowMode({
						local: {
							enable: slowMode.local.enable,
							time: slowMode.local.time || 1000,
						},
					});
					SocketIoServices.emitSlowMode({
						externalRoomId: roomId,
						isSlowmode: !slowMode.local.enable,
						slowmodeDelayMS: !slowMode.local.enable ? slowMode.local.time : 0,
					});
				}
			},
			250,
			{
				leading: false,
				trailing: true,
			}
		),
		[roomId, slowMode]
	);

	const onClickSlowModeTimeoutButtonHandler = () => {
		toggleSlowModeTimeoutModal(true);
	};

	return (
		<Modal
			className='modal'
			isOpen={slowModeModal}
			ariaHideApp={false}
			style={{
				overlay: MODAL_STYLE.overlay,
			}}>
			<div className='modal__head'>
				<div className='modal__title'>{modals.slowMode.title}</div>
				<Button className='modal__close' onClick={onCloseModalHandler} />
			</div>
			<div className='modal__body'>
				<div className='modal__axis-y'>
					<div className='settings'>
						<div className='settings__items'>
							<div className='settings__item settings__item--switcher'>
								<div className='settings__item-title'>
									{slowMode.local.enable ? modals.slowMode.disable : modals.slowMode.enable}
								</div>
								<div className='settings__item-switcher'>
									<Switcher
										checked={slowMode.local.enable}
										onChange={onChangeSlowModeTimeoutSwitcherHandler}
									/>
								</div>
							</div>
							<div className='settings__item'>
								<Button className='settings__button' onClick={onClickSlowModeTimeoutButtonHandler}>
									{modals.slowMode.sendTimeout}
									{getSlowModeTimeTitle()}
								</Button>
							</div>
						</div>
						<div className='settings__text'>{modals.slowMode.text}</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default observer(SlowModeModal);
