import {PollType} from 'models/enums/Poll.enum';
import {PollOption} from 'models/poll';
import pollServices from 'store/pollServices';
import usePollActions from 'hooks/usePollActions';
import {FunctionComponent, useCallback} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import classNames from 'classnames';

const PollToggleOptions: FunctionComponent = function PollToggleOptions() {
	const {calcPollOptionVote} = usePollActions();
	const {poll, pollAlreadyVoted} = useLocalObservable(() => pollServices);

	const isVisibleVotes = pollAlreadyVoted && poll && poll.type !== PollType.QUIZ;

	const pollToggleOptionsClasses = classNames('poll-toggle__options', {
		'poll-toggle__options--votes': isVisibleVotes,
	});

	const renderPollOption = useCallback(
		(option: PollOption) => {
			return (
				<div key={option.id} className='poll-toggle__option'>
					<img src={option.pic} alt='pic' className='poll-toggle__option-img' />
					{isVisibleVotes && poll?.options && !!poll.options.length && (
						<div className='poll-toggle__option-numbers'>
							{calcPollOptionVote(poll.options, option.id)}%
						</div>
					)}
				</div>
			);
		},
		[pollAlreadyVoted, poll]
	);

	if (!poll || (poll && !poll.options?.length)) {
		return null;
	}

	return <div className={pollToggleOptionsClasses}>{poll.options.map(renderPollOption)}</div>;
};

export default observer(PollToggleOptions);
